import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Layout from '../../molecules/Layout/Layout';

const TapApplications = (props) => {
  const { children } = props;
  const _renderModules = () => (
    <Layout>
      {children}
    </Layout>
  );
  return (
    <React.Fragment>
      {_renderModules()}
    </React.Fragment>
  );
};
TapApplications.propTypes = {
  children: PropTypes.object.isRequired,
};
export default withRouter(TapApplications);
