import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import skeletonReducer from '../organisms/skeleton.reducer';
import ProfileReducer from './Profile.reducers';
import CalendarReducer from './Calendar.reducers';
import TaskReducer from './TaskManagement.reducers';
import TapApplicationsReducer from './TapApplications.reducers';
import AuthenticationReducers from './Authentication.reducers';
import SupportPortalReducers from './SupportPortal.reducers';
import cardManagementReducers from './CardManagement.reducers';
import groupManagementReducers from './GroupManagement.reducers';
import tapConfigurationReducers from './TapConfigurations.reducers';
import UserManagementReducers from './UserManagement.reducers';
import userAvailabilityReducers from './Availability.reducer';
import mobileConfigReducers from './MobileConfigurations.reducer';
import NotificationSettingsReducers from './NotificationSettings.reducers';

// import history from '@tekion/tekion-base/utils/history';

const history = createBrowserHistory({ basename: `/${process.env.MICRO_SERVICE_APP}` });


export const defaultReducers = {
  router: connectRouter(history),
  skeleton: skeletonReducer,
  profile: ProfileReducer,
  task: TaskReducer,
  calender: CalendarReducer,
  TapApplications: TapApplicationsReducer,
  Authentication: AuthenticationReducers,
  supportPortal: SupportPortalReducers,
  cardManagement: cardManagementReducers,
  groupManagement: groupManagementReducers,
  tapConfigurations: tapConfigurationReducers,
  userManagement: UserManagementReducers,
  userAvailability: userAvailabilityReducers,
  mobileConfigurations: mobileConfigReducers,
  notificationSettings: NotificationSettingsReducers,
};

export const rootReducer = combineReducers(defaultReducers);

export default {
  defaultReducers,
  rootReducer,
};
