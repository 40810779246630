import { doGet, doPut, doPost } from '../services/http';
import {
  getListing, saveModule, fetchModule,
} from './urls';

export const fetchListingAPI = (url, feature) => doGet(`${url}${getListing}`.replace('{feature}', feature));

export const saveModuleAPI = (url, data) => doPut(`${url}${saveModule}`, data);

export const fetchModuleAPI = (url, module, feature) => doGet(`${url}${fetchModule}`.replace('{module}', module).replace('{feature}', feature));

export const updateModuleAPI = (url, feature, data) => doPost(`${url}${fetchModule}`.replace('{module}', data.module).replace('{feature}', feature), data);
