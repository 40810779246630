exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".MobileConfigurationsCreate_container__2Yn7C{position:absolute;width:calc(100% - 6.4rem)}[dir] .MobileConfigurationsCreate_container__2Yn7C{border-radius:1.5rem}[dir=ltr] .MobileConfigurationsCreate_container__2Yn7C{margin:6.2rem 0 0 6.4rem}[dir=rtl] .MobileConfigurationsCreate_container__2Yn7C{margin:6.2rem 6.4rem 0 0}.MobileConfigurationsCreate_container__2Yn7C .MobileConfigurationsCreate_createModule__1hW1s{font-family:Proxima-Nova-Semibold;font-size:1.6rem;height:calc(100vh - 15em);overflow:auto}[dir] .MobileConfigurationsCreate_container__2Yn7C .MobileConfigurationsCreate_createModule__1hW1s{padding:2.5rem}[dir] .MobileConfigurationsCreate_container__2Yn7C .MobileConfigurationsCreate_createModule__1hW1s .MobileConfigurationsCreate_rowCol__3IsYS{margin-bottom:2.4rem}.MobileConfigurationsCreate_container__2Yn7C .MobileConfigurationsCreate_moduleFooter__phYP7{bottom:2.3rem;height:6.7rem}[dir] .MobileConfigurationsCreate_container__2Yn7C .MobileConfigurationsCreate_moduleFooter__phYP7{border-top:.1rem solid #d4d5d6}[dir=ltr] .MobileConfigurationsCreate_container__2Yn7C .MobileConfigurationsCreate_moduleFooter__phYP7{text-align:right;padding:2rem 3rem 0 0}[dir=rtl] .MobileConfigurationsCreate_container__2Yn7C .MobileConfigurationsCreate_moduleFooter__phYP7{text-align:left;padding:2rem 0 0 3rem}", ""]);

// Exports
exports.locals = {
	"container": "MobileConfigurationsCreate_container__2Yn7C",
	"createModule": "MobileConfigurationsCreate_createModule__1hW1s",
	"rowCol": "MobileConfigurationsCreate_rowCol__3IsYS",
	"moduleFooter": "MobileConfigurationsCreate_moduleFooter__phYP7"
};