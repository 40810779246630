
import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import { deepClone } from '@tekion/tap-components/utils/helper';
import { getValueFromEnv } from '@tekion/tap-components/utils/commonHelper';
import {
  fetchAllGroupsAPI, createGroupAPI, updateGroupAPI, deleteGroupAPI, fetchGroupAPI, fetchGroupTypes,
} from '../api/GroupManagement';
import { CONSTANTS } from '../constants/Constants';
const { ACTIONS } = CONSTANTS;
const BASE_URL = getValueFromEnv('BASE_URL');

/**
 * @description Function to fetch all Groups.
 * @param {String} baseUrl - baseurl to fetch the module.
 */
export const getGroups = baseUrl => (
  (dispatch) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: { getGroups: true } });
    return fetchAllGroupsAPI(baseUrl).then((response) => {
      dispatch({ type: ACTIONS.SET_LOADER, payload: { getGroups: false } });
      dispatch({ type: ACTIONS.FETCH_ALL_GROUPS_SUCCESS, payload: response.groups });
    }).catch((error) => {
      dispatch({ type: ACTIONS.SET_LOADER, payload: { getGroups: false } });
      dispatch({ type: ACTIONS.FETCH_LISTING_ERROR, payload: error });
    });
  }
);

/**
 * @description Function to fetch Group Type details.
 * @param {String} baseUrl - baseurl to fetch the module.
 */
export const groupTypes = baseUrl => (
  (dispatch) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: { getGroups: true } });
    return fetchGroupTypes(baseUrl).then((response) => {
      dispatch({ type: ACTIONS.SET_LOADER, payload: { getGroups: false } });
      dispatch({ type: ACTIONS.FETCH_GROUP_TYPES_SUCCESS, payload: response.groupTypes });
    }).catch((error) => {
      toaster('error', `${error.status} Error occurred while getting group types`);
      dispatch({ type: ACTIONS.SET_LOADER, payload: { getGroups: false } });
      dispatch({ type: ACTIONS.FETCH_LISTING_ERROR, payload: error });
    });
  }
);

/**
 * @description Function to fetch individual Group details.
 * @param {String} baseUrl - baseurl to fetch the module.
 */
export const activeGroup = (baseUrl, data) => (
  dispatch => fetchGroupAPI(baseUrl, data).then((response) => {
    dispatch({ type: ACTIONS.FETCH_GROUP_SUCCESS, payload: response });
  }).catch((error) => {
    dispatch({ type: ACTIONS.FETCH_LISTING_ERROR, payload: error });
  })
);

/**
 * @description Function to create New Group.
 * @param {String} baseUrl - baseurl to fetch the module.
 */
export const createGroup = (baseUrl, data) => (
  (dispatch) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: { addGroup: true } });
    return createGroupAPI(baseUrl, data).then((response) => {
      dispatch({ type: ACTIONS.FETCH_ADD_GROUP_SUCCESS, payload: response });
      dispatch(getGroups(BASE_URL));
      toaster('success', 'Group added successfully');
      dispatch({ type: ACTIONS.SET_LOADER, payload: { addGroup: false } });
    }).catch((error) => {
      const { status, msg } = error.response.data;
      if (status === 'Not ok') toaster('error', `${msg}`);
      else toaster('error', `${status} Error occurred while creating Group `);
      dispatch({ type: ACTIONS.FETCH_ADD_GROUP_SUCCESS, payload: error.response });
      dispatch({ type: ACTIONS.SET_LOADER, payload: { addGroup: false } });
    });
  }
);

/**
 * @description Function to update individual Group.
 * @param {String} baseUrl - baseurl to update the module.
 */
export const updateGroup = (baseUrl, data) => (
  (dispatch, getState) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: { updateGroup: true } });
    return updateGroupAPI(baseUrl, data).then(() => {
      dispatch({ type: ACTIONS.SET_LOADER, payload: { updateGroup: false } });
      const { groupManagement } = getState();
      const newData = deepClone(groupManagement.allGroups);
      const updatedIndex = newData.findIndex(item => item.groupKey === data.groupKey);
      newData[updatedIndex] = {
        ...newData[updatedIndex],
        name: data.name,
        iconName: data.iconName,
        colorCode: data.colorCode,
        groupKey: data.groupKey,
      };
      dispatch({ type: ACTIONS.FETCH_ALL_GROUPS_SUCCESS, payload: newData });
      dispatch(getGroups(BASE_URL));
      toaster('success', 'Group updated successfully');
    }).catch((error) => {
      toaster('error', `${error.status} Error occurred while updating Group`);
      dispatch({ type: ACTIONS.SET_LOADER, payload: { updateGroup: false } });
    });
  }
);

/**
 * @description Function to delete individual Group.
 * @param {String} baseUrl - baseurl to delete the module.
 */
export const deleteGroup = (baseUrl, data) => (
  (dispatch, getState) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: { deleteGroup: true } });
    return deleteGroupAPI(baseUrl, data).then(() => {
      dispatch({ type: ACTIONS.SET_LOADER, payload: { deleteGroup: false } });
      const { groupManagement } = getState();
      const newData = deepClone(groupManagement.allGroups);
      const indexToDelete = newData.findIndex(item => item.groupKey === data.groupKey);
      newData.splice(indexToDelete, 1);
      dispatch({ type: ACTIONS.FETCH_ALL_GROUPS_SUCCESS, payload: newData });
      toaster('success', 'Group deleted successfully');
    }).catch((error) => {
      toaster('error', `${error.status} Error occurred while deleting Group`);
      dispatch({ type: ACTIONS.SET_LOADER, payload: { deleteGroup: false } });
    });
  }
);
