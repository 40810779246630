/* eslint-disable i18next/no-literal-string */
import React from 'react';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants'; //eslint-disable-line
import { NO_OP } from '@tekion/tap-components/constants/Constants';
import FixedColumnTable from 'tcomponents/molecules/table/FixedColumnTable';
import IconAsBtn from 'tcomponents/atoms/iconAsBtn/IconAsBtn';
import KebabMenu from 'tcomponents/molecules/KebabMenu/KebabMenu';
import { showConfirm } from '@tekion/tap-components/utils/helper.confirm';

import withPagination from '../../organisms/withPagination/withPagination';
import { getUsersTablePage } from '../../constants/Constants';
import styles from './UserImageMigration.module.scss';

const USER_MANAGEMENT_DEFAULT_COLUMNS = [
  {
    Header: 'Name', id: 'name', accessor: 'name',
  },
  {
    Header: 'Email', id: 'email', accessor: 'email',
  },
];
const USER_MANAGEMENT_TABLES_STYLES = {
  NO_BORDER: '0',
  BORDER: '2px solid #000',
  CURSOR: 'pointer',
  BACKGROUND: '#e6f3ff',
  WHITE_BACKGROUND: '#fff',
  MISSED: '#da0009',
  NOT_MISSED: '#000',
  HEIGHT: '4rem',
};
const USER_MANAGEMENT_ACTIONS = {
  MIGRATE: 'migrate',
  REMOVE_V1: 'remove-v1',
};
const USER_MANAGEMENT_ACTION_MENU = [
  { key: 'migrate', label: __('Migrate') },
  { key: 'remove-v1', label: __('Remove from v1') },
];
const USER_MANAGEMENT_KEBAB_STYLES = {
  fixed: 'right',
  resizable: true,
  width: 50,
};

const tableRowStyle = { cursor: USER_MANAGEMENT_TABLES_STYLES.CURSOR, height: USER_MANAGEMENT_TABLES_STYLES.HEIGHT };
const TableWithPagination = withPagination(FixedColumnTable);

class UserImageMigration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumns: [...USER_MANAGEMENT_DEFAULT_COLUMNS],
      page: getUsersTablePage(),
      searchText: '',
    };
  }

  componentDidMount() {
    const {
      apiBaseUrl, actions, pageType, filterWithImage,
    } = this.props;
    actions.fetchUsersWithImageV1(apiBaseUrl, pageType, filterWithImage);
  }

  componentDidUpdate(prevProps) {
    const {
      apiBaseUrl, actions, isUserFetching, pageType, filterWithImage,
    } = this.props;
    const { isUserFetching: prevIsUserFetching } = prevProps;
    if (isUserFetching && prevIsUserFetching !== isUserFetching) {
      actions.fetchUsersWithImageV1(apiBaseUrl, pageType, filterWithImage);
    }
  }

  getAllData = (pageInfo, pages) => {
    const { page } = this.state;
    this.setState({
      page: {
        ...page,
        currentPage: pageInfo,
        pageSize: pages,
      },
    });
  };

  getColumnConfigurator = () => ({
    ...USER_MANAGEMENT_KEBAB_STYLES,
    Cell: this.getRowActionCell,
    Header: <IconAsBtn style={{ cursor: 'not-allowed' }}>icon-settings</IconAsBtn>,
    id: 'settings',
    sortable: false,
  });

  getRowActionCell = ({ original }) => {
    const menuItemsList = [...USER_MANAGEMENT_ACTION_MENU];
    return (
      <KebabMenu
        menuItems={menuItemsList}
        rowInfo={original}
        triggerElement={<span className="icon-overflow" />}
        onClickAction={key => this.onKebabItemClick(key, original)}
      />
    );
  };

  onKebabItemClick = (key, original) => {
    if (key === USER_MANAGEMENT_ACTIONS.MIGRATE) {
      const { apiBaseUrl, actions } = this.props;
      actions.migrateImage(apiBaseUrl, original.email);
    } else if (key === USER_MANAGEMENT_ACTIONS.REMOVE_V1) {
      showConfirm({
        title: __('Confirm Delete'),
        content: __('Are you sure, want to delete the user "{{userName}}"', { userName: `${original.name || original.firstName} - ${original.email}` }),
        onOk: () => {
          const { apiBaseUrl, actions } = this.props;
          actions.deleteUserFromV1(apiBaseUrl, original.email);
        },
      });
    }
    return null;
  };

  render() {
    const {
      tableColumns, page, searchText,
    } = this.state;
    const {
      workSpaceSize, isTableLoading, allUsers, filteredUsers, pageType,
    } = this.props;
    const userDatas = searchText ? filteredUsers : allUsers;

    return (
      <section>
        <div className={styles.tableContainer}>
          <div className={styles.header}>
            <h2 className={styles.title}>{__('User Image Migration')}</h2>
          </div>
          <TableWithPagination
            data={userDatas}
            columns={[...tableColumns, pageType === 'v1' && this.getColumnConfigurator()]}
            fetchData={this.getAllData}
            isMultiSort={false}
            multiSort={false}
            showPagination
            minRows={0}
            sortable={false}
            onRowClick={NO_OP}
            initPagination={page}
            rowHeight={40}
            rowStyle={tableRowStyle}
            totalDatasCount={userDatas.length}
            loading={isTableLoading}
            style={{ height: workSpaceSize.contentHeight, overflow: 'hidden', fontSize: '1.2rem' }}
          />
        </div>
      </section>
    );
  }
}

UserImageMigration.propTypes = {
  actions: PropTypes.object.isRequired,
  allUsers: PropTypes.array,
  filteredUsers: PropTypes.array,
  isTableLoading: PropTypes.bool.isRequired,
  apiBaseUrl: PropTypes.string.isRequired,
  workSpaceSize: PropTypes.object,
};

UserImageMigration.defaultProps = {
  allUsers: EMPTY_ARRAY,
  filteredUsers: EMPTY_ARRAY,
  workSpaceSize: EMPTY_OBJECT,
};

export default UserImageMigration;
