import React from 'react';
import { withRouter } from 'react-router-dom';

import Layout from '../../molecules/Layout/Layout';

import logo from '../../asset/Logo.png';
import styles from './Home.module.scss';

const Home = () => {
  const _renderHome = () => (
    <Layout>
      <React.Fragment>
        <div
          className={styles.home_container}
        >
          <img
            src={logo}
            alt={__('Tekion Automation Platform')}
          />
          <div>{__('Automation Platform')}</div>
        </div>
      </React.Fragment>
    </Layout>
  );
  return (
    <React.Fragment>
      {_renderHome()}
    </React.Fragment>
  );
};
export default withRouter(Home);
