import React, { useState } from 'react';
import Modal from 'tcomponents/molecules/Modal';
import { Input } from 'antd';

import styles from './UserManagement.module.scss'

const initialValues = {
  email: "",
  firstName: "",
  lastName: ""
}

const AddUserModal = (props) => {
  const [userValues, setUserValues] = useState(initialValues);
  const { apiBaseUrl, actions, showModal, setShowModal } = props;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserValues({
      ...userValues,
      [name]: value
    })
  }

  const onModalCancel = () => {
    setShowModal(false)
    setUserValues(initialValues);
  }

  const onModalSubmit = () => {
    actions.createUser(apiBaseUrl, userValues);
    setShowModal(false);
    setUserValues(initialValues);
  }

  return (
    <Modal
      visible={showModal}
      title={__('User Details')}
      centered
      onCancel={onModalCancel}
      onSubmit={onModalSubmit}
      destroyOnClose
      submitBtnText={__('Add')}
      okButtonProps={{ disabled: userValues.email && userValues.firstName && userValues.lastName ? false : true }}
    >
      <div className={styles.userMgmt_field}>
        <label>{__('Email ')}<span className={styles.userMgmt_label}>{__('*')}</span></label>
        <Input className={styles.userMgmt_input} placeholder={__('enter your email')} type='email' name='email' value={userValues.email} onChange={handleChange} />
      </div>
      <div className={styles.userMgmt_field}>
        <label>{__('First Name')} <span className={styles.userMgmt_label}>{__('*')}</span></label>
        <Input className={styles.userMgmt_input} placeholder={__('enter your first name')} name='firstName' value={userValues.firstName} onChange={handleChange} />
      </div>
      <div className={styles.userMgmt_field}>
        <label>{__('Last Name')} <span className={styles.userMgmt_label}>{__('*')}</span></label>
        <Input className={styles.userMgmt_input} placeholder={__('enter your last name')} name='lastName' value={userValues.lastName} onChange={handleChange} />
      </div>
    </Modal>
  )
}

export default AddUserModal;