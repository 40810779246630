import { createSelector } from 'reselect';
/**
 * @description Selector Function for getting the specified state from reducer
 */
const CalendarReducer = state => state.calender;

const getUserStatus = () => createSelector(CalendarReducer,
  substate => substate.userData);


const loader = () => createSelector(CalendarReducer,
  substate => substate.loading);

export {
  getUserStatus,
  loader,
};
