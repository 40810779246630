import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import Button from 'tcomponents/atoms/Button';
import Input from 'tcomponents/atoms/Input';
import Loader from 'tcomponents/molecules/loader';

import {
  saveModule, fetchModule, updateModule, fetchListing,
} from '../../action/TapApplications.action';
import {
  saveModuleSelector, fetchModuleSelector, loading, updateModuleSelector,
} from './TapApplications.selector';

import styles from './TapApplications.module.scss';
import PageHeader from './PageHeader/PageHeader';
import { BREAD_CRUMBS } from './TapConfigurations/Constants';

class CreateModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      module: '',
      s3url: '',
      version: '',
      editModule: false,
      loaded: false,
    };
  }

  componentDidMount() {
    const {
      history: {
        location: { pathname },
      }, match: {
        params: { feature },
      },
    } = this.props;
    const { fetchModuleAPI, apiBaseUrl, location } = this.props;
    if (pathname.endsWith('edit') && feature === 'tapchat') {
      fetchModuleAPI(apiBaseUrl, location.state.module, feature);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { fetchModuleResponse, location } = props;
    if (fetchModuleResponse && fetchModuleResponse.data && Object.keys(fetchModuleResponse.data).length > 0) {
      const { module, version } = fetchModuleResponse.data;
      if (location?.state?.module === module && (module !== state.module || version !== state.version) && !state.loaded) {
        return {
          ...fetchModuleResponse.data,
          editModule: true,
          loaded: true,
        };
      }
    }
    return null;
  }

  /**
    * @description Function to create or edit the module.
  */
  handleSave = (feature) => {
    const {
      module, s3url, version, editModule,
    } = this.state;
    const {
      apiBaseUrl, saveModuleAPI, updateModuleAPI, history,
    } = this.props;

    const payload = { module, version, s3url };
    if (editModule) {
      updateModuleAPI(apiBaseUrl, feature, payload);
    } else {
      payload.feature = feature;
      saveModuleAPI(apiBaseUrl, payload);
    }
    this.setState({ loaded: false });
    history.push(`/app/tapapplications/${feature}`);
  }

  /**
    * @description Function to handle the Onchage event of TextInput.
  */
  onChange = (e, type) => {
    this.setState({ [type]: e.target.value });
  }

  /**
    * @description Function to handle the cancel button click of add/edit module
  */
  onCancel = (feature) => {
    const {
      history,
    } = this.props;
    history.push(`/app/tapapplications/${feature}`);
  }

  renderHeader = () => {
    const { editModule } = this.state;
    return (
      <PageHeader 
        breadCrumbs={editModule ? BREAD_CRUMBS.TAP_CHAT_EDIT : BREAD_CRUMBS.TAP_CHAT_CREATE}
      />
    )
  }

  _renderCreateForm = () => {
    const {
      module, s3url, version, editModule,
    } = this.state;
    const {
      match: {
        params: { feature },
      }, loading,
    } = this.props;
    return (
      <React.Fragment>
        {loading ? <Loader />
          : (
            <div className={styles.version__control}>
               {this.renderHeader()}
              <div className={styles.create__module}>
                <Row>
                  <Col xs={24} md={24} sm={24} lg={11}>
                    <Row className={styles.mb24} align="middle" gutter={16}>
                      <Col xs={24} md={10} sm={24} lg={7}>
                        {__('Module')}
                      </Col>
                      <Col span={12}>
                        <Input id="module" value={module} inputWrapperClass={!module ? styles.show__error : ''} disabled={editModule} onChange={e => this.onChange(e, 'module')} />
                      </Col>
                    </Row>
                    <Row className={styles.mb24} align="middle" gutter={16}>
                      <Col xs={24} md={10} sm={24} lg={7}>
                        {__('S3 Url')}
                      </Col>
                      <Col span={12}>
                        <Input id="s3url" value={s3url} inputWrapperClass={!s3url ? styles.show__error : ''} disabled={editModule} onChange={e => this.onChange(e, 's3url')} />
                      </Col>
                    </Row>
                    <Row className={styles.mb24} align="middle" gutter={16}>
                      <Col xs={24} md={10} sm={24} lg={7}>
                        {__('Version')}
                      </Col>
                      <Col span={12}>
                        <Input id="version" value={version} inputWrapperClass={!version ? styles.show__error : ''} onChange={e => this.onChange(e, 'version')} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className={styles.module__footer}>
                <Button style={{ marginRight: '25px' }} onClick={() => this.onCancel(feature)}>
                  {__('Cancel')}
                </Button>
                <Button view="primary" onClick={() => this.handleSave(feature)} disabled={!module || !s3url || !version}>
                  {__('Save')}
                </Button>
              </div>
            </div>
          )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this._renderCreateForm()}
      </React.Fragment>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  saveModuleResponse: saveModuleSelector(),
  fetchModuleResponse: fetchModuleSelector(),
  updateModuleRepsone: updateModuleSelector(),
  loading: loading(),
});
/**
 * @description Map the actions (as props) that are required to dispatch actions from UI
 */
const mapDispatchToProps = (dispatch) => {
  const getListing = bindActionCreators(fetchListing, dispatch);
  const saveModuleAPI = bindActionCreators(saveModule, dispatch);
  const fetchModuleAPI = bindActionCreators(fetchModule, dispatch);
  const updateModuleAPI = bindActionCreators(updateModule, dispatch);
  return {
    saveModuleAPI,
    fetchModuleAPI,
    updateModuleAPI,
    getListing,
  };
};
CreateModule.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  fetchModuleAPI: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  saveModuleAPI: PropTypes.func.isRequired,
  updateModuleAPI: PropTypes.func.isRequired,
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateModule)
);
