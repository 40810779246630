import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _isEqual from 'lodash/isEqual';
import { createStructuredSelector } from 'reselect';
import { isEmpty } from '@tekion/tap-components/utils/helper';
import Button from 'tcomponents/atoms/Button';
import Loader from '@tekion/tap-components/atoms/Loader';
import Availability from '@tekion/tap-components/organisms/CalendarAvailability/Availability/Availability';
import { showConfirm } from '@tekion/tap-components/utils/helper.confirm';
import { EMPTY_OBJECT } from '@tekion/tap-components/constants/Constants';

import styles from './Availability.module.scss';
import { getUserInformation, saveUserInformation } from '../../../action/Calendar.action';
import { getUserStatus, loader } from '../Calendar/Calendar.selector';
import { fetchUserAvailabilitys, updateUserCustomAvailabilitys } from '../../../action/Availability.action';
import {
  getUserData,
  getAvailabilitys,
} from './Availability.selector';
import 'antd/dist/antd.css';
import {
  DEFAULT_TIME_FORMAT, FORMATTED_TIME_FORMAT, DEFAULT_DATE_FORMAT, FORMATTED_DATE_FORMAT,
} from '../../../constants/Constants';
import TokenManager from '@tekion/tap-components/utils/TokenManager';

function CalendarAvailability(props) {
  const {
    apiBaseUrl, history, fetchUserDeatils, userStatus, loading, fetchUserCustomAvailabilitys, userData, updateUserAvailabilitys, updateUserDeatils, userAvailabilitys,
  } = props;
  const availabilityRef = useRef(null);

  useEffect(() => {
    if (isEmpty(userStatus)) {
      fetchUserDeatils(apiBaseUrl).then((response) => {
        if (response === null) {
          const username = TokenManager.getItem('uname');
          const email = TokenManager.getItem('email');
          const updateUserPayload = {
            email: username || '',
            time_zone: moment.tz.guess(),
            time_format: '24 Hours',
            date_format: 'MM/DD/YYYY',
            status: true,
            has_outlook: true,
            has_zoom: false,
            name: username,
            slug: email.replace('@tekion.com', ''),
          };
          updateUserDeatils(apiBaseUrl, updateUserPayload).then((isUserUpdated) => {
            if (isUserUpdated) {
              fetchUserCustomAvailabilitys(apiBaseUrl);
            }
          });
        }
      });
    }
    if (isEmpty(userData)) {
      fetchUserCustomAvailabilitys(apiBaseUrl);
    }
  }, []);

  const timeFormat = useMemo(() => {
    const { time_format: timeFormat } = userStatus;
    if (timeFormat === '24 Hours') {
      return DEFAULT_TIME_FORMAT;
    }
    return FORMATTED_TIME_FORMAT;
  }, [userStatus]);

  const dateFormat = useMemo(() => {
    const { date_format: dateFormat } = userStatus;
    if (dateFormat === 'MM/DD/YYYY') {
      return FORMATTED_DATE_FORMAT;
    }
    return DEFAULT_DATE_FORMAT;
  }, [userStatus]);

  const onSaveDateOverides = useCallback((userAvailabilitys) => {
    const payload = {
      email: userStatus?.email,
      name: userStatus?.name,
      rule: userAvailabilitys,
      default: true,
      timezone: userStatus?.timezone || '',
    };
    return updateUserAvailabilitys(apiBaseUrl, payload, userData?.uuid);
  }, [userStatus, updateUserAvailabilitys, apiBaseUrl, userData]);

  const onSaveUserAvailability = useCallback(() => {
    const updatedUserAvailabilities = availabilityRef.current.getData().map(field => ({
      date: field.type === 'wkday' ? '' : moment(field?.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      day: field.day,
      intervals: field.intervals,
      type: field.type,
    }));
    onSaveDateOverides(updatedUserAvailabilities);
  }, [onSaveDateOverides]);

  const handleOnTabChange = useCallback((value) => {
    const updatedUserAvailabilities = availabilityRef.current.getData().map(field => ({
      date: field.type === 'wkday' ? '' : moment(field?.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      day: field.day,
      intervals: field.intervals,
      type: field.type,
    }));
    const exisitngAvailability = Object.values(userAvailabilitys).reduce((acc, arr) => acc.concat(arr), []);
    if (!_isEqual(exisitngAvailability, updatedUserAvailabilities)) {
      showConfirm({
        title: __('Attention!'),
        content: __('Please note that any changes you\'ve made will be lost if you don\'t save.'),
        okText: __('Save'),
        iconType: 'warning',
        onOk: async () => {
          const res = await onSaveDateOverides(updatedUserAvailabilities);
          if (res) {
            return history.push(`/app/settings/${value}`);
          }
          return null;
        },
        onCancel: () => history.push(`/app/settings/${value}`),
      });
    } else {
      return history.push(`/app/settings/${value}`);
    }
    return null;
  }, [history, userAvailabilitys, availabilityRef, onSaveDateOverides]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Tabs className={styles.calendarTab} activeKey="availability" onChange={handleOnTabChange}>
          <Tabs.TabPane tab="Basic Details" key="calendar">
          </Tabs.TabPane>
          <Tabs.TabPane tab="Availability" key="availability">
            {loading ? <Loader /> : (
              <>
                <div className={styles.header}>
                  <h4 className={styles.headerText}>{__('Set your weekly availability')}</h4>
                  <h6 className={styles.subHeaderText}>{__('Choose a schedule below to edit or create a new one that you can apply to your event types')}</h6>
                </div>
                <div className={styles.availability_conatiner}>
                  <Availability
                    userAvailabilitys={userAvailabilitys}
                    timeFormat={timeFormat}
                    dateFormat={dateFormat}
                    loading={loading}
                    ref={availabilityRef}
                    onSave={onSaveDateOverides}
                  />
                </div>
              </>
            )}
          </Tabs.TabPane>
        </Tabs>
      </div>

      <div className={styles.footer}>
        {/* <Button className={styles.cancelButton}>{__('Cancel')}</Button> onSaveUserAvailability */}
        <Button className={styles.saveButton} onClick={onSaveUserAvailability}>{__('Save')}</Button>
      </div>

    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  userStatus: getUserStatus(),
  userData: getUserData(),
  loading: loader(),
  userAvailabilitys: getAvailabilitys(),
});


const mapDispatchToProps = (dispatch) => {
  const fetchUserDeatils = bindActionCreators(getUserInformation, dispatch);
  const fetchUserCustomAvailabilitys = bindActionCreators(fetchUserAvailabilitys, dispatch);
  const updateUserAvailabilitys = bindActionCreators(updateUserCustomAvailabilitys, dispatch);
  const updateUserDeatils = bindActionCreators(saveUserInformation, dispatch);
  return {
    fetchUserDeatils,
    fetchUserCustomAvailabilitys,
    updateUserAvailabilitys,
    updateUserDeatils,
  };
};
CalendarAvailability.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  fetchUserDeatils: PropTypes.func.isRequired,
  fetchUserCustomAvailabilitys: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  userStatus: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  updateUserAvailabilitys: PropTypes.func.isRequired,
  updateUserDeatils: PropTypes.func.isRequired,
  userAvailabilitys: PropTypes.object.isRequired,
  history: PropTypes.object,
};

CalendarAvailability.defaultProps = {
  history: EMPTY_OBJECT,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarAvailability);
