import React, { useEffect, useRef, useState } from 'react';
import Dropdown from 'tcomponents/molecules/DropDown';
import cx from 'classnames';
import FontIcon from 'tcomponents/atoms/FontIcon';
import Input from 'tcomponents/atoms/Input';
import TMSelectRenderOptions from '../../atoms/TMSelectRenderOptions/TMSelectRenderOptions';
import { EMPTY_ARRAY, EMPTY_STRING, NO_OP } from '@tekion/tap-components/constants/Constants';
import styles from './TMSelect.module.scss';
import { isEmpty } from '@tekion/tap-components/utils/helper';
import PropType from 'prop-types';
import TMMultiSelectValue from '../../atoms/TMMultiSelectValue/TMMultiSelectValue';
import { NO_DATA_TEXT, DOWN_ARROR_KEYCODE, UP_ARROR_KEYCODE, ENTER_KEYCODE } from './TMSelect.constants';

const SIZE_CLASS_MAP = {
  small: styles.small, medium: styles.medium, large: styles.large,
};

const TMSelect = ({ allowSearch, allowClear, bordered, canTrimOptions, customOptionFilterer, disabled, dropdownClassName, getPopupContainer, isDataLoading, isInlineSearch, isMultiSelect, isMultiSelectBlurEnabled, isReadOnlyMode, listValidator, labelRender, maxDropdownOptions, notFoundContent, optionValueKey, onChange, options: optionsProp, optionSearchKey, onOptionSelect, onOptionDeSelect, placeholder, selectContainerClassName, selectedValue, showDropDownIcon, size }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [options, setOptions] = useState([]);
  const [currentSelectedOptionIndex, setcurrentSelectedOptionIndex] = useState(-1);
  const [searchText, setSearchText] = useState(EMPTY_STRING);
  const inputRef = useRef();

  useEffect(() => {
    if (!isDropdownVisible) {
      setSearchText(''); // Clear search text on dropdown close.
      return;
    }
    // When dropdown is opened, focus the input.
    if (inputRef.current) inputRef.current.focus();
    // Group selected options to the first.
    groupSelectedOptions(searchText);
  }, [isDropdownVisible, optionsProp]);

  const onOptionClick = (option) => {
    if (isReadOnlyMode) return;

    if (isMultiSelect) {
      onMultiSelectOptionClick(option);
    }
  };

  const onMultiSelectOptionClick = (option) => {
    if (isOptionSelected(option)) {
      onOptionDeSelect(option);
      if (isMultiSelectBlurEnabled) {
        multiSelectOptionChange(selectedValue.filter(item => item[optionValueKey] !== option[optionValueKey]));
      } else {
        onChange(selectedValue.filter(item => item[optionValueKey] !== option[optionValueKey]));
      }
    } else {
      onOptionSelect(option);
      if (isMultiSelectBlurEnabled) {
        multiSelectOptionChange([...selectedValue, option]);
      } else {
        onChange([...selectedValue, option]);
      }
    }
    if (inputRef.current) inputRef.current.focus();
  };

  const groupSelectedOptions = (searchValue) => {
    const splitOptions = { selectedOptions: [], unselectedOptions: [] };
    optionsProp.forEach((option) => {
      // Filter option by search text
      if (isInlineSearch && !isEmpty(searchValue) && !filterOption(option, searchValue)) return;

      // Split selected and unselected options, so that we can display selected at top
      splitOptions[isOptionSelected(option) ? 'selectedOptions' : 'unselectedOptions'].push(option);
    });

    const updatedOptions = canTrimOptions ? [...splitOptions.selectedOptions, ...splitOptions.unselectedOptions.slice(0, maxDropdownOptions)] : [...splitOptions.selectedOptions, ...splitOptions.unselectedOptions];
    setOptions(updatedOptions);
  };

  const filterOption = (option, searchValue) => customOptionFilterer ? customOptionFilterer(option, searchValue) : option?.[optionSearchKey]?.toLowerCase()?.includes(searchValue?.toLowerCase());

  const isOptionSelected = (option) => {
    if (isMultiSelect) {
      return selectedValue.find(item => item?.[optionValueKey] === option?.[optionValueKey]);
    }
    return option?.[optionValueKey] === selectedValue?.[optionValueKey];
  };

  // Render option if not disabled or if option is selected
  const canRenderOption = option => !disabled || isOptionSelected(option);

  const RenderData = () => {
    if (isDataLoading) return <Loader />;

    return (
      <div className={styles.dropdownOptions}>
        {
          !isEmpty(options) && options.map((option, index) => {
            if (!canRenderOption(option)) return null;
            if (listValidator && !listValidator(option)) {
              return null;
            }
            const isSelected = isOptionSelected(option);
            const disableOption = isMultiSelect && !allowClear && isSelected;
            return (
              <TMSelectRenderOptions
                indexId={index}
                key={option?.[optionValueKey]}
                option={option}
                disableOption={option?.disabled || disableOption}
                optionValueKey={optionValueKey}
                disabled={disabled}
                isReadOnlyMode={isReadOnlyMode}
                onOptionClick={onOptionClick}
                labelRender={labelRender}
                isMultiSelect={isMultiSelect}
                hideSelectedTickIcon={false}
                hideSelectedBgColor={false}
                isSelected={isSelected}
                isOptionKeyDown={index === currentSelectedOptionIndex}
              />
            );
          })
        }
        {
          isEmpty(options)
          // If search text is empty in dynamic search, don't show empty data.
          && (isInlineSearch || !isEmpty(searchText)) && (
            <div className={styles.noDataText}>
              { !isEmpty(notFoundContent) ? notFoundContent : NO_DATA_TEXT }
            </div>
          )
        }
      </div>
    );
  };

  const performInlineSearch = (searchValue) => {
    // If search text is cleared, options should be grouped.
    if (isEmpty(searchValue)) {
      groupSelectedOptions();
    } else {
      const filteredOptions = optionsProp.filter(option => filterOption(option, searchValue));
      setOptions(canTrimOptions ? filteredOptions.slice(0, maxDropdownOptions) : filteredOptions);
    }
    setcurrentSelectedOptionIndex(-1);
  };

  const onSearchTextChange = (e) => {
    const { value } = e.target;
    const searchValue = value.toLowerCase();
    setSearchText(value);
    if (isInlineSearch) {
      // Search based on options
      performInlineSearch(searchValue);
    } else {
      onSearchTextChangeProp(searchValue);
    }
  };

  const handleKeyDown = (e) => {
    const { keyCode = 0 } = e;
    if (keyCode === DOWN_ARROR_KEYCODE) {
      setcurrentSelectedOptionIndex(currentSelectedOptionIndex + 1 <= options.length - 1 ? currentSelectedOptionIndex + 1 : 0);
    } else if (keyCode === UP_ARROR_KEYCODE && currentSelectedOptionIndex > 0) {
      setcurrentSelectedOptionIndex(currentSelectedOptionIndex - 1);
    } else if (keyCode === ENTER_KEYCODE && options[currentSelectedOptionIndex]) {
      onOptionClick(options[currentSelectedOptionIndex]);
    }
  };

  const menu = !isDropdownVisible ? <></> : (
    <div>
      {
        allowSearch && !disabled && (
          <div className={styles.searchInput}>
            <Input
              autoFocus
              innerRef={inputRef}
              value={searchText}
              inputWrapperClass={styles.inputWrapperClass}
              placeholder={__('Search here')}
              onChange={onSearchTextChange}
              onKeyDown={handleKeyDown}
            />
          </div>
        )
      }
      {RenderData()}
    </div>
  );

  const clearSelectedValueForMultiSelect = (e) => {
    if (isMultiSelectBlurEnabled && isDropdownVisible) {
      multiSelectOptionChange([]);
    } else {
      onChange();
    }
    e.stopPropagation();
  };

  const TMSingleSelectValue = () => (
    <div className={cx(styles.singleSelect, selectValueClassName)}>
      {!isEmpty(selectedValue) && labelRender(selectedValue) }
      { allowClear && !disabled && <FontIcon onClick={clearSelectedValue} className={cx(styles.clearIcon, clearIconClassName)} size="S">icon-close-filled</FontIcon> }
    </div>
  );

  const renderSelectedOptions = () => (
    isMultiSelect ? <TMMultiSelectValue 
      labelRender={labelRender}
      allowClear={allowClear}
      isMultiSelectBlurEnabled={isMultiSelectBlurEnabled}
      disabled={disabled}
      clearSelectedValueForMultiSelect={clearSelectedValueForMultiSelect}
      selectedValue={selectedValue}
      showAddIcon={false}
    /> : <TMSingleSelectValue />
  );

  const isDropdownDisabled = () => {
    // When disabled props is true, don't disable dropdown for multi select unless options are empty.
    // because the dropdown should be visible with only selected options
    // but user actions on the options will be disabled
    if (disabled && (
      !isMultiSelect || (isMultiSelect && isEmpty(selectedValue))
    )) {
      return true;
    }

    return false;
  };

  const onVisibleChange = (visible) => {
    if (isMultiSelectBlurEnabled && !visible && isMultiSelect) {
      onChange(selectedValue);
    }
    if (!visible && currentSelectedOptionIndex !== -1) {
      setcurrentSelectedOptionIndex(-1);
    }
    setIsDropdownVisible(visible);
  };

  return <Dropdown
      disabled={isDropdownDisabled()}
      placement="bottomLeft"
      overlayClassName={cx(styles.selectDropdown, dropdownClassName)}
      visible={isDropdownVisible}
      getPopupContainer={getPopupContainer}
      onVisibleChange={visible => onVisibleChange(visible)}
      overlay={menu}
      trigger={['click']}
    >
      {/* <div style={style} selenium-id={seleniumId} className={cx(styles.selectContainer, selectContainerClassName, SIZE_CLASS_MAP[size], { [styles.bordered]: bordered }, 'tm_select_container')}> */}
      <div className={cx(styles.selectContainer, selectContainerClassName, SIZE_CLASS_MAP[size], { [styles.bordered]: bordered }, 'tm_select_container')}>
        { (!isEmpty(selectedValue) || typeof selectedValue === 'boolean') ? renderSelectedOptions() : (
          <div className={styles.placeholder}>
            {placeholder}
          </div>
        ) }
        <FontIcon size="S" className={cx(styles.dropDownIcon, { [styles.dropdownIconHidden]: !showDropDownIcon, [styles.dropDownOpen]: isDropdownVisible })}>tap-icon-expand</FontIcon>

      </div>
    </Dropdown>
}

TMSelect.propTypes = {
  allowSearch: PropType.bool,
  allowClear: PropType.bool,
  bordered: PropType.bool,
  canTrimOptions: PropType.bool, 
  customOptionFilterer: PropType.func,
  disabled: PropType.bool,
  dropdownClassName: PropType.string,
  getPopupContainer: PropType.func,
  isDataLoading: PropType.bool,
  isInlineSearch: PropType.bool,
  isMultiSelect: PropType.bool,
  isMultiSelectBlurEnabled: PropType.bool,
  isReadOnlyMode: PropType.bool,
  listValidator: PropType.func,
  labelRender: PropType.func,
  maxDropdownOptions: PropType.number,
  notFoundContent: PropType.string,
  optionValueKey: PropType.string,
  onChange: PropType.func,
  options: PropType.array,
  optionSearchKey: PropType.string,
  onOptionSelect: PropType.func,
  onOptionDeSelect: PropType.func,
  placeholder: PropType.string,
  selectContainerClassName: PropType.string,
  selectedValue: PropType.array,
  showDropDownIcon: PropType.bool,
  size: PropType.string,
}
TMSelect.defaultProps = {
  allowClear: true,
  allowSearch: false,
  bordered: false,
  canTrimOptions: false,
  disabled: false,
  dropdownClassName: '',
  isMultiSelect: false,
  isReadOnlyMode: false,
  optionValueKey: 'value',
  optionSearchKey: 'userName',
  placeholder: '',
  selectedValue: '',
  selectContainerClassName: '',
  selectValueClassName: '',
  size: 'medium',
  isDataLoading: false,
  isInlineSearch: true,
  showDropDownIcon: false, // This property is used to show dropdown indicator icon
  listValidator: undefined,
  isMultiSelectBlurEnabled: false,
  maxDropdownOptions: 10,
  notFoundContent: '',
  onChange: NO_OP,
  options: EMPTY_ARRAY,
  getPopupContainer: () => document.body,
  onOptionSelect: NO_OP,
  onOptionDeSelect: NO_OP,
  onDropdownVisibleChange: NO_OP,
}

export default TMSelect;
