import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { CONSTANTS } from '../constants/Constants';

const { ACTIONS } = CONSTANTS;
const initialState = {
  allGroups: EMPTY_ARRAY,
  groupTypes: EMPTY_ARRAY,
  activeGroup: EMPTY_OBJECT,
  addCard: EMPTY_OBJECT,
  loading: {
    getGroups: false,
    addGroup: false,
    editGroup: false,
    deleteGroup: false,
    updateGroup: false,
  },
};

export default (prevState, action) => {
  const state = prevState || initialState;
  switch (action.type) {
    case ACTIONS.FETCH_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        allGroups: action.payload,
      };
    case ACTIONS.FETCH_GROUP_TYPES_SUCCESS:
      return {
        ...state,
        groupTypes: action.payload,
      };
    case ACTIONS.FETCH_GROUP_SUCCESS:
      return {
        ...state,
        activeGroup: action.payload,
      };
    case ACTIONS.FETCH_ADD_GROUP_SUCCESS:
      return {
        ...state,
        addGroup: action.payload,
      };
    case ACTIONS.SET_LOADER:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      };
    default: return state;
  }
};
