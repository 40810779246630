// Third-party library imports.
import React from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';

// Tekion library imports.
import { isEmpty, renderIfElse } from '@tekion/tap-components/utils/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Button from 'tcomponents/atoms/Button';

// Tap library imports.
import CheckboxPalette from '@tekion/tap-components/molecules/CheckboxPalette';

// Local repo imports.
import { NOTIFICATION_TYPES, EMPTY_GROUPS_TEXT } from './SupportPortal.constants';
import './SupportPortal.scss';
import { compareNotificationSettingDiff, constructPayloads } from './SupportPortal.helper';

class SupportPortal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      headers: props.notifySettingHeaders,
      data: props.notifySettingData,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { notifySettingHeaders, notifySettingData } = nextProps;
    const { headers, data } = prevState;
    if (isEmpty(headers) && isEmpty(data)) {
      return {
        headers: notifySettingHeaders,
        data: notifySettingData,
      };
    }
    return null;
  }

  componentDidMount() {
    const { actions, apiBaseUrl, isNotifySettingFetched } = this.props;
    if (!isNotifySettingFetched) {
      const { getNotificationSetting } = actions;
      const payload = {
        member_only_notify: true,
        columns: ['id', 'name', 'notify'],
      };
      getNotificationSetting(apiBaseUrl, payload);
    }
  }

  handlePaletteChange = (paletteData) => {
    const { data } = this.state;
    const { accessor, keys, action, subKeys, isSubAction } = paletteData;
    const updatedData = produce(data, (draft) => {
      const updatedPalette = draft[accessor];
      const { type, value } = action;
      if(!isSubAction) {
      keys.forEach((key) => {
        const paletteRowIndex = updatedPalette.findIndex(row => row.key === key);
        const updatedPaletteRowsAction = updatedPalette[paletteRowIndex].actions;
        const paletteActionIndex = updatedPaletteRowsAction.findIndex(ele => ele.type === type);
        updatedPaletteRowsAction[paletteActionIndex] = {
          type,
          value,
        };
        subKeys.forEach((subKey) => {
          const { subActions } = updatedPalette[paletteRowIndex];
          const subPaletteRowIndex =  subActions.findIndex(row => row.key === subKey);
          const updatedSubPaletteRowsAction = subActions[subPaletteRowIndex].actions;
          const paletteActionIndex = updatedSubPaletteRowsAction.findIndex(ele => ele.type === type);
          updatedSubPaletteRowsAction[paletteActionIndex] = action;
          subActions[subPaletteRowIndex].actions = updatedSubPaletteRowsAction;
        })
      })
    }
    else{
      const paletteRowIndex = updatedPalette.findIndex(row => row.key === keys[0]);
      const { subActions, actions } = updatedPalette[paletteRowIndex];
      subKeys.forEach((subKey) => {
        const subPaletteRowIndex = subActions.findIndex(row => row.key === subKey);
        const updatedSubPaletteRowsAction = subActions[subPaletteRowIndex].actions;
        const subpaletteActionIndex = updatedSubPaletteRowsAction.findIndex(ele => ele.type === type);
        updatedSubPaletteRowsAction[subpaletteActionIndex] = {
          type,
          value,
        };
        subActions[subPaletteRowIndex].actions = updatedSubPaletteRowsAction;
        let overAllValue = subActions.reduce((accumulator, subAction) => {
          const { actions } = subAction;
          return actions.find((action) => action.type === type)?.value && accumulator; 
        }, true);
        const paletteActionIndex = actions.findIndex(ele => ele.type === type);
        actions[paletteActionIndex] = {
          type,
          value: overAllValue,
        };
      })
    }
    })

  

    this.setState({
      data: updatedData,
    });
  };

  handleSave = () => {
    const { data } = this.state;
    const { notifySettingData } = this.props;
    const differedData = compareNotificationSettingDiff(notifySettingData, data);
    if (!isEmpty(differedData)) {
      const { actions, apiBaseUrl } = this.props;
      const payloads = constructPayloads(differedData);
      const { saveNotificationSetting } = actions;
      saveNotificationSetting(apiBaseUrl, payloads);
    }
  }

  onExpand = (rowIndex, contentAccessor) => {
    const { data } = this.state;
    const updatedData = produce(data, (draft) => {
      const eventData = draft[contentAccessor][rowIndex];
      const { expanded } = eventData;
      eventData.expanded = !expanded;
    });
    this.setState({data: updatedData});   

  };
  render() {
    const { headers, data } = this.state;
    const {
      isNotifySettingLoading, isNotifySettingSaving, notifySettingData, isNotifySettingFetched,
    } = this.props;
    return (
      <React.Fragment>
        <div className="support-container">
          <h3>{__('Notification Settings')}</h3>
          {renderIfElse(isNotifySettingFetched && isEmpty(notifySettingData), () => (
            <div className="emptyGroupsInfo">
              <span className="icon-information-filled icon" />
              <h2>{EMPTY_GROUPS_TEXT.TITLE}</h2>
              <p>{EMPTY_GROUPS_TEXT.DESC1}</p>
              <p>{EMPTY_GROUPS_TEXT.DESC2}</p>
            </div>
          ), () => (
            <>
              <h4>{__('Group Name')}</h4>
              <CheckboxPalette
                paletteHeaders={headers}
                paletteActions={NOTIFICATION_TYPES}
                paletteDatas={data}
                loadingCheckboxPalette={isNotifySettingLoading}
                onPaletteChange={this.handlePaletteChange}
                onExpand={this.onExpand}
              />
            </>
          ))}
        </div>
        <div className="support-settings-footer">
          <Button
            view="primary"
            onClick={this.handleSave}
            disabled={isEmpty(data)}
            loading={isNotifySettingSaving}
          >Save
          </Button>
        </div>
      </React.Fragment>
    );
  }
}


SupportPortal.propTypes = {
  actions: PropTypes.object.isRequired,
  apiBaseUrl: PropTypes.string.isRequired,
  isNotifySettingLoading: PropTypes.bool,
  isNotifySettingSaving: PropTypes.bool,
  notifySettingData: PropTypes.object,
  notifySettingHeaders: PropTypes.array,
  isNotifySettingFetched: PropTypes.bool,
};

SupportPortal.defaultProps = {
  isNotifySettingLoading: false,
  isNotifySettingSaving: false,
  notifySettingData: EMPTY_OBJECT,
  notifySettingHeaders: EMPTY_ARRAY,
  isNotifySettingFetched: false,
};

export default SupportPortal;
