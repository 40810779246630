import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from 'tcomponents/atoms/Button';
import Card from 'tcomponents/molecules/Card';
import Loader from 'tcomponents/molecules/loader';

import { fetchListing } from '../../action/TapApplications.action';
import styles from './TapApplications.module.scss';

import { getListing, loading } from './TapApplications.selector';
import PageHeader from './PageHeader/PageHeader';
import { BREAD_CRUMBS } from './TapConfigurations/Constants';
import withSize from '../../organisms/withSize';

class Listing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      getListing, apiBaseUrl, match: {
        params: { feature },
      },
    } = this.props;
    this.feature = feature;
    getListing(apiBaseUrl, feature);
  }

  /**
  * @description Function will set the selected module and redirect to edit screen with selected module.
    * @param {Object} selectedModule - Object.
  */
  onSelectModule = (selectedModule) => {
    const { history } = this.props;
    history.push({
      pathname: `/app/tapapplications/${this.feature}/${selectedModule.module}/edit`,
      state: {
        module: selectedModule.module,
      },

    });
  };

  /**
    * @description Function to handle the Onclick event of AddNew button ,It will redirect to module create screen.
  */
  addModule = () => {
    const { history } = this.props;
    history.push(`/app/tapapplications/${this.feature}/create`);
  };

  /**
    * @description Function to render the card content.
  */
  _renderCardContent = module => (
    <React.Fragment>
      <h3 className={styles.moduleName}>{module?.module}</h3>
      <div>{module?.version}</div>
    </React.Fragment>
  );

  _renderCard = () => {
    const { loading, getListReponse, contentHeight } = this.props;
    if (loading || !Object.keys(getListReponse).length) {
      return <Loader />;
    }
    return (
      <div style={{ height: contentHeight }} className={styles.card__container}>
        {getListReponse.data.map(module => (
          <Card
            size="small"
            title=""
            className={styles.card__module}
            key={module.module}
            onClick={() => this.onSelectModule(module)}
          >
            {this._renderCardContent(module)}
          </Card>
        ))}
      </div>
    );
  };

  renderHeaderActionItems = () => (
    <Button
      view="primary"
      className={styles.add__btn}
      onClick={this.addModule}
    >
      {__('+ Add New')}
    </Button>
  )

  renderHeader = () => {
    return (
      <PageHeader 
        breadCrumbs={BREAD_CRUMBS.TAP_CHAT}
        actionItems={this.renderHeaderActionItems()}
      />
    )
  }

  _renderModules = () => (
      <div>
        <React.Fragment>
          <div className={styles.version__control}>
            {this.renderHeader()}
            {this._renderCard()}
          </div>
        </React.Fragment>
      </div>
  );

  render() {
    return (
      <React.Fragment>
        {this._renderModules()}
      </React.Fragment>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  getListReponse: getListing(),
  loading: loading(),
});
/**
 * @description Map the actions (as props) that are required to dispatch actions from UI
 */
const mapDispatchToProps = (dispatch) => {
  const getListing = bindActionCreators(fetchListing, dispatch);
  return {
    getListing,
  };
};
Listing.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  getListing: PropTypes.func.isRequired,
  getListReponse: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSize(Listing))
);
