import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from '@tekion/tap-components/utils/helper';
import { NO_OP } from '@tekion/tap-components/constants/Constants';

export default function withPagination(ComposedComponent) {
  class Pagination extends React.Component {
    constructor(props) {
      super(props);
      const { initPagination } = props;
      const { pageSize, currentPage, pageSizeOptions } = initPagination || {};
      this.state = {
        pageSize,
        currentPage,
        totalNumberOfPages: 1,
        pageSizeOptions,
        currentData: [],
      };
    }

    componentDidMount() {
      const { pageSize, currentPage } = this.state;
      const { data } = this.props;
      this.setupTable(data, currentPage, pageSize);
    }

    componentDidUpdate(prevProps) {
      const { data: prevData, initPagination: prevInitPagination } = prevProps;
      const { initPagination, data } = this.props;
      const { pageSize, currentPage } = this.state;
      if (!isEmpty(initPagination) && !isEmpty(prevInitPagination)) {
        const { currentPage: propCurrentPage, pageSize: propPageSize } = initPagination;
        if (currentPage !== propCurrentPage || pageSize !== propPageSize) {
          this.setupCurrentPage(initPagination);
        }
      }
      if (prevData !== data) {
        this.setupTable(data, currentPage, pageSize);
      }
    }

    setupCurrentPage = (initPagination) => {
      this.setState({ currentPage: initPagination.currentPage, pageSize: initPagination.pageSize });
    };

    setupTable = (data, currentPage, pageSize) => {
      const start = this.getStart(currentPage, pageSize);
      const end = this.getEnd(currentPage, pageSize);
      this.calculateTotalNumberOfPages(data.length, pageSize);
      this.setState({ currentData: data.slice(start, end) });
    }

    getStart = (currentPage, pageSize) => (currentPage - 1) * pageSize;

    getEnd = (currentPage, pageSize) => currentPage * pageSize;

    calculateTotalNumberOfPages = (totalData, pageSize) => {
      let totalNumberOfPages;
      const totalBaseDatas = Math.floor(totalData / pageSize);
      if (totalBaseDatas < 1) {
        totalNumberOfPages = 1;
      } else {
        const overflowDatas = totalData % pageSize;
        if (overflowDatas > 0) {
          totalNumberOfPages = totalBaseDatas + 1;
        } else {
          totalNumberOfPages = totalBaseDatas;
        }
      }
      this.setState({
        totalNumberOfPages,
      });
    };

    onChangePage = (pages) => {
      const { page, resultsPerPage } = pages;
      const { data, fetchData } = this.props;
      this.calculateTotalNumberOfPages(data.length, resultsPerPage);
      const currentData = this.sliceData(data, page, resultsPerPage);
      this.setState({
        currentPage: page, pageSize: resultsPerPage, currentData,
      });
      fetchData(page, resultsPerPage);
    };

    sliceData = (data, currentPage, pageSize) => {
      const start = this.getStart(currentPage, pageSize);
      const end = this.getEnd(currentPage, pageSize);
      const slicedData = data.slice(start, end);
      return slicedData;
    }

    render() {
      const {
        columns, isMultiSort, showPagination, data, loading, minRows, onRowClick, getDataFromRemote, ...rest
      } = this.props;
      const {
        pageSize, currentPage, totalNumberOfPages, pageSizeOptions, currentData,
      } = this.state;
      return (
        <ComposedComponent
          columns={columns}
          isMultiSort={isMultiSort}
          showPagination={showPagination}
          data={getDataFromRemote ? data : currentData}
          loading={loading}
          minRows={minRows}
          onRowClick={onRowClick}
          {...rest}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          changePage={this.onChangePage}
          totalNumberOfPages={totalNumberOfPages}
          currentPage={currentPage}
        />
      );
    }
  }

  Pagination.propTypes = {
    fetchData: PropTypes.func,
    onRowClick: PropTypes.func,
    onChangePage: PropTypes.func,
    getDataFromRemote: PropTypes.bool,
    totalDatasCount: PropTypes.number,
    data: PropTypes.array,
    pageSize: PropTypes.number,
    pageSizeOptions: PropTypes.array,
    isMultiSort: PropTypes.bool,
    showPagination: PropTypes.bool,
    columns: PropTypes.array,
    loading: PropTypes.bool,
    minRows: PropTypes.number,
    initPagination: PropTypes.object,
  };

  Pagination.defaultProps = {
    fetchData: NO_OP,
    onRowClick: NO_OP,
    onChangePage: NO_OP,
    totalDatasCount: 0,
    getDataFromRemote: false,
    data: [],
    pageSize: 25,
    pageSizeOptions: [25, 50, 75],
    isMultiSort: true,
    showPagination: true,
    columns: [],
    loading: false,
    minRows: 0,
    initPagination: {
      pageSize: 25,
      pageSizeOptions: [25, 50, 75],
      currentPage: 1,
    },
  };

  return Pagination;
}
