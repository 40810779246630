import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import {
  fetchListingAPI, saveModuleAPI, fetchModuleAPI, updateModuleAPI,
} from '../api/TapApplications';
import { CONSTANTS } from '../constants/Constants';
const { ACTIONS } = CONSTANTS;


/**
 * @description Function to fetch list of modules.
 * @param {String} baseUrl - baseurl to fetch the module.
 * @param {String} feature - fetch all modules under this feature
 */
export const fetchListing = (baseUrl, feature) => (
  (dispatch) => {
    dispatch({ type: ACTIONS.LOADER, payload: true });
    return fetchListingAPI(baseUrl, feature).then((response) => {
      dispatch({ type: ACTIONS.LOADER, payload: false });
      dispatch({ type: ACTIONS.FETCH_LISTING_SUCESS, payload: response });
    }).catch((error) => {
      dispatch({ type: ACTIONS.LOADER, payload: false });
      dispatch({ type: ACTIONS.FETCH_LISTING_ERROR, payload: error });
    });
  }
);

/**
 * @description Function to fetch information(module,s3url,version) about the selected module for view/edit.
 * @param {String} baseUrl - baseurl to fetch the module information.
 * @param {String} module - module name
 * @param {String} feature - module that belong to this feature
 */
export const fetchModule = (baseUrl, module, feature) => (
  (dispatch) => {
    dispatch({ type: ACTIONS.LOADER, payload: true });
    return fetchModuleAPI(baseUrl, module, feature).then((response) => {
      dispatch({ type: ACTIONS.LOADER, payload: false });
      dispatch({ type: ACTIONS.FETCH_MODULE_SUCESS, payload: response });
    }).catch((error) => {
      dispatch({ type: ACTIONS.LOADER, payload: false });
      dispatch({ type: ACTIONS.FETCH_MODULE_ERRROR, payload: error });
    });
  }
);

/**
 * @description Function to save the module
 * @param {String} baseUrl - baseurl to save the module.
 * @param {Object} data - {module: moduleName, s3url: awsurl, version: versionof module, feature: feature name}
 */
export const saveModule = (baseUrl, data) => (
  (dispatch) => {
    dispatch({ type: ACTIONS.LOADER, payload: true });
    return saveModuleAPI(baseUrl, data).then(() => {
      dispatch({ type: ACTIONS.LOADER, payload: false });
      dispatch(fetchListing(baseUrl, data.feature));
      toaster('success', __('Module added successfully'));
    }).catch((error) => {
      toaster('error', `${error.status} ${__('error occured while adding module')} `);
      dispatch({ type: ACTIONS.LOADER, payload: false });
    });
  }
);

/**
 * @description Function to update the module
 * @param {String} baseUrl - baseurl to update the module.
 * @param {String} module - module name
 * @param {Object} data - {module: moduleName, s3url: awsurl, version: versionof module, feature: feature name}
 */
export const updateModule = (baseUrl, feature, data) => (
  (dispatch) => {
    dispatch({ type: ACTIONS.LOADER, payload: true });
    return updateModuleAPI(baseUrl, feature, data).then(() => {
      dispatch({ type: ACTIONS.LOADER, payload: false });
      dispatch(fetchListing(baseUrl, feature));
      dispatch(resetModule());
      toaster('success', 'Module Updated successfully');
    }).catch((error) => {
      toaster('error', `${error.status} error occured while updating module `);
      dispatch({ type: ACTIONS.LOADER, payload: false });
    });
  }
);

/**
 * @description Function to reset the redux store
 */
export const resetModule = () => (
  (dispatch) => {
    dispatch({ type: ACTIONS.RESET_MODULE, payload: true });
  }
);
