import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import _isEqual from 'lodash/isEqual';
import { Row, Col, Tabs } from 'antd';
import TextInput from 'tcomponents/molecules/TextInput';
import Select from 'tcomponents/molecules/Select';
import Switch from 'tcomponents/molecules/Switch';
import Checkbox from 'tcomponents/atoms/checkbox';
import Button from 'tcomponents/atoms/Button';
import ErrorPage from '@tekion/tap-components/atoms/ErrorPage';
import { isEmpty } from '@tekion/tap-components/utils/helper';
import TokenManager from '@tekion/tap-components/utils/TokenManager';
import Loader from '@tekion/tap-components/atoms/Loader';
import { EMPTY_OBJECT } from '@tekion/tap-components/constants/Constants';
import { showConfirm } from '@tekion/tap-components/utils/helper.confirm';

import ZoomLogo from '../../../asset/zoomIcon.svg';
import TeamsLogo from '../../../asset/teamsIcon.svg';
import { getUserInformation, saveUserInformation } from '../../../action/Calendar.action';
import { getUserStatus, loader } from './Calendar.selector';
import './Calendar.scss';

export const filterOption = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
const getAccountList = userData => ([
  {
    id: 1, label: 'Teams', isAccountLinked: true, logo: TeamsLogo,
  },
  {
    id: 2, label: 'Zoom', isAccountLinked: userData.has_zoom || false, logo: ZoomLogo,
  },
]);
class Calendar extends React.Component {
  constructor(props) {
    super(props);
    const email = TokenManager.getItem('email');
    const slug = email ? email.replace('@tekion.com', '') : '';
    const { userStatus } = props;
    this.state = {
      dateFormats: [{ label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' }, { label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' }],
      timeFormats: [{ label: '24 Hours', value: '24 Hours' }, { label: '12 Hour (AM / PM)', value: '12 Hour (AM / PM)' }],
      selectedFields: {},
      isDataAvailable: false,
      calendarAddFields: {
        date_format: 'MM/DD/YYYY',
        time_format: '24 Hours',
        slug,
      },
      loaded: false,
      userStatus,
      accountList: getAccountList(userStatus),
    };
    this.zerothTime = moment('00:00', 'HH:mm');
  }

  componentDidMount() {
    const { apiBaseUrl, fetchUserInformation, userStatus } = this.props;
    if (isEmpty(userStatus)) {
      fetchUserInformation(apiBaseUrl);
    } else {
      this.setState({
        calendarAddFields: { ...userStatus },
        loaded: true,
        isDataAvailable: true,
        userStatus,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { userStatus } = props;
    const { userStatus: prevUserStatus } = state;
    if (prevUserStatus !== userStatus && !state.loaded) {
      return {
        loaded: true,
        isDataAvailable: true,
        calendarAddFields: { ...userStatus },
        userStatus,
        accountList: getAccountList(userStatus),
      };
    }

    return null;
  }

  createOptionsForDropDown = options => (
    options.map(option => ({
      label: __(option.value),
      value: option.value,
    }))
  );

  handleOnSelect = (value, type) => {
    const { calendarAddFields } = this.state;
    if (value && value.target && value.target.type === 'text') {
      calendarAddFields[type] = value.target.value;
    } else {
      calendarAddFields[type] = value;
    }
    this.setState({ calendarAddFields });
  }

  createOptionsForTimeZone = () => (
    this.sortedTimeZone().map(option => ({
      label: option.label,
      value: option.value,
    }))
  )

  createOptionsForMonths = data => (
    data.map(option => ({
      label: option,
      value: option,
    }))
  )

  sortedTimeZone = () => {
    const sortedArray = moment.tz.names().map((option, index) => ({
      label: `(GMT${moment.tz(moment.tz.names()[index]).format('Z')}) ${option}`,
      value: option,
    }));
    sortedArray.sort((a, b) => {
      let returnValue = 0;
      const re = /^\(GMT([+-]\d{1,2}):(\d{1,2})\).*$/;
      const aOffset = parseFloat(a.label.replace(re, '$1.$2'));
      const bOffset = parseFloat(b.label.replace(re, '$1.$2'));
      if (aOffset < bOffset) {
        returnValue = -1;
      } else if (aOffset > bOffset) {
        returnValue = 1;
      }
      return returnValue;
    });
    return sortedArray;
  }

  checkedWeekdays = (day) => {
    const { calendarAddFields } = this.state;
    const checkFindIndex = calendarAddFields && calendarAddFields.availability && calendarAddFields.availability.rules && calendarAddFields.availability.rules.findIndex(obj => obj.day === day);
    if (checkFindIndex !== undefined && checkFindIndex !== -1) {
      return true;
    }
    return false;
  }

  loadWeekDays = () => {
    const { weeks } = this.state;
    return weeks.map(obj => (
      <div className="mb10" key={obj.day}>
        <Checkbox checked={this.checkedWeekdays(obj.day)} onChange={(e) => { this.onChangeWeekDays(e, obj); }}>{obj.dayLabel}</Checkbox>
      </div>
    ));
  }

  onChangeWeekDays = (e, obj) => {
    const { calendarAddFields } = this.state;
    if (calendarAddFields.availability === undefined) {
      calendarAddFields.availability = {
        name: 'Default Hours',
        rules: [],
      };
    }
    if (e.target.checked) {
      calendarAddFields.availability.rules.push(obj);
    } else {
      const index = calendarAddFields && calendarAddFields.availability && calendarAddFields.availability.rules.findIndex(val => val.day === obj.day);
      calendarAddFields.availability.rules.splice(index, 1);
    }
    this.setState({ calendarAddFields });
  }

  displayMonths = () => {
    const { selectedFields } = this.state;
    return (
      <Select
        showSearch
        value={selectedFields.month === undefined || !selectedFields.month ? moment().format('MMM') : selectedFields.month}
        onChange={(value) => { this.handleOnSelect(value, 'month'); }}
        options={this.createOptionsForMonths(moment.monthsShort())}
        style={{ minWidth: '6rem' }}
        filterOption
        allowClear
      />
    );
  }

  displayMonthDates = () => {
    const { selectedFields } = this.state;
    const getSelectedMonthDays = moment(`${moment().format('Y')}-${selectedFields.month === undefined || !selectedFields.month ? moment().format('MMM') : selectedFields.month}`);
    return (
      <Select
        showSearch
        value={selectedFields.days}
        onChange={(value) => { this.handleOnSelect(value, 'days'); }}
        options={this.createOptionsForMonths(Array.from(Array(getSelectedMonthDays.daysInMonth()), (_, i) => i + 1))}
        style={{ minWidth: '6rem' }}
        filterOption
        allowClear
      />
    );
  }


  statusChange = (value) => {
    const { userStatus } = this.props;
    this.setState({ calendarAddFields: { ...userStatus, status: value } });
  }

  updateUserSettings = () => {
    const { updateUserInformation, apiBaseUrl } = this.props;
    const { calendarAddFields, accountList } = this.state;
    const {
      slug, date_format: dateFormat, time_format: timeFormat, timezone,
    } = calendarAddFields;
    const username = TokenManager.getItem('uname');
    const email = TokenManager.getItem('email');
    const payload = {
      email: email || '',
      time_zone: calendarAddFields.timezone === undefined ? moment.tz.guess() : timezone,
      date_format: dateFormat,
      time_format: timeFormat,
      status: calendarAddFields?.status,
      has_outlook: true,
      has_zoom: accountList[1].isAccountLinked,
      name: username,
      slug: !slug ? calendarAddFields.slug = email.replace('@tekion.com', '') : slug,
    };
    return updateUserInformation(apiBaseUrl, payload);
  }

  addZoomLink = (e) => {
    const { calendarAddFields } = this.state;
    calendarAddFields.location = {
      meeting_link: e.target.value,
    };
    this.setState({ calendarAddFields });
  }

  addUserName = (e) => {
    const { calendarAddFields } = this.state;
    calendarAddFields.slug = e.target.value.toLowerCase().replace(/[^a-z]/g, '');
    this.setState({ calendarAddFields });
  }

  onChangeAccountStatus = (fieldName) => {
    const { accountList } = this.state;
    if (fieldName === 'Zoom') {
      accountList[1].isAccountLinked = !accountList[1].isAccountLinked;
    }
    this.setState({ accountList });
  }

  renderAccountLists = () => {
    const { accountList } = this.state;
    return accountList.map((data) => {
      const { isAccountLinked, label, logo } = data;
      return (
        <div className="user-Account">
          <span className="content-Text"><img src={logo} alt="logo"></img>{__(label)}</span>
          <Button
            className={isAccountLinked ? 'connectedButton' : 'disconnectedButton'}
            onClick={() => this.onChangeAccountStatus(label)}
            disabled={label === 'Teams'}
          >
            {isAccountLinked ? __('Connected') : __('Connect')}
          </Button>
        </div>
      );
    });
  }

  renderUserInformation = () => {
    const { userStatus } = this.props;
    return (
      <>
        <div className="userData-Label">
          {__('Username')}
        </div>
        <h4 className="userData-Value">{userStatus.slug}</h4>
      </>
    );
  }

  handleOnTabChange = (value) => {
    const { history, userStatus } = this.props;
    const { calendarAddFields, accountList } = this.state;
    const prevAccountList = getAccountList(userStatus);
    if (!_isEqual(userStatus, calendarAddFields) || !_isEqual(prevAccountList, accountList)) {
      showConfirm({
        title: __('Attention!'),
        content: __('Please note that any changes you\'ve made will be lost if you don\'t save.'),
        okText: __('Save'),
        iconType: 'warning',
        onOk: async () => {
          const res = await this.updateUserSettings();
          if (res) {
            return history.push(`/app/settings/${value}`);
          }
          return null;
        },
        onCancel: () => history.push(`/app/settings/${value}`),
      });
    } else {
      return history.push(`/app/settings/${value}`);
    }
    return null;
  }

  _renderBasicDetails = () => {
    const {
      dateFormats, timeFormats,
      calendarAddFields, isDataAvailable,
    } = this.state;

    return (
      <Row>
        <Col className="basicDetailsContainer" xs={24} md={24} sm={24} lg={14}>
          <Row className="mb20" align="middle" gutter={16}>
            <Col xs={24} md={10} sm={24} lg={7}>{__('Date Format')}</Col>
            <Col xs={24} md={10} sm={24} lg={12}>
              <Select
                showSearch
                value={calendarAddFields.date_format}
                onChange={(value) => { this.handleOnSelect(value, 'date_format'); }}
                options={this.createOptionsForDropDown(dateFormats)}
                style={{ width: '100%' }}
                filterOption
                className="calendarFields"
                allowClear
              />
            </Col>
          </Row>
          <Row className="mb20" align="middle" gutter={16}>
            <Col xs={24} md={10} sm={24} lg={7}>{__('Time Format')}</Col>
            <Col xs={24} md={10} sm={24} lg={12}>
              <Select
                showSearch
                value={calendarAddFields.time_format}
                onChange={(value) => { this.handleOnSelect(value, 'time_format'); }}
                options={this.createOptionsForDropDown(timeFormats)}
                style={{ width: '100%' }}
                filterOption
                className="calendarFields"
                allowClear
              />
            </Col>
          </Row>

          <Row className="mb20" align="middle" gutter={16}>
            <Col xs={24} md={10} sm={24} lg={7}>{__('Time Zone')}</Col>
            <Col xs={24} md={10} sm={24} lg={12}>
              <Select
                showSearch
                value={calendarAddFields.timezone === undefined ? moment.tz.guess() : calendarAddFields.timezone}
                onChange={(value) => { this.handleOnSelect(value, 'timezone'); }}
                options={this.createOptionsForTimeZone()}
                style={{ width: '100%' }}
                allowClear
                className="calendarFields"
                filterOption={filterOption}
              />
            </Col>
          </Row>
          <Row className="mb20" align="middle" gutter={16}>
            <Col xs={24} md={10} sm={24} lg={7}>{__('Calendar Username')}</Col>
            <Col xs={24} md={10} sm={24} lg={12}>
              <TextInput className="calendarUserName" disabled={isDataAvailable} value={calendarAddFields.slug} onChange={this.addUserName} />
            </Col>
          </Row>

          <Row className="mb20" align="middle" gutter={16}>
            <Col xs={24} md={10} sm={24} lg={7}>{__('Allow Meetings to be booked')}</Col>
            <Col xs={24} md={10} sm={24} lg={12}>
              <Switch checked={calendarAddFields?.status} onChange={this.statusChange} />
            </Col>
          </Row>
          <div className="userLinkedAccounts">
            <h4 className="header-text">{__('Linked Accounts')}</h4>
            {this.renderAccountLists()}
          </div>
        </Col>
      </Row>
    );
  }

  _renderCalendarSettings = () => {
    const { loading } = this.props;

    return (
      <React.Fragment>
        <div className="calendar-container">
          <Tabs className="calendarTab" activeKey="basicDetails" onChange={this.handleOnTabChange}>
            <Tabs.TabPane tab="Basic Details" key="basicDetails">
              {loading ? <Loader /> : this._renderBasicDetails()}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Availability" key="availability">
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className="calendar-settings-footer">
          <Button disabled={loading} view="primary" onClick={this.updateUserSettings}>{__('Save')}</Button>
        </div>
      </React.Fragment>
    );
  };

  _renderErrorComponent = () => (<ErrorPage notFound />)

  render() {
    const { PARTNERS_SITE } = process.env;

    return (
      <React.Fragment>
        {PARTNERS_SITE === 'true' ? this._renderErrorComponent() : this._renderCalendarSettings()}
      </React.Fragment>
    );
  }
}

/**
 * @description Map the state objects (as props) that are required to render the details in the UI
 */
const mapStateToProps = createStructuredSelector({
  userStatus: getUserStatus(),
  loading: loader(),
});

/**
 * @description Map the actions (as props) that are required to dispatch actions from UI
 */
const mapDispatchToProps = (dispatch) => {
  const fetchUserInformation = bindActionCreators(getUserInformation, dispatch);
  const updateUserInformation = bindActionCreators(saveUserInformation, dispatch);
  return {
    fetchUserInformation,
    updateUserInformation,
  };
};

Calendar.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  fetchUserInformation: PropTypes.func.isRequired,
  updateUserInformation: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  userStatus: PropTypes.object.isRequired,
  history: PropTypes.object,
};

Calendar.defaultProps = {
  history: EMPTY_OBJECT,
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
