import { createSelector } from 'reselect';

const UserManagement = state => state.userManagement;
const skeleton = state => state.skeleton;

const getAllUsers = () => createSelector(
  UserManagement,
  subState => {
    const formatedData = subState?.allUsers.map((eachValue) => {
      const lastLogin = new Date(eachValue.last_login);
      const signedUpOn = new Date(eachValue.signed_up_on);
      return {
        ...eachValue,
        lastLoginDate: !isNaN(lastLogin) ? `${lastLogin.getFullYear()}/${lastLogin.getMonth() + 1}/${lastLogin.getDate()}` : '-',
        signedUpOnDate: !isNaN(signedUpOn) ? `${signedUpOn.getFullYear()}/${signedUpOn.getMonth() + 1}/${signedUpOn.getDate()}` : '-',
      };
    });
    return formatedData;
  }
);

const loading = () => createSelector(
  UserManagement,
  subState => subState?.isTableLoading
);

const fetching = () => createSelector(
  UserManagement,
  subState => subState?.isUserFetching
)

const getWorkSpaceSize = () => createSelector(
  skeleton,
  substate => substate?.workSpaceSize
);

const getFilteredUsers = () => createSelector(
  UserManagement,
  substate => substate?.filteredUsers
);

export {
  getWorkSpaceSize, getAllUsers, loading, getFilteredUsers, fetching
};
