import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { CONSTANTS } from '../constants/Constants';

const { ACTIONS } = CONSTANTS;
const initialState = {
  loading: {
    fetchMobileConfigs: false,
    fetchActiveMobileConfig: false,
    createOrUpdate: false,
  },
  mobileConfigs: EMPTY_ARRAY,
  activeMobileConfig: null,
};

export default (prevState, action) => {
  const state = prevState || initialState;
  switch (action.type) {
    case ACTIONS.SET_MOBILE_CONFIG:
      return {
        ...state,
        mobileConfigs: action.payload,
      };
    case ACTIONS.SET_MOBILE_CONFIG_LOADING:
      return {
        ...state,
        loading: { ...state.loading, [action.field]: action.payload },
      };
    case ACTIONS.SET_ACTIVE_MOBILE_CONFIG:
      return {
        ...state,
        activeMobileConfig: action.payload,
      };
    default: return state;
  }
};
