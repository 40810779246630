import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import {
  getAllGroups, getIndividualGroup, createGroup, updateGroup, deleteGroup, groupTypes,
} from './urls';
import Http, { doGet, doDelete } from '../services/http';

export const fetchAllGroupsAPI = url => doGet(`${url}${getAllGroups}`);
export const fetchGroupAPI = (url, groupKey) => doGet(`${url}${getIndividualGroup}`.replace('{groupKey}', groupKey));
export const createGroupAPI = (url, data) => Http.post('TAP', `${url}${createGroup}`, data, EMPTY_OBJECT);
export const updateGroupAPI = (url, data) => Http.post('TAP', `${url}${updateGroup}`, data, EMPTY_OBJECT);
export const deleteGroupAPI = (url, data) => doDelete(`${url}${deleteGroup}`, data);
export const fetchGroupTypes = url => doGet(`${url}${groupTypes}`);
