import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Tabs } from 'antd';
import Checkbox from 'tcomponents/atoms/checkbox';

import UserImageMigration from './UserImageMigration';
import { migrateImage, fetchUsersWithImageV1, deleteUserFromV1 } from '../../action/UserManagement.action';
import {
  loading, getWorkSpaceSize, getAllUsers, getFilteredUsers, fetching,
} from './UserImageMigration.selector';
import Layout from '../../molecules/Layout/Layout';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      migrateImage,
      fetchUsersWithImageV1,
      deleteUserFromV1,
    }, dispatch),
  };
}

const mapStateToProps = createStructuredSelector({
  isTableLoading: loading(),
  allUsers: getAllUsers(),
  isUserFetching: fetching(),
  filteredUsers: getFilteredUsers(),
  workSpaceSize: getWorkSpaceSize(),
});

const { TabPane } = Tabs;
const UserImageMigrationContainer = (props) => {
  const [pageType, setPageType] = useState('v1');
  const [filterWithImage, setFilterWithImage] = useState(false);
  return (
    <Layout>
      <div
        style={{
          height: 'calc(100vh - 90px)',
          overflow: 'hidden',
          margin: ' 62px 0 0 65px',
          padding: '0 0.5rem',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Tabs defaultActiveKey={pageType} onChange={setPageType}>
            <TabPane tab="V1" key="v1"></TabPane>
            <TabPane tab="V2" key="v2"></TabPane>
          </Tabs>
          <Checkbox label={__('Only with profile picture')} onToggle={setFilterWithImage} checked={filterWithImage} />
        </div>
        <UserImageMigration key={`${pageType}-${filterWithImage}`} {...props} pageType={pageType} filterWithImage={filterWithImage} />
      </div>
    </Layout>
  );
};


export default connect(mapStateToProps, mapDispatchToProps)(UserImageMigrationContainer);
