import { createSelector } from 'reselect';
/**
 * @description Selector Function for getting the specified state from reducer
 */
const taskReducer = state => state.task;

const makePreferences = () => createSelector(taskReducer,
  substate => substate.perference);

const makeFetchApiStatus = () => createSelector(taskReducer,
  substate => substate.isFetchApiCalled);

const makeTmUsers = () => createSelector(taskReducer,
  substate => substate.tmUsers);

const makeTmTranslateUsers = () => createSelector(taskReducer,
  substate => substate.tmTranslateUsers);

const getIsFetchOptions = () => createSelector(taskReducer,
  substate => substate.isFetchOptions);

export {
  makePreferences,
  makeFetchApiStatus,
  makeTmUsers,
  makeTmTranslateUsers,
  getIsFetchOptions
};
