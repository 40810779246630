
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FOOTER_HEIGHT, HEADER_HEIGHT, SIDEBAR_WIDTH } from '../constants/Constants';

export default function withSize(ComposedComponent) {
  class Size extends React.PureComponent {
    render() {
      const {
        workspaceSize, ...rest
      } = this.props;
      const { contentHeight, contentWidth } = workspaceSize;
      return (
        <div
          style={
            {
              height: contentHeight,
              width: contentWidth,
              marginBottom: FOOTER_HEIGHT,
              marginLeft: SIDEBAR_WIDTH,
              marginTop: HEADER_HEIGHT,
            }}
        >
          <ComposedComponent
            contentHeight={contentHeight}
            contentWidth={contentWidth}
            {...rest}
          />
        </div>
      );
    }
  }

  Size.propTypes = {
    workspaceSize: PropTypes.object.isRequired,
  };

  const mapStateToProps = state => ({
    workspaceSize: state.skeleton.workSpaceSize,
  });
  return connect(mapStateToProps)(Size);
}
