import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import FontIcon from 'tcomponents/atoms/FontIcon';
import styles from './TMSelectRenderOptions.module.scss';

const TMSelectRenderOptions = ({
  option, optionValueKey, disabled, isReadOnlyMode,
  onOptionClick, listRender, labelRender, isMultiSelect, hideSelectedTickIcon,
  hideSelectedBgColor, indexId, disableOption = false, isSelected, isOptionKeyDown,
}) => {
  const optionRef = useRef(null);

  useEffect(() => {
    if (isOptionKeyDown && optionRef?.current) {
      optionRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [isOptionKeyDown]);

  const handleOptionClick = () => {
    if (disabled || disableOption) {
      return false;
    }
    onOptionClick(option);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      ref={optionRef}
      key={option[optionValueKey]}
      className={cx(styles.dropdownOption, 'tm_select_dropdown_option', {
        [styles.readOnlyMode]: isReadOnlyMode,
        [styles.selected]: isSelected && !isReadOnlyMode && !hideSelectedBgColor,
        [styles.hovered]: isOptionKeyDown,
        [styles.optionDisabled]: disableOption,
      })}
      disabled={disabled || disableOption}
      onClick={handleOptionClick}
    >
      {listRender ? listRender(option, indexId) : labelRender(option, indexId)}
      {
        isMultiSelect && !isReadOnlyMode && !hideSelectedTickIcon && (
          <FontIcon size="S" className={styles.tickIcon}>icon-tick1</FontIcon>
        )
      }
    </div>
  );
};

export default TMSelectRenderOptions;
