import React from "react";
import cx from 'classnames';
import { isEmpty } from "@tekion/tap-components/utils/helper";
import styles from './TMMultiSelectValue.module.scss';

const TMMultiSelectValue = ({ labelRender, allowClear, isMultiSelectBlurEnabled, disabled, clearIconClassName, clearSelectedValueForMultiSelect, selectedValue, selectValueClassName, showAddIcon }) => (
  <div className={styles.multiSelectSelectedWrapper}>
    <div className={cx(styles.multiSelect, selectValueClassName)}>
      {
        !isEmpty(selectedValue) && selectedValue.map((item, index) => labelRender(item, index))
      }
      { showAddIcon && <FontIcon size="L">icon-add-circle</FontIcon> }
    </div>
    { allowClear && isMultiSelectBlurEnabled && !disabled && <FontIcon onClick={clearSelectedValueForMultiSelect} className={cx(styles.clearIcon, clearIconClassName)} size="S">icon-close-filled</FontIcon> }
  </div>
);

export default TMMultiSelectValue;