import axios from 'axios';
import HTTPClient from '@tekion/tekion-base/utils/httpClient';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import TokenManager from '@tekion/tap-components/utils/TokenManager';
const contentType = 'application/json; charset=UTF-8';

export const fetchURL = (url, headers = EMPTY_OBJECT) => {
  const accessToken = TokenManager.getItem(TokenManager.accessTokenKey);
  const email = TokenManager.getItem('email');
  const locale = TokenManager.getItem('locale');
  return fetch(
    url,
    Object.assign({}, {
      headers: {
        Accept: contentType,
        'tap-api-token': accessToken,
        email,
        locale,
        ...headers,
      },
    }),
  );
};


/**
 * @description Function to process the response based on the response status
 * If response is ok it process the JSON else rejects the Promise
 * @param {Object} - res - JSON response object.
 * @returns {Object} - response - Resolved promise object.
 */
export const processResponse = (res) => {
  let response = null;
  if (res.ok) {
    response = res.json();
  } else {
    response = Promise.reject(res);
  }
  return response;
};

/**
 * @description Sending a GET request to JSON API.
 * doGet method resolves or rejects the promise that is obtained
 * from the fetchURL method
 * @param {string} url
 * @returns {object}
 */
export const doGet = (url, headers) => fetchURL(url, headers).then(res => processResponse(res));

/**
* @description Sending a POST request to JSON API.
* doPost method resolves or rejects the promise that is obtained
* from the fetchURL method
* @param {string} url
* @param {object} data
* @returns {object}
*/
export const doPost = (url, data) => {
  const accessToken = TokenManager.getItem(TokenManager.accessTokenKey);
  const locale = TokenManager.getItem('locale');
  const header = {
    'Content-Type': contentType,
    'tap-api-token': accessToken,
    locale,
  };
  const dataObj = JSON.stringify(data);
  return fetch(url,
    Object.assign({}, { method: 'POST', headers: header, body: dataObj }))
    .then(res => processResponse(res));
};

export const doPut = (url, data, headers = {}) => {
  const accessToken = TokenManager.getItem(TokenManager.accessTokenKey);
  const locale = TokenManager.getItem('locale');
  const header = {
    'Content-Type': contentType,
    'tap-api-token': accessToken,
    locale,
    ...headers,
  };
  const dataObj = JSON.stringify(data);
  return fetch(url,
    Object.assign({}, { method: 'PUT', headers: header, body: dataObj }))
    .then(res => processResponse(res));
};

export const doDelete = (url, data) => {
  const accessToken = TokenManager.getItem(TokenManager.accessTokenKey);
  const locale = TokenManager.getItem('locale');
  const header = {
    'Content-Type': contentType,
    'tap-api-token': accessToken,
    locale,
  };
  const dataObj = JSON.stringify(data);
  return fetch(url,
    Object.assign({}, { method: 'DELETE', headers: header, body: dataObj }))
    .then(res => processResponse(res));
};

export const doAxiosGet = (baseUrl, timeout) => axios.create({
  baseURL: baseUrl,
  timeout: timeout || '0',
  headers: {
    'Content-Type': 'application/json',
  },
})();


const createHTTP = (baseUrl, headers) => axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    ...headers,
  },
});

const mailIdDetails = { locale: TokenManager.getItem('language') };
const authenticationHeader = () => ({
  locale: TokenManager.getItem('language'),
  'tap-api-token': TokenManager.getItem(TokenManager.accessTokenKey)
});
const headerWithLocale = { locale: TokenManager.getItem('language') };

let instance = {};

const URL_TYPES_VS_TEKION_SERVICES = {
  LOCAL: 'api/',
  TAP: 'api/',
  EXTERNAL: 'api/',
  GET_TAP_WITH_MAIL: 'api/',
};

const URL_TYPES_VS_HEADERS = {
  TAP: authenticationHeader,
  EXTERNAL: () => headerWithLocale,
  GET_TAP_WITH_MAIL: () => mailIdDetails,
  LOCAL: () => headerWithLocale,
};

export const setDefaultModuleServices = () => {
  const local = new Http('LOCAL', { url: 'http://localhost:5000/' }); // eslint-disable-line
  const tap = new Http('TAP', { url: 'http://localhost:5000/' }); // eslint-disable-line
  const external = new Http('EXTERNAL', { url: 'http://localhost:5000/' }); // eslint-disable-line
  const tapWithEmail = new Http('GET_TAP_WITH_MAIL', { url: 'http://localhost:5000/' }); // eslint-disable-line
};

export const removeDefaultModuleServices = () => {
  instance = {};
};

const getHeaders = (moduleType) => {
  const getHeaderFunc = URL_TYPES_VS_HEADERS[moduleType] || URL_TYPES_VS_HEADERS.CDMS;
  return getHeaderFunc();
};

class Http {
  constructor(moduleType, { url, headers } = EMPTY_OBJECT) {
    instance[moduleType] = createHTTP(`${url}${URL_TYPES_VS_TEKION_SERVICES[moduleType]}`, { ...headers });
  }

  static get(moduleType, url, data) {
    return instance[moduleType]({ method: 'GET', headers: { ...getHeaders(moduleType) }, url, params: data }); // eslint-disable-line
  }

  static post(moduleType, url, data, query) {
    return instance[moduleType]({ method: 'POST', headers: { ...getHeaders(moduleType) }, url, data, params: query }); // eslint-disable-line
  }

  static put(moduleType, url, data, query, headers) {
    return instance[moduleType]({ method: 'PUT', headers: { ...getHeaders(moduleType), ...headers }, url, data, params: query }); // eslint-disable-line
  }

  static patch(moduleType, url, data, query) {
    return instance[moduleType]({ method: 'PATCH', headers: { ...getHeaders(moduleType) }, url, data, params: query }); // eslint-disable-line
  }

  static delete(moduleType, url, data, query) {
    return instance[moduleType]({ method: 'DELETE', headers: { ...getHeaders(moduleType) }, url, data, params: query }); // eslint-disable-line
  }
}

const init = () => {
  HTTPClient.getInstance().interceptors.response.use(
    response => response.data
  );
};

init();

export default Http;
