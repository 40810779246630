export const GroupData = {
  formData: [
    {
      id: 'name', label: 'Group Name', required: true, isEditable: true,
    },
    {
      id: 'iconName', label: 'Icon Name', required: true, isEditable: true,
    },
    {
      id: 'groupKey', label: 'Group Key', required: true, isEditable: false,
    },
    {
      id: 'colorCode', label: 'Color Code', required: true, isEditable: true,
    },
  ],
};

export const GROUP_MANAGEMENT_HEADER_PROPS = {
  label: 'GROUP MANAGEMENT',
};

export const GROUP_MANAGEMENT_SUB_HEADER_PROPS = {
};

export const GROUP_MANAGEMENT_MANAGER_PROPS = {
  showMultiSort: true,
  showSearch: true,
  showFilter: true,
  showHeader: true,
  showSubHeader: true,
};

export const GROUP_MANAGEMENT_PAGE_SIZE_OPTIONS = [20, 50, 100, 200];


export const GROUP_MANAGEMENT_DEFAULT_COLUMNS = [
  {
    Header: __('Group Name'), accessor: 'name', key: 'name', sortable: true, width: 250,
  },
  {
    Header: __('Group Key'), accessor: 'groupKey', key: 'groupKey', sortable: true, width: 250,
  },
  {
    Header: __('Icon Name'), accessor: 'iconName', key: 'iconName', sortable: true,
  },
  {
    Header: __('Color Code'), accessor: 'colorCode', key: 'colorCode', sortable: true, width: 250,
  },
];

export const GROUP_MANAGEMENT_COLUMN_CONFIGURATOR = {
  fixed: 'right', resizable: true, width: 50,
};

export const GROUP_MANAGEMENT_ACTION_MENU = [{ key: 'edit', label: __('Edit') }, { key: 'delete', label: __('Delete') }];

export const GROUP_MANAGEMENT_ACTIONS = {
  EDIT: 'edit',
  DELETE: 'delete',
};
export const GROUP_MANAGEMENT_TABLES_STYLES = {
  NO_BORDER: '0',
  BORDER: '2px solid #000',
  CURSOR: 'pointer',
  BACKGROUND: '#e6f3ff',
  WHITE_BACKGROUND: '#fff',
  MISSED: '#da0009',
  NOT_MISSED: '#000',
  HEIGHT: '4rem',
};
