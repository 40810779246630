import { createSelector } from 'reselect';

const listingReducer = state => state.TapApplications;
const authenticationReducer = state => state.Authentication;

const getListing = () => createSelector(
  listingReducer,
  substate => substate?.fetchListing
);

const saveModuleSelector = () => createSelector(
  listingReducer,
  substate => substate.saveModule
);

const fetchModuleSelector = () => createSelector(
  listingReducer,
  substate => substate.fetchModule
);

const updateModuleSelector = () => createSelector(
  listingReducer,
  substate => substate.updateModule
);

const fetchAuthenticationSelector = () => createSelector(
  authenticationReducer,
  substate => substate.notAuthenticated
);

const loading = () => createSelector(
  listingReducer,
  substate => substate?.loading
);

export {
  getListing, saveModuleSelector, fetchModuleSelector, loading, updateModuleSelector, fetchAuthenticationSelector,
};
