export const createFormData = {
  header: [
    { id: 'downtime', label: 'Downtime' },
  ],
  downtime: [
    {
      id: 'type', label: 'Type', value: '', type: 'input', isEditable: false, required: true,
    },
    {
      id: 'status', label: 'Status', value: '', type: 'radio', options: [{ label: 'True', value: true }, { label: 'False', value: false }], isEditable: true, required: true,
    },
    {
      id: 'title', label: 'Title', value: '', type: 'input', isEditable: true, required: true,
    },
    {
      id: 'message', label: 'Message', value: '', type: 'textArea', isEditable: true, required: true,
    },
    {
      id: 'modules', label: 'Modules', value: [], type: 'selectTag', isEditable: true, required: true,
    },
    {
      id: 'users', label: 'Users', value: [], type: 'autoComplete', isEditable: true,
    },
  ],
};

export const BREAD_CRUMBS = {
  CARD_MANAGEMENT: [
    { name: __('TAP Applications'), url: '/app/tapapplications' },
    { name: __('Card Management') },
  ],
  CARD_MANAGEMENT_CREATE: [
    { name: __('TAP Applications'), url: '/app/tapapplications' },
    { name: __('Card Management'), url: '/app/tapapplications/cards' },
    { name: __('Create') },
  ],
  CARD_MANAGEMENT_UPDATE: [
    { name: __('TAP Applications'), url: '/app/tapapplications' },
    { name: __('Card Management'), url: '/app/tapapplications/cards' },
    { name: __('Edit') },
  ],
  TAP_CHAT: [
    { name: __('TAP Applications'), url: '/app/tapapplications' },
    { name: __('TAP Chat') },
  ],
  TAP_CHAT_CREATE: [
    { name: __('TAP Applications'), url: '/app/tapapplications' },
    { name: __('TAP Chat'), url: '/app/tapapplications/tapchat' },
    { name: __('Create') },
  ],
  TAP_CHAT_EDIT: [
    { name: __('TAP Applications'), url: '/app/tapapplications' },
    { name: __('TAP Chat'), url: '/app/tapapplications/tapchat' },
    { name: __('Edit') },
  ],
  RELEASE_VERSION: [
    { name: __('TAP Applications'), url: '/app/tapapplications' },
    { name: __('Release Version') },
  ],
  GROUP_MANAGEMENT: [
    { name: __('TAP Applications'), url: '/app/tapapplications' },
    { name: __('Group Management') },
  ],
  GROUP_MANAGEMENT_CREATE: [
    { name: __('TAP Applications'), url: '/app/tapapplications' },
    { name: __('Group Management'), url: '/app/tapapplications/groups' },
    { name: __('Create') },
  ],
  GROUP_MANAGEMENT_UPDATE: [
    { name: __('TAP Applications'), url: '/app/tapapplications' },
    { name: __('Group Management'), url: '/app/tapapplications/groups' },
    { name: __('Edit') },
  ],
  TAP_CONFIGURATIONS: [
    { name: __('TAP Applications'), url: '/app/tapapplications' },
    { name: __('TAP Configurations') },
  ],
  TAP_CONFIGURATIONS_CREATE: [
    { name: __('TAP Applications'), url: '/app/tapapplications' },
    { name: __('TAP Configurations'), url: '/app/tapapplications/tapconfig' },
    { name: __('Create') },
  ],
  TAP_CONFIGURATIONS_UPDATE: [
    { name: __('TAP Applications'), url: '/app/tapapplications' },
    { name: __('TAP Configurations'), url: '/app/tapapplications/tapconfig' },
    { name: __('Edit') },
  ],
  MOBILE_CONFIGURATIONS: [
    { name: __('TAP Applications'), url: '/app/tapapplications' },
    { name: __('Mobile Configurations') },
  ],
  MOBILE_CONFIGURATIONS_CREATE: [
    { name: __('TAP Applications'), url: '/app/tapapplications' },
    { name: __('Mobile Configurations'), url: '/app/tapapplications/mobileconfigurations' },
    { name: __('Create') },
  ],
  MOBILE_CONFIGURATIONS_EDIT: [
    { name: __('TAP Applications'), url: '/app/tapapplications' },
    { name: __('Mobile Configurations'), url: '/app/tapapplications/mobileconfigurations' },
    { name: __('Edit') },
  ],
};
