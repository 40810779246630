import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import {
  updateReleaseVersion,
} from '../api/ReleaseVersion';
import { CONSTANTS } from '../constants/Constants';
const { ACTIONS } = CONSTANTS;

/**
 * @description Function to update the relaseVersion
 * @param {Object} data - {module: moduleName, s3url: awsurl, version: versionof module, feature: feature name}
 */
export const updateVersion = (baseUrl, data) => (
  (dispatch) => {
    dispatch({ type: ACTIONS.LOADER, payload: true });
    return updateReleaseVersion(baseUrl, data).then((response) => {
      dispatch({ type: ACTIONS.LOADER, payload: false });
      toaster('success', response.msg);
    }).catch((error) => {
      toaster('error', `${error.status} ${__('error occurred while updating release version')}`);
      dispatch({ type: ACTIONS.LOADER, payload: false });
    });
  }
);
