import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';

import SettingsTabs from '../../molecules/SettingsTabs';
import Layout from '../../molecules/Layout/Layout';
import './Settings.scss';

const Settings = (props) => {
  const { children, history: { location: { pathname } }, history } = props;

  const changeTab = activeTab => () => {
    history.push(`/app/settings${activeTab}`);
  };

  const renderSettingsComponent = () => {
    const activeTab = pathname.replace('/app/settings', '') || '/profile';
    return (
      <div>
        <Layout>
          <section>
            <div className="page-container">
              <div className="settings-header">
                <span className="settings-title">{__('Settings')}</span>
                {['/calendar', '/availability'].includes(activeTab) && (
                  <Button href="/calendar/events">{__('Go to Calendar')}</Button>
                )}
              </div>
              <div className="setting-content-container">
                <div className="setting-section">
                  <SettingsTabs activeTab={activeTab} onChangeTab={changeTab} />
                </div>
                <div className="setting-content">
                  {children}
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </div>
    );
  };
  return (
    <React.Fragment>
      {renderSettingsComponent()}
    </React.Fragment>
  );
};

Settings.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Settings);
