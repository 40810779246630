// Third-party library imports.
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Tekion library imports.

// Tap library imports.

// Local repo imports.
import SupportPortal from './SupportPortal';
import { getNotificationSetting, saveNotificationSetting } from '../../../action/SupportPortal.action';
import {
  getIsNotifySettingFetched,
  getIsNotifySettingLoading,
  getNotifySettingHeaders,
  getNotifySettingData,
  getIsNotifySettingSaving,
} from './SupportPortal.selectors';


const mapStateToProps = state => ({
  isNotifySettingFetched: getIsNotifySettingFetched(state),
  isNotifySettingLoading: getIsNotifySettingLoading(state),
  isNotifySettingSaving: getIsNotifySettingSaving(state),
  notifySettingHeaders: getNotifySettingHeaders(state),
  notifySettingData: getNotifySettingData(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getNotificationSetting,
    saveNotificationSetting,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportPortal);
