import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import { CONSTANTS } from '../constants/Constants';
import { fetchUserAvailability, updateUserAvailabilitys } from '../api/Availability';
const { ACTIONS } = CONSTANTS;

export const fetchUserAvailabilitys = baseurl => (dispatch) => {
  dispatch({ type: ACTIONS.LOADER, payload: true });
  fetchUserAvailability(baseurl).then((data) => {
    dispatch({ type: ACTIONS.FETCH_USER_AVAILABILITY, payload: data });
    dispatch({ type: ACTIONS.LOADER, payload: false });
  }).catch(() => {
    dispatch({ type: ACTIONS.FETCH_USER_AVAILABILITY, payload: {} });
    toaster('error', 'Error While Fetching User Availability');
  });
};

export const updateUserCustomAvailabilitys = (baseurl, data, uuid) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.LOADER, payload: true });
  return updateUserAvailabilitys(baseurl, data, uuid).then(() => {
    const currentUserAvailability = getState().userAvailability.userAvailabilityData;
    dispatch({ type: ACTIONS.UPDATE_USER_AVAILABILITY, payload: { ...currentUserAvailability, rule: data.rule } });
    toaster('success', 'Availability Updated Sucessfully');
    dispatch({ type: ACTIONS.LOADER, payload: false });
    dispatch(fetchUserAvailabilitys(baseurl));
    return true;
  }).catch(() => {
    toaster('error', 'Error While updating User Availability');
    return false;
  });
};
