import { getTapConfig, searchUsers } from './urls';
import Http, { doGet, doPost } from '../services/http';

export const fetchTapConfigAPI = url => doGet(`${url}${getTapConfig}`);
export const updateTapConfigAPI = (url, data) => doPost(`${url}${getTapConfig}`, data);

export const searchUsersAPI = (url, query) => {
  const requestUrl = `${url}${searchUsers}${query}`;
  return Http.get('TAP', requestUrl);
};
