
import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import { getEmailId, getUserName } from '@tekion/tap-components/utils/helper';
import moment from 'moment';
import { fetchUserGetAvailability, saveUserGetAvailability } from '../api/Calendar';
import { CONSTANTS } from '../constants/Constants';
import { getUserAvailable, storeUserAvailable } from '../api/urls';
const { ACTIONS } = CONSTANTS;

export const getUserInformation = baseUrl => (
  (dispatch) => {
    dispatch({ type: ACTIONS.LOADER, payload: true });
    const url = `${baseUrl}${getUserAvailable}`;
    return fetchUserGetAvailability(url).then((response) => {
      dispatch({ type: ACTIONS.LOADER, payload: false });
      dispatch({ type: ACTIONS.STORE_USER_AVAILABILITY_DATA, payload: response });
      return response;
    }).catch(async (error) => {
      const errorInfo = await error.json();
      if (errorInfo.ErrorMsg === 'Error while getting user details from DB') {
        dispatch(createUserIfNotExists(baseUrl));
      } else {
        dispatch({ type: ACTIONS.LOADER, payload: false });
        dispatch({ type: ACTIONS.STORE_USER_AVAILABILITY_DATA, payload: {} });
      }
      return null;
    });
  }
);

const createUserIfNotExists = baseUrl => (dispatch) => {
  const userName = getUserName();
  if (!userName) {
    setTimeout(() => {
      dispatch(createUserIfNotExists(baseUrl));
    }, 1000);
    return;
  }
  const email = getEmailId();
  const payload = {
    email,
    time_zone: moment.tz.guess(),
    time_format: '24 Hours',
    date_format: 'MM/DD/YYYY',
    has_outlook: true,
    has_zoom: false,
    name: userName,
    slug: email.replace('@tekion.com', ''),
    status: true,
  };
  dispatch(saveUserInformation(baseUrl, payload, false));
};

export const saveUserInformation = (baseUrl, payload, showToaster = true) => ((dispatch) => {
  const url = `${baseUrl}${storeUserAvailable}`;
  dispatch({ type: ACTIONS.LOADER, payload: true });
  return saveUserGetAvailability(url, payload).then(() => {
    dispatch({ type: ACTIONS.LOADER, payload: false });
    dispatch({ type: ACTIONS.STORE_USER_AVAILABILITY_DATA, payload });
    if (showToaster) {
      toaster('success', __('Updated User Information Sucessfully'));
    }
    return true;
  }).catch(() => {
    dispatch({ type: ACTIONS.LOADER, payload: false });
    toaster('error', __('Failed to update user availability details'));
    return false;
  });
});
