import { CONSTANTS } from '../constants/Constants';

const { ACTIONS } = CONSTANTS;

const initialState = {
  userAvailabilityData: {},
};

export default (prevState, action) => {
  const state = prevState || initialState;

  switch (action.type) {
    case ACTIONS.FETCH_USER_AVAILABILITY:
      return {
        ...state,
        userAvailabilityData: action.payload,
      };

    case ACTIONS.UPDATE_USER_AVAILABILITY:
      return {
        ...state,
        userAvailabilityData: action.payload,
      };
    default: return state;
  }
};
