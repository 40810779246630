import React, {
  useState, useMemo, useEffect,
} from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NO_OP } from '@tekion/tap-components/constants/Constants';
import SelectAsLookup from '@tekion/tap-support-components/organisms/FieldLibrary/SelectAsLookup';
import styles from './TapConfigurations.module.scss';


function AutoCompleteHelper(props) {
  const {
    defaultValue, getChildData, placeholder, searchKeyApi, loading, getSearchUsers, searchUsers,
  } = props;
  const [value, setValue] = useState(defaultValue);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (searchUsers.length > 1) {
      const filteredUsers = searchUsers.map((data, index) => ({
        key: index,
        label: data.name || `${data.firstName}${data.lastName}`,
        value: data.email || data.value,
      }));
      setOptions(filteredUsers);
    } else {
      setOptions([]);
    }
  }, [searchUsers]);

  useEffect(() => {
    searchKeyApi('');
  }, []);

  useEffect(() => {
    getChildData(value);
  }, [value]);

  const debounceFetcher = useMemo(() => {
    searchKeyApi();
    return debounce(searchKeyApi, 500);
  }, [getSearchUsers]);

  const handleSelectedValue = (selectedData) => {
    setValue(selectedData);
  };

  return (
    <div className={styles.tags}>
      <SelectAsLookup
        loading={loading.autoComplete}
        values={value}
        isMultiSelect
        className={styles.selectContainer}
        placeholder={placeholder}
        onSelect={d => handleSelectedValue(d.payload.value)}
        onSearch={d => debounceFetcher(d.payload.searchKey)}
        allowClear
        showSearch
        showArrow
        options={options}
      />
    </div>
  );
}

AutoCompleteHelper.propTypes = {
  defaultValue: PropTypes.array,
  searchKeyApi: PropTypes.func.isRequired,
  loading: PropTypes.object,
  getSearchUsers: PropTypes.func,
  getChildData: PropTypes.func,
  placeholder: PropTypes.string,
  searchUsers: PropTypes.array,
};

AutoCompleteHelper.defaultProps = {
  defaultValue: [],
  getChildData: NO_OP,
  getSearchUsers: NO_OP,
  placeholder: '',
  loading: {},
  searchUsers: [],
};

export default withRouter(
  connect(
  )(AutoCompleteHelper)
);
