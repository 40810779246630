import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { fetchMobileConfigListApi, createOrUpdateMobileConfigApi, getMobileConfigApi } from '../api/MobileConfigurations';
import { CONSTANTS } from '../constants/Constants';
const { ACTIONS } = CONSTANTS;

export const fetchMobileConfigList = () => (dispatch) => {
  dispatch({ type: ACTIONS.SET_MOBILE_CONFIG_LOADING, field: 'fetchMobileConfigs', payload: true });
  return fetchMobileConfigListApi().then(({ data }) => {
    dispatch({ type: ACTIONS.SET_MOBILE_CONFIG_LOADING, field: 'fetchMobileConfigs', payload: false });
    dispatch({ type: ACTIONS.SET_MOBILE_CONFIG, payload: data });
  }).catch(() => {
    dispatch({ type: ACTIONS.SET_MOBILE_CONFIG_LOADING, field: 'fetchMobileConfigs', payload: false });
    dispatch({ type: ACTIONS.SET_MOBILE_CONFIG, payload: EMPTY_ARRAY });
  });
};

export const createOrUpdateMobileConfig = (platform, data) => (dispatch) => {
  dispatch({ type: ACTIONS.SET_MOBILE_CONFIG_LOADING, field: 'createOrUpdate', payload: true });
  return createOrUpdateMobileConfigApi(platform, data).then(() => {
    dispatch({ type: ACTIONS.SET_MOBILE_CONFIG_LOADING, field: 'createOrUpdate', payload: false });
    dispatch({ type: ACTIONS.SET_ACTIVE_MOBILE_CONFIG, payload: null });
    toaster('success', __('Module added successfully'));
    return true;
  }).catch((error) => {
    toaster('error', `${error.status} ${__('Error occured while adding module')} `);
    dispatch({ type: ACTIONS.SET_MOBILE_CONFIG_LOADING, field: 'createOrUpdate', payload: false });
    return false;
  });
};

export const getMobileConfig = platform => (dispatch) => {
  dispatch({ type: ACTIONS.SET_MOBILE_CONFIG_LOADING, field: 'fetchActiveMobileConfig', payload: true });
  return getMobileConfigApi(platform).then(({ data }) => {
    dispatch({ type: ACTIONS.SET_MOBILE_CONFIG_LOADING, field: 'fetchActiveMobileConfig', payload: false });
    dispatch({ type: ACTIONS.SET_ACTIVE_MOBILE_CONFIG, payload: { platform, ...data } });
  }).catch(() => {
    dispatch({ type: ACTIONS.SET_MOBILE_CONFIG_LOADING, field: 'fetchActiveMobileConfig', payload: false });
    dispatch({ type: ACTIONS.SET_ACTIVE_MOBILE_CONFIG, payload: null });
  });
};
