import React from 'react';
import PropTypes from 'prop-types';
import ErrorPage from '@tekion/tap-components/atoms/ErrorPage';
import TokenManager from '@tekion/tap-components/utils/TokenManager';

const SignUp = (props) => {
  const accessToken = TokenManager.getItem(TokenManager.accessTokenKey);
  if (accessToken) {
    props.history.push('/');
  }
  return (
    <ErrorPage
      message={(
        <span>
          {__('User account cannot be created from this site. Kindly contact')}
          <a
            href="mailto:helpdesk@tekion.com"
            style={{ textDecoration: 'none' }}
          >
            {__('IT Helpdesk')}
          </a>
          {__('for Okta account creation to sign in.')} <br /> {__('If you already have an account')}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              props.history.push('/login');
            }}
            style={{ textDecoration: 'none' }}
          >
            {__('click here to Login.')}
          </a>
        </span>
      )}
    />
  );
};

SignUp.propTypes = {
  history: PropTypes.string.isRequired,
  push: PropTypes.object.isRequired,
};

export default SignUp;
