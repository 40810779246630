import { CONSTANTS } from '../constants/Constants';

const { ACTIONS } = CONSTANTS;
const initialState = {
  userData: {},
};

/* - Reducer for User Details.
 * @params
 *  - prevState - object
 *  - action - object
 * @return
 *  - state - object
 */
export default (prevState, action) => {
  const state = prevState || initialState;
  switch (action.type) {
    case ACTIONS.STORE_USER_AVAILABILITY_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case ACTIONS.LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    default: return state;
  }
};
