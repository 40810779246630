import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Avatar from 'tcomponents/atoms/Avatar';
import Tooltip from 'tcomponents/atoms/tooltip';
import { isEmpty } from '@tekion/tap-components/utils/helper';
import { formatUserName, getBackgroundColorCode } from '../../utils/helper';
import styles from './TMAvatar.module.scss';
import { EMPTY_STRING } from '@tekion/tap-components/constants/Constants';

const DEFAULT_AVATAR_SIZE = 24;

const TMAvatar = ({
  user, size, displayName, className, enableTooltip, formattedName, key, displayEmail,
}) => {
  const name = formattedName || formatUserName(user);
  const colorCode = isEmpty(formattedName) ? getBackgroundColorCode(name) : 'inherit';
  const src = user?.avatar || user?.image || user?.src || '';
  const email = user?.email || '';
  const avatarIcon = (
    <Avatar
      key={key}
      containerClassName={cx('tm-avatar', className, styles.avatarContainer)}
      contentClassName={styles.userNameContent}
      style={{ backgroundColor: colorCode }}
      displayName={displayName}
      secondaryName={displayEmail ? email : null}
      src={src}
      name={name}
      size={size}
    />
  );

  return (
    <>
      { enableTooltip
        ? name && (
          <Tooltip title={email ? `${name}  <${email}>` : name} destroyTooltipOnHide>
            {avatarIcon}
          </Tooltip>
        )
        : name && avatarIcon
      }
    </>
  );
};

TMAvatar.propTypes = {
  user: PropTypes.object.isRequired,
  displayName: PropTypes.bool,
  displayEmail: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.array,
  enableTooltip: PropTypes.bool,
  formattedName: PropTypes.string,
};

TMAvatar.defaultProps = {
  displayName: true,
  displayEmail: false,
  className: EMPTY_STRING,
  size: DEFAULT_AVATAR_SIZE,
  enableTooltip: false,
  formattedName: EMPTY_STRING,
};

export default TMAvatar;
