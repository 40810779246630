import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Button from 'tcomponents/atoms/Button';
import Card from 'tcomponents/molecules/Card';
import styles from './MobileConfigurations.module.scss';
import PageHeader from '../PageHeader/PageHeader';
import withSize from '../../../organisms/withSize';
import { BREAD_CRUMBS } from '../TapConfigurations/Constants';

class MobileConfigurations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.fetchMobileConfigList();
  }

  onSelectModule = selectedModule => () => {
    const { history } = this.props;
    history.push({
      pathname: `/app/tapapplications/mobileconfigurations/edit/${selectedModule.platform}`,
      state: {
        module: selectedModule.platform,
      },
    });
  };

  addModule = () => {
    const { history } = this.props;
    history.push('/app/tapapplications/mobileconfigurations/create');
  };

  renderHeaderActionItems = () => (
    <Button
      view="primary"
      className={styles.addBtn}
      onClick={this.addModule}
    >
      {__('+ Add New')}
    </Button>
  );

  render() {
    const { mobileConfigs } = this.props;
    return (
      <div className={styles.container}>
        <PageHeader
          breadCrumbs={BREAD_CRUMBS.MOBILE_CONFIGURATIONS}
          actionItems={this.renderHeaderActionItems()}
        />
        <div className={styles.list}>
          {mobileConfigs.map(item => (
            <Card
              size="small"
              title=""
              className={styles.configItem}
              key={item.platform}
              onClick={this.onSelectModule(item)}
            >
              <h3 className={styles.platform}>{item.platform}</h3>
              <div>{item.version}</div>
            </Card>
          ))}
        </div>
      </div>
    );
  }
}

MobileConfigurations.propTypes = {
  mobileConfigs: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default withRouter(withSize(MobileConfigurations));
