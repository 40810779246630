/* eslint-disable react/prop-types */
import React from 'react';

import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Select } from 'antd';
import Input from 'tcomponents/atoms/Input';
import Button from 'tcomponents/atoms/Button';
import Loader from 'tcomponents/molecules/loader';
import { deepClone, renderIfElse } from '@tekion/tap-components/utils/helper';
import { addCard, updateCard, activeCard } from '../../../action/CardManagement.action';
import { addNewCard, getCard, loading } from './CardManagement.selector';
import { getGroups, groupTypes } from '../../../action/GroupManagement.action';
import { getAllGroups, getGroupTypes } from '../GroupManagement/GroupManagement.selector';
import styles from '../TapApplications.module.scss';
import PageHeader from '../PageHeader/PageHeader';
import { BREAD_CRUMBS } from '../TapConfigurations/Constants';

class CreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: [
        {
          id: 'appKey', label: 'App Key', value: '', isEditable: false, placeholder: 'App Key value must be New and Unique', required: true, defaultValue: '',
        },
        {
          id: 'iconName', label: 'Icon Name', value: '', isEditable: true, required: true, defaultValue: '',
        },
        {
          id: 'label', label: 'Label', value: '', isEditable: true, required: true, defaultValue: '',
        },
        {
          id: 'path', label: 'Path', value: '', isEditable: true, required: true, defaultValue: '',
        },
        {
          id: 'childPages', label: 'Child Pages', value: '', isEditable: true, required: false, defaultValue: [],
        },
        {
          id: 'slabel', label: 'Short Label', value: '', isEditable: true, required: true, defaultValue: '',
        },
        {
          id: 'value', label: 'Value', value: '', isEditable: false, required: true, defaultValue: '',
        },
        {
          id: 'roleKey', label: 'Role Key', value: '', isEditable: true, defaultValue: '',
        },
        {
          id: 'groupKey', label: 'Select Group', value: '', isEditable: true, required: false, defaultValue: '',
        },
        {
          id: 'groupType', label: 'Group Type', value: '', isEditable: true, required: false, defaultValue: '',
        },
      ],
      editModule: false,
      loaded: false,
      disableDelete: true,
    };
  }

  componentDidMount() {
    const { history: { location: { pathname } } } = this.props;
    const {
      getIndividualCard, apiBaseUrl, getAllGroups, getGroupTypes,
    } = this.props;
    if (pathname.endsWith('edit') && pathname.includes('cards')) {
      const { history: { location: { state: { cardData: { appKey } } } } } = this.props;
      getIndividualCard(apiBaseUrl, appKey);
      this.setState({ editModule: true });
    }
    if (pathname.endsWith('create')) {
      this.setState({ loaded: true });
    }
    getAllGroups(apiBaseUrl);
    getGroupTypes(apiBaseUrl);
  }

  componentDidUpdate() {
    const { history, loading } = this.props;
    if (loading.addCard || loading.updateCard) {
      history.push('/app/tapapplications/cards');
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { getCardResponse, location } = props;
    const { formData } = state;
    if (getCardResponse.data) {
      const {
        appKey,
      } = getCardResponse.data;
      if (location?.state?.cardData?.appKey === appKey && appKey !== state.formData[0].id && !state.loaded) {
        const newData = formData.map(data => (
          {
            ...data,
            value: getCardResponse.data[data.id],
          }
        ));
        return {
          formData: newData,
          editModule: true,
          loaded: true,
        };
      }
    }
    return null;
  }

  handleSave = () => {
    const { formData, editModule } = this.state;
    const payload = formData.reduce((obj, item) => ({
      ...obj,
      [item.id]: item.value ? item.value : item.defaultValue,
    }), {});
    const {
      apiBaseUrl, addNewCard, history, updateOldCard,
    } = this.props;
    if (editModule) {
      updateOldCard(apiBaseUrl, payload);
      history.push('/app/tapapplications/cards');
      this.setState({ loaded: false });
    } else {
      addNewCard(apiBaseUrl, payload);
    }
  }

  formValidate = (formData) => {
    const invalid = formData.some(data => data.required ? !data.value : false);
    return invalid;
  }

  onChange = (target, index, type) => {
    const { formData } = this.state;
    const newData = deepClone(formData);
    newData[index].value = (type === 'select') ? target : target.value;
    this.setState({ formData: newData, disableDelete: this.formValidate(newData) });
  }

  onCancel = () => {
    const {
      history,
    } = this.props;
    history.push('/app/tapapplications/cards');
  }

  renderHeader = () => {
    const { editModule } = this.state;
    return (
      <PageHeader
        breadCrumbs={editModule ? BREAD_CRUMBS.CARD_MANAGEMENT_UPDATE : BREAD_CRUMBS.CARD_MANAGEMENT_CREATE}
      />
    );
  }

  render() {
    const {
      formData, loaded, editModule, disableDelete,
    } = this.state;
    const { loading, allGroups, groupTypes } = this.props;
    const { Option } = Select;
    return (
      <React.Fragment>
        {!loaded ? <Loader />
          : (
            <div className={styles.version__control}>
              {this.renderHeader()}
              <div className={styles.create__module}>
                <Row>
                  <Col xs={24} md={24} sm={24} lg={11}>
                    {formData.map((data, index) => (
                      <Row className={styles.mb24} align="middle" gutter={16} key={data.b}>
                        <Col xs={24} md={10} sm={24} lg={7}>
                          {data.label}{data.required ? ' *' : ''}
                        </Col>
                        <Col span={12}>
                          {
                            renderIfElse(data.id === 'groupKey', () => (
                              <Select
                                showSearch
                                className={styles.select}
                                defaultValue={data.value}
                                placeholder={__('Please select a group name')}
                                onChange={value => this.onChange(value, index, 'select')}
                                allowClear
                              >
                                {allGroups.map(data => <Option value={data.groupKey}>{data.name}</Option>)}
                              </Select>
                            ),
                            () => renderIfElse(data.id === 'groupType', () => (
                              <Select
                                showSearch
                                className={styles.select}
                                defaultValue={data.value}
                                placeholder={__('Please select a group type')}
                                onChange={value => this.onChange(value, index, 'select')}
                                allowClear
                              >
                                {groupTypes.map(data => <Option value={data.key}>{data.name}</Option>)}
                                <Option key="empty group">{__('No group')}</Option>
                              </Select>
                            ),
                            () => renderIfElse(data.id === 'childPages', () => (
                              <Select
                                mode="tags"
                                className={styles.childPages}
                                placeholder="Tags Mode"
                                onChange={value => this.onChange(value, index, 'select')}
                                value={data.value}
                              />
                            ),
                            () => (
                              <Input
                                id={data.value}
                                value={data.value}
                                name={data.value}
                                disabled={editModule && !data.isEditable}
                                placeholder={data.placeholder || `Please enter ${data.label}`}
                                onChange={e => this.onChange(e.target, index)}
                              />
                            ))))}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </div>
              <div className={styles.module__footer}>
                <Button
                  style={{ marginRight: '25px' }}
                  onClick={this.onCancel}
                >
                  {__('Cancel')}
                </Button>
                <Button
                  view="primary"
                  onClick={this.handleSave}
                  disabled={disableDelete}
                  loading={loading.addCard}
                >
                  {__('Save')}
                </Button>
              </div>
            </div>
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  getCardResponse: getCard(),
  loading: loading(),
  addCard: addNewCard(),
  allGroups: getAllGroups(),
  groupTypes: getGroupTypes(),
});

const mapDispatchToProps = (dispatch) => {
  const getIndividualCard = bindActionCreators(activeCard, dispatch);
  const addNewCard = bindActionCreators(addCard, dispatch);
  const updateOldCard = bindActionCreators(updateCard, dispatch);
  const getAllGroups = bindActionCreators(getGroups, dispatch);
  const getGroupTypes = bindActionCreators(groupTypes, dispatch);
  return {
    getIndividualCard,
    addNewCard,
    updateOldCard,
    getAllGroups,
    getGroupTypes,
  };
};
CreateForm.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  getIndividualCard: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,

};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateForm)
);
