import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from 'tcomponents/atoms/Button';
import { deepClone, isEmpty } from '@tekion/tap-components/utils/helper';
import { NO_OP } from '@tekion/tap-components/constants/Constants';
import KebabMenu from 'tcomponents/molecules/KebabMenu/KebabMenu';
import Menu from 'tcomponents/molecules/Menu';
import Modal from 'tcomponents/molecules/Modal';
import FixedColumnTable from 'tcomponents/molecules/table/FixedColumnTable';
import IconAsBtn from 'tcomponents/atoms/iconAsBtn/IconAsBtn';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Input from 'tcomponents/atoms/Input';
import withPagination from '../../../organisms/withPagination/withPagination';
import withSize from '../../../organisms/withSize';
import PageHeader from '../PageHeader/PageHeader';
import { BREAD_CRUMBS } from '../TapConfigurations/Constants';

import {
  getCards, activeCard, deleteCard,
} from '../../../action/CardManagement.action';
import {
  getAllCards, loading, addNewCard, getWorkSpaceSize,
} from './CardManagement.selector';
import {
  CARD_MANAGEMENT_DEFAULT_COLUMNS, CARD_MANAGEMENT_COLUMN_CONFIGURATOR,
  CARD_MANAGEMENT_ACTION_MENU, CARD_MANAGEMENT_ACTIONS, CARD_MANAGEMENT_TABLES_STYLES,
  CARD_MANAGEMENT_TABLE_PAGE,
} from '../../../constants/Constants';
import styles from '../TapApplications.module.scss';

const { Item } = Menu;
const tableRowStyle = { cursor: CARD_MANAGEMENT_TABLES_STYLES.CURSOR, height: CARD_MANAGEMENT_TABLES_STYLES.HEIGHT };
const TableWithPagination = withPagination(FixedColumnTable);
const getCardManagementTablePage = () => deepClone(CARD_MANAGEMENT_TABLE_PAGE);
class CardManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      currentCard: '',
      tableColumns: [...CARD_MANAGEMENT_DEFAULT_COLUMNS, this.getColumnConfigurator()],
      page: getCardManagementTablePage(),
      filteredCards: [],
    };
  }

  componentDidMount() {
    const {
      getAllCards, apiBaseUrl, allCards,
    } = this.props;
    if (isEmpty(allCards)) { getAllCards(apiBaseUrl)} else this.setState({ filteredCards: allCards}); 
  }

  componentDidUpdate(prevProps) {
    const { allCards } = this.props;
    if (prevProps.allCards !== allCards) {
      this.setInitialCardData(allCards);
    }
  }

  setInitialCardData = (allCards) => {
    this.setState({ filteredCards: allCards });
  }

  addModule = () => {
    const { history } = this.props;
    history.push('/app/tapapplications/cards/create');
  };

  onCardEdit = (cardData) => {
    const { history } = this.props;
    history.push({
      pathname: `/app/tapapplications/cards/${cardData.appKey}/edit`,
      state: { cardData },
    });
  };

  onCardDelete = (cardData) => {
    this.setState(
      { showDeleteModal: true, currentCard: cardData }
    );
  };

  onModalCancel = () => {
    this.setState({
      showDeleteModal: false,
    });
  }

  onModalSubmit = () => {
    const { currentCard } = this.state;
    const { deleteOldCard, apiBaseUrl } = this.props;
    this.setState({
      showDeleteModal: false,
    });
    deleteOldCard(apiBaseUrl, currentCard);
  }

   renderDeleteModal = () => {
     const { showDeleteModal, currentCard } = this.state;
     const { appKey } = currentCard;
     return (
       <Modal
         visible={showDeleteModal}
         title={__('Confirm delete')}
         centered
         onCancel={this.onModalCancel}
         onSubmit={this.onModalSubmit}
         destroyOnClose
         submitBtnText={__('Delete')}
       >
         {__('Do you want to permanently delete {{appKey}}?', { appKey })}
       </Modal>
     );
   }

   renderDropDownMenu = Data => (
     <Menu className="commentActions__menu">
       <Item>
         <a onClick={() => this.onCardEdit(Data)} tabIndex="0" role="button">
         {__('Edit')}
         </a>
       </Item>
       <Item>
         <a onClick={() => this.onCardDelete(Data)} tabIndex="0" role="button">
         {__('Delete')}
         </a>
       </Item>
     </Menu>
   );

   onKebabItemClick = (key, original) => {
     if (key === CARD_MANAGEMENT_ACTIONS.EDIT) {
       this.onCardEdit(original);
     } else if (key === CARD_MANAGEMENT_ACTIONS.DELETE) {
       this.onCardDelete(original);
     }
   };

   getRowActionCell = ({ original }) => (
     <KebabMenu
       onClickAction={key => this.onKebabItemClick(key, original)}
       menuItems={CARD_MANAGEMENT_ACTION_MENU}
       triggerElement={<span className="icon-overflow" />}
       rowInfo={original}
     />
   );

   getColumnConfigurator = () => ({
     ...CARD_MANAGEMENT_COLUMN_CONFIGURATOR,
     Cell: this.getRowActionCell,
     Header: <IconAsBtn>icon-settings</IconAsBtn>,
     id: 'settings',
   });

   onPageChange = (currentPage, pageSize) => {
     const { page } = this.state;
     this.setState({ page: { ...page, currentPage, pageSize } });
   };

   onSearch = (e) => {
     const { allCards } = this.props;
     const newData = [];
     allCards.forEach((obj) => {
       if (obj.label.toLowerCase().includes(e.target.value.toLowerCase())) { newData.push(obj); }
     });
     this.setState({ filteredCards: newData });
   }

   renderHeaderActionItems = () => (
    <Button
      view="primary"
      className={styles.add__btn}
      onClick={this.addModule}
    >
      {__('+ Add New')}
    </Button>
  )

  renderHeader = () => {
    return (
      <PageHeader 
        breadCrumbs={BREAD_CRUMBS.CARD_MANAGEMENT}
        actionItems={this.renderHeaderActionItems()}
      />
    )
  }

   renderPage = () => {
     const {
       loading, allCards, workSpaceSize,
     } = this.props;
     const { tableColumns, page, filteredCards } = this.state;
     const { getCards, deleteCard, updateCard } = loading;
     const tableHeight = workSpaceSize.contentHeight - 50
     return (
       <div
         className={styles.version__control}
       >
         {this.renderHeader()}
         <div className={styles.table_container}>
           <div className={styles.searchContainer}>
             <Input
               className={styles.inputSearch}
               placeholder={__('Search Cards here')}
               onChange={this.onSearch}
             />
             <span className={styles.fields__rowCount}>{filteredCards.length}{__(' Cards')}</span>
           </div>
           <TableWithPagination
             data={filteredCards}
             fetchData={this.onPageChange}
             columns={tableColumns}
             isMultiSort={false}
             multiSort={false}
             showPagination
             minRows={0}
             sortable={false}
             onRowClick={NO_OP}
             initPagination={page}
             rowHeight={40}
             rowStyle={tableRowStyle}
             totalDatasCount={allCards.length}
             style={{ height: tableHeight, overflow: 'hidden', fontSize: '1.2rem' }}
           />
         </div>
       </div>
     );
   };

   render() {
     return (
       <React.Fragment>
         {this.renderPage()}
         {this.renderDeleteModal()}
       </React.Fragment>
     );
   }
}

const mapStateToProps = createStructuredSelector({
  allCards: getAllCards(),
  loading: loading(),
  workSpaceSize: getWorkSpaceSize(),
});

const mapDispatchToProps = (dispatch) => {
  const getAllCards = bindActionCreators(getCards, dispatch);
  const getCard = bindActionCreators(activeCard, dispatch);
  const deleteOldCard = bindActionCreators(deleteCard, dispatch);
  return {
    getAllCards,
    getCard,
    deleteOldCard,
    addNewCard,
  };
};

CardManagement.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  allCards: PropTypes.array,
  loading: PropTypes.object,
  workSpaceSize: PropTypes.object,
  deleteOldCard: PropTypes.func,
  getAllCards: PropTypes.func,
};

CardManagement.defaultProps = {
  allCards: EMPTY_ARRAY,
  loading: EMPTY_OBJECT,
  workSpaceSize: EMPTY_OBJECT,
  deleteOldCard: NO_OP,
  getAllCards: NO_OP,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSize(CardManagement))
);
