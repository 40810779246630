/* eslint-disable i18next/no-literal-string */
import React from 'react';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants'; //eslint-disable-line
import { NO_OP } from '@tekion/tap-components/constants/Constants';
import ExpandableSearch from 'tcomponents/organisms/ExpandableSearch';
import FixedColumnTable from 'tcomponents/molecules/table/FixedColumnTable';
import Button from 'tcomponents/atoms/Button';
import IconAsBtn from 'tcomponents/atoms/iconAsBtn/IconAsBtn';
import KebabMenu from 'tcomponents/molecules/KebabMenu/KebabMenu';

import Layout from '../../molecules/Layout/Layout';
import withPagination from '../../organisms/withPagination/withPagination';
import { USER_MANAGEMENT_DEFAULT_COLUMNS, USER_MANAGEMENT_TABLES_STYLES, getUsersTablePage, USER_MANAGEMENT_ACTIONS, USER_MANAGEMENT_ACTION_MENU, USER_MANAGEMENT_KEBAB_STYLES } from '../../constants/Constants';
import AddUserModal from './AddUserModal';

import styles from './UserManagement.module.scss';

const tableRowStyle = { cursor: USER_MANAGEMENT_TABLES_STYLES.CURSOR, height: USER_MANAGEMENT_TABLES_STYLES.HEIGHT };
const TableWithPagination = withPagination(FixedColumnTable);

class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumns: [...USER_MANAGEMENT_DEFAULT_COLUMNS],
      page: getUsersTablePage(),
      searchText: '',
      showModal: false,
    };
  }

  componentDidMount() {
    const { apiBaseUrl, actions } = this.props;
    actions.fetchAllUsers(apiBaseUrl);
  }

  componentDidUpdate(prevProps) {
    const { apiBaseUrl, actions, isUserFetching } = this.props;
    const { isUserFetching: prevIsUserFetching } = prevProps;
    if (isUserFetching && prevIsUserFetching !== isUserFetching) {
      actions.fetchAllUsers(apiBaseUrl);
    }
  }

  onSearch = (value) => {
    const { apiBaseUrl, actions } = this.props;
    const { page } = this.state;
    if (value) {
      actions.searchUsers(apiBaseUrl, value);
    }
    this.getAllData(1, page.pageSize);
    this.setState({ searchText: value });
  };

  getAllData = (pageInfo, pages) => {
    const { page } = this.state;
    this.setState({
      page: {
        ...page,
        currentPage: pageInfo,
        pageSize: pages,
      },
    });
  };

  getColumnConfigurator = () => ({ 
    ...USER_MANAGEMENT_KEBAB_STYLES,
    Cell: this.getRowActionCell,
    Header: <IconAsBtn style={{ cursor: 'not-allowed' }}>icon-settings</IconAsBtn>,
    id: 'settings',
    sortable: false,
  });

  getRowActionCell = ({ original }) => {
    const menuItemsList = [...USER_MANAGEMENT_ACTION_MENU];
    return (
      <KebabMenu
        menuItems={menuItemsList}
        rowInfo={original}
        triggerElement={<span className='icon-overflow' />}
        onClickAction={key => this.onKebabItemClick(key, original)}
      />    
    );
  };

  onKebabItemClick = (key, original) => {
    if (key === USER_MANAGEMENT_ACTIONS.DELETE) {
      const { apiBaseUrl, actions } = this.props;
      actions.deleteUser(apiBaseUrl, {'email': original.email});
    }
    return null;
  };

  setShowModal = (value) => {
    this.setState({ showModal: value });
  }

  renderAddUserModal = () => {
    this.setShowModal(true);
  }

  render() {
    const {
      tableColumns, page, searchText, showModal
    } = this.state;
    const {
      workSpaceSize, isTableLoading, allUsers, filteredUsers, apiBaseUrl, actions
    } = this.props;
    const { onSearch, renderAddUserModal, setShowModal } = this;
    const userDatas = searchText ? filteredUsers : allUsers;

    return (
      <React.Fragment>
        <Layout>
          <section>
            <div className={styles.tableContainer}>
              <div className={styles.header}>
                <h2 className={styles.title}>{__('User Management')}</h2>
                <div className={styles.tableTool}>
                  <ExpandableSearch
                    isActive
                    className={styles.customSearch}
                    isClearable={false}
                    placeholder='Search Username/Email'
                    value={searchText}
                    onChange={onSearch}
                  />
                  <Button
                    view='primary'
                    className={styles.add__btn}
                    onClick={renderAddUserModal}
                  >
                    {__('+ Add User')}
                  </Button>
                  <AddUserModal apiBaseUrl={apiBaseUrl} actions={actions} showModal={showModal} setShowModal={setShowModal} />
                </div>
              </div>
              <TableWithPagination
                data={userDatas}
                columns={[...tableColumns, this.getColumnConfigurator()]}
                fetchData={this.getAllData}
                isMultiSort={false}
                multiSort={false}
                showPagination
                minRows={0}
                sortable={false}
                onRowClick={NO_OP}
                initPagination={page}
                rowHeight={40}
                rowStyle={tableRowStyle}
                totalDatasCount={userDatas.length}
                loading={isTableLoading}
                style={{ height: workSpaceSize.contentHeight, overflow: 'hidden', fontSize: '1.2rem' }}
              />
            </div>
          </section>
        </Layout>
      </React.Fragment>
    );
  }
}

UserManagement.propTypes = {
  actions: PropTypes.object.isRequired,
  allUsers: PropTypes.array,
  filteredUsers: PropTypes.array,
  isTableLoading: PropTypes.bool.isRequired,
  apiBaseUrl: PropTypes.string.isRequired,
  workSpaceSize: PropTypes.object,
};

UserManagement.defaultProps = {
  allUsers: EMPTY_ARRAY,
  filteredUsers: EMPTY_ARRAY,
  workSpaceSize: EMPTY_OBJECT,
};

export default UserManagement;
