exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles_buildInfo__2Nsts{max-width:25rem;min-width:25rem;display:flex;flex-direction:column;gap:1rem}[dir] .styles_buildInfo__2Nsts{background-color:#fff;margin:1rem 4rem}.styles_buildInfoHeader__1WcyQ{font-size:1.8rem;letter-spacing:.03rem;color:#161616;display:flex;gap:.5rem;align-items:center}.styles_buildInfoLabel__3YTUa{font-weight:bold;color:#007bff}.styles_buildInfoValue__l7U78{font-weight:normal;color:#333}", ""]);

// Exports
exports.locals = {
	"buildInfo": "styles_buildInfo__2Nsts",
	"buildInfoHeader": "styles_buildInfoHeader__1WcyQ",
	"buildInfoLabel": "styles_buildInfoLabel__3YTUa",
	"buildInfoValue": "styles_buildInfoValue__l7U78"
};