import JwtDecode from 'jwt-decode';
import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import { fetchAuthenticationDetail } from '../api/authentication';
import { CONSTANTS } from '../constants/Constants';
import TokenManager from '@tekion/tap-components/utils/TokenManager';
const { ACTIONS } = CONSTANTS;

/**
 * @description Function to fetch authentication details.
 * @param {String} baseUrl - baseurl to fetch authentication.
 */
export const fetchAuthentication = baseUrl => (
  (dispatch) => {
    dispatch({ type: ACTIONS.LOADER, payload: true });
    return fetchAuthenticationDetail(baseUrl).then((response) => {
      dispatch({ type: ACTIONS.LOADER, payload: false });
      if (response && response.data && response.data.role) {
        TokenManager.setItem('idToken', response.data.role);
        if ('superAdmin' in JwtDecode(response.data.role)) {
          dispatch({ type: ACTIONS.FETCH_AUTHENTICATION, payload: false });
        } else {
          dispatch({ type: ACTIONS.FETCH_AUTHENTICATION, payload: true });
          toaster('error', __('You are not authorized to perform this operation.'));
        }
      } else {
        dispatch({ type: ACTIONS.FETCH_AUTHENTICATION, payload: true });
        toaster('error', __('You are not authorized to perform this operation.'));
      }
    }).catch(() => {
      dispatch({ type: ACTIONS.LOADER, payload: true });
      toaster('error', __('You are not authorized to perform this operation.'));
    });
  }
);
