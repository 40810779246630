import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchMobileConfigList } from '../../../action/MobileConfigurations.action';
import { getMobileConfigLoading, getMobileConfigs } from './MobileConfigurations.selector';
import MobileConfigurations from './MobileConfigurations';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchMobileConfigList,
  }, dispatch),
});

const mapStateToProps = createStructuredSelector({
  mobileConfigs: getMobileConfigs(),
  loading: getMobileConfigLoading(),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileConfigurations);
