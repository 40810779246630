import { stringInterpolate } from '@tekion/tekion-base/formatters/string';
import { getLocalTimeZone } from '@tekion/tekion-base/utils/dateUtils';
import Http, { doGet, doPost, doPut } from '../services/http';
import {
    getUserPreference, postUpdateNotification, updateUserPreference, postUpdateSummary, getTmUsers, getTmTranslateUsers, editTmUserRole,
} from './urls';
import { EMPTY_OBJECT } from '@tekion/tap-components/constants/Constants';

export const fetchPreferencesApi = url => doGet(`${url}${getUserPreference}`);
export const updateNotificationApi = (url, data) => doPost(`${url}${postUpdateNotification}`, data);
export const updateSummaryApi = (url, data) => doPut(`${url}${updateUserPreference}`, data, { timeZone: getLocalTimeZone() });
export const updateSummaryTMApi = (url, data) => doPut(`${url}${stringInterpolate(postUpdateSummary, {})}`, data, { timeZone: getLocalTimeZone() });
export const fetchTmUsersApi = url => doGet(`${url}${getTmUsers}`);
export const fetchTmTranslateUsersApi = url => doGet(`${url}${getTmTranslateUsers}`);
export const updateUserPermissionApi = (url, payload) => Http.post('TAP', `${url}${editTmUserRole}`, payload, EMPTY_OBJECT);
