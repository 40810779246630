// Third-party library imports.

// Tekion library imports.
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// Tap library imports.

// Local repo imports.
import { getUserGroups, updateNotifyInUserGroups } from './urls';
import Http from '../services/http';

export const fetchNotificationSetting = (baseUrl, data) => Http.post('TAP', `${baseUrl}${getUserGroups}`, data, EMPTY_OBJECT);
export const updateNotificationSetting = (baseUrl, groupId, data) => Http.put('TAP', `${baseUrl}${updateNotifyInUserGroups}`.replace('<group_id>', groupId), data, EMPTY_OBJECT);
