import { createSelector } from 'reselect';

const skeleton = state => state.skeleton;
const listingReducer = state => state.groupManagement;
const authenticationReducer = state => state.Authentication;

const getAllGroups = () => createSelector(
  listingReducer,
  substate => substate?.allGroups
);

const getGroup = () => createSelector(
  listingReducer,
  substate => substate?.activeGroup
);

const getGroupTypes = () => createSelector(
  listingReducer,
  substate => substate?.groupTypes
);

const addNewGroup = () => createSelector(
  listingReducer,
  substate => substate?.addCard
);

const updateOldGroup = () => createSelector(
  listingReducer,
  substate => substate?.updateCard
);

const deleteOldGroup = () => createSelector(
  listingReducer,
  substate => substate?.deleteCard
);

const fetchAuthenticationSelector = () => createSelector(
  authenticationReducer,
  substate => substate.notAuthenticated
);

const loading = () => createSelector(
  listingReducer,
  substate => substate?.loading
);

const getWorkSpaceSize = () => createSelector(
  skeleton,
  substate => substate?.workSpaceSize
);
export {
  getAllGroups, fetchAuthenticationSelector, getWorkSpaceSize, loading, addNewGroup, updateOldGroup, deleteOldGroup, getGroup, getGroupTypes,
};
