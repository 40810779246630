import { handleActions } from 'redux-actions';

import TYPES from './actions.types';

const DEFAULT_WORKSPACE_SIZE = {
  workSpaceSize: {
    height: 0,
  },
};

const INITIAL_STATE = {
  ...DEFAULT_WORKSPACE_SIZE,
};

const workSpaceSizeModifier = {
  [TYPES.APP_RESIZE]: (state = INITIAL_STATE, { size }) => ({
    ...state,
    workSpaceSize: size,
  }),
};

export default handleActions({
  ...workSpaceSizeModifier,
}, INITIAL_STATE);
