import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { CONSTANTS } from '../constants/Constants';

const { ACTIONS } = CONSTANTS;
const initialState = {
  allUsers: EMPTY_ARRAY,
  filteredUsers: EMPTY_ARRAY,
  isTableLoading: true,
  isUserFetching: false,
};

export default (prevState, action) => {
  const state = prevState || initialState;
  switch (action.type) {
    case ACTIONS.SET_ALL_USER:
      return {
        ...state,
        allUsers: action.payload,
      };
    case ACTIONS.SEARCH_USER:
      return {
        ...state,
        filteredUsers: action.payload,
      };
    case ACTIONS.SET_FETCHING:
      return {
        ...state,
        isUserFetching: action.payload,
      };
    case ACTIONS.SET_LOADER:
      return {
        ...state,
        isTableLoading: action.payload,
      };
    default: return state;
  }
};
