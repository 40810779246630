// import { doGet, doPost, doDelete,  } from '../services/http';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import {
  getAllCards, getIndividualCard, addCard, deleteCard, updateCard,
} from './urls';

import Http, { doGet, doDelete } from '../services/http';

export const fetchAllCardsAPI = url => doGet(`${url}${getAllCards}`);
export const fetchCardAPI = (url, appKey) => doGet(`${url}${getIndividualCard}`.replace('{appKey}', appKey));
export const deleteCardAPI = (url, data) => doDelete(`${url}${deleteCard}`, data);
export const addCardAPI = (url, data) => Http.post('TAP', `${url}${addCard}`, data, EMPTY_OBJECT);
export const updateCardAPI = (url, data) => Http.post('TAP', `${url}${updateCard}`, data, EMPTY_OBJECT);
