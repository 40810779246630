import React, { useEffect } from 'react';
import { isEmpty } from '@tekion/tap-components/utils/helper';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import TokenManager from '@tekion/tap-components/utils/TokenManager';

const Desktop = ({ match }) => {
  useEffect(() => {
    if (!isEmpty(match)) {
      const { params: { app } = { app: EMPTY_STRING } } = match
      if (app === 'TM') {
        const accessToken = TokenManager.getItem(TokenManager.accessTokenKey);
        const email = encodeURIComponent(TokenManager.getItem('email'));
        const tokenExpiryDate = encodeURIComponent(TokenManager.getItem(TokenManager.tokenExpiryDate));
        window.open(`tapapp://token=${accessToken}&&email=${email}&&expireDate=${tokenExpiryDate}`, '_blank')
      }
    }
  }, [match])

  return (
    <p>Please wait while we redirect you to our app</p>
  );
};


export default Desktop;
