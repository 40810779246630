export const NOTIFICATION_OPTION_LABELS = {
  ticket_create: __('Ticket Create'),
  ticket_comment: __('Ticket Comment'),
  ticket_update: __('Ticket Update'),
};

export const NOTIFICATION_TYPES = [
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'webapp',
    label: 'Web App',
  },
];

export const EMPTY_GROUPS_TEXT = {
  TITLE: __('Not Associated with any group '),
  DESC1: __('You have not been assigned to a User group'),
  DESC2: __('This page is used to configure group level notifications'),
};

export const SOURCE_TYPE = ['chat','call', 'offline'];

export const getEventData = () => {
  const sourceEvent = {};
  SOURCE_TYPE.forEach((source) => {
     sourceEvent[source] = {
     ticketComment: false,
     ticketCreate: false,
     ticketUpdate: false,
     webpappTicketCreate: false,
     webpappTicketComment: false,
     webpappTicketUpdate: false,
     }
  });
  return sourceEvent;
}

export const EVENT_MAPPING = {
    ticket_comment: 'ticketComment' ,
    ticket_create: 'ticketCreate' ,
    ticket_update: 'ticketUpdate' ,
    app_ticket_create: 'webpappTicketCreate',
    app_ticket_comment: 'webpappTicketComment' ,
    app_ticket_update: 'webpappTicketUpdate',
  }

  export const getSourceData = (SourceWiseEvent, emailEvent, webEvent) => {
    return [{
      key:'chat',
      label: 'Chat',
      actions: [
         { type: 'email',
         value: SourceWiseEvent['chat'][emailEvent]},
         { type: 'webapp',
         value: SourceWiseEvent['chat'][webEvent]}          ]
      },
      {
        key:'call',
        label: 'Call',
        actions: [
           { type: 'email',
           value: SourceWiseEvent['call'][emailEvent]},
           { type: 'webapp',
         value: SourceWiseEvent['call'][webEvent]}    
          ]
        },
        {
          key:'offline',
          label: 'Offline',
          actions: [
             { type: 'email',
             value: SourceWiseEvent['offline'][emailEvent]},
             { type: 'webapp',
           value: SourceWiseEvent['offline'][webEvent]}    
            ]
          }
      ]
  }
