import React, { PureComponent, Fragment } from 'react';
import RouteWithPermission from './routesWithPermission';

class Routes extends PureComponent {

  renderRouteWithPermissionComponent = permission => <RouteWithPermission {...this.props} permission={permission} />;

  render() {
    return (
      <Fragment>
        {this.renderRouteWithPermissionComponent()}
      </Fragment>
    );
  }
}

export default Routes;
