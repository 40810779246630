import { createSelector } from 'reselect';

const mobileConfigurationsState = state => state.mobileConfigurations;

export const getMobileConfigs = () => createSelector(
  mobileConfigurationsState,
  substate => substate?.mobileConfigs
);

export const getMobileConfigLoading = () => createSelector(
  mobileConfigurationsState,
  substate => substate?.loading
);

export const getActiveMobileConfig = () => createSelector(
  mobileConfigurationsState,
  substate => substate?.activeMobileConfig
);
