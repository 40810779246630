import React, { Component, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import Spinner from 'tcomponents/molecules/SpinnerComponent';
import './variables.css';

import NotificationWrapper from 'tcomponents/organisms/NotificationWrapper';

import { setDefaultModuleServices } from 'services/http';
import BuildInfoWrapper from '../../../tap-dev-kit/common/components/BuildInfoWrapper';
import { APP_BUILD_TIME } from '../../../config/Meta';
import translate from 'ti18n/translate'; // IIFE

import Routes from './routes';

class App extends Component {
  constructor() {
    super();
    setDefaultModuleServices();
    this.state = { isLoading: true };
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  renderRoutes() {
    return (
      <Fragment>
        <Switch>
          <Route path="*" component={Routes} />
        </Switch>
        <NotificationWrapper />
        <BuildInfoWrapper buildTime={APP_BUILD_TIME} />
      </Fragment>
    );
  }

  renderSpinner = () => (
    <div className="full-height full-width loader">
      <Spinner className="hv-center" id="iotMonitoringSpinner" size="large" />
    </div>
  )

  render() {
    const { isLoading } = this.state;
    return isLoading ? this.renderSpinner() : this.renderRoutes();
  }
}

export default (translate()(App));
