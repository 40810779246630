import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import UserManagement from './UserManagement';
import { fetchAllUsers, searchUsers, createUser, deleteUser } from '../../action/UserManagement.action';
import {
  loading, getWorkSpaceSize, getAllUsers, getFilteredUsers, fetching
} from './UserManagement.selector';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      fetchAllUsers,
      searchUsers,
      createUser,
      deleteUser,
    }, dispatch),
  };
}

const mapStateToProps = createStructuredSelector({
  isTableLoading: loading(),
  allUsers: getAllUsers(),
  isUserFetching: fetching(),
  filteredUsers: getFilteredUsers(),
  workSpaceSize: getWorkSpaceSize(),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
