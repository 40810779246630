import {
  getAllUsers, searchUsers, createUser, deleteUser,
} from './urls';
import { doDelete, doGet, doPost } from '../services/http';

export const fetchAllUsersApi = url => doGet(`${url}${getAllUsers}`);

export const searchUsersApi = (url, searchText) => doGet(`${url}${searchUsers}${searchText}`);

export const createUserApi = (url, data) => doPost(`${url}${createUser}`, data);

export const deleteUserApi = (url, data) => doDelete(`${url}${deleteUser}`, data);

export const fetchUsersWithImageV1Api = (url, version, filterWithImage) => doGet(`${url}/tapapi/v2/restricted/profile-picture/${version}/details`, filterWithImage ? { absoluteempty: true } : {});
export const migrateImageApi = (url, email) => doGet(`${url}/tapapi/v2/restricted/migrate/profilepicture/${email}`);
export const deleteUserFromV1Api = (url, email) => doDelete(`${url}/tapapi/v2/restricted/profile-picture/v1/delete/${email}`);
