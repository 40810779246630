import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { createOrUpdateMobileConfig, getMobileConfig } from '../../../../action/MobileConfigurations.action';
import { getActiveMobileConfig, getMobileConfigLoading } from '../MobileConfigurations.selector';
import MobileConfigurationsCreate from './MobileConfigurationsCreate';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    createOrUpdateMobileConfig,
    getMobileConfig,
  }, dispatch),
});

const mapStateToProps = createStructuredSelector({
  activeMobileConfig: getActiveMobileConfig(),
  loading: getMobileConfigLoading(),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileConfigurationsCreate);
