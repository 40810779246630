import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import Switch from '@tekion/tekion-components/src/molecules/Switch';
import PropTypes from 'prop-types';
import Button from 'tcomponents/atoms/Button';
import Input from 'tcomponents/atoms/Input';
import Loader from 'tcomponents/molecules/loader';
import styles from './MobileConfigurationsCreate.module.scss';
import PageHeader from '../../PageHeader/PageHeader';
import { BREAD_CRUMBS } from '../../TapConfigurations/Constants';


class MobileConfigurationsCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      platform: '',
      version: '',
      minimumVersion: '',
      forceUpdate: false,
    };
  }

  componentDidMount() {
    const { actions, isEdit, match } = this.props;
    const { getMobileConfig } = actions;
    if (isEdit) {
      getMobileConfig(match.params.platform, actions);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { activeMobileConfig, isEdit } = props;
    const {
      platform, version, minimumVersion, forceUpdate,
    } = activeMobileConfig || {};
    if (isEdit && platform !== state.platform) {
      return {
        platform,
        version,
        minimumVersion,
        forceUpdate,
      };
    }
    return null;
  }

  handleSave = () => {
    const {
      platform, version, minimumVersion, forceUpdate,
    } = this.state;
    const { history, actions } = this.props;
    const { createOrUpdateMobileConfig } = actions;
    const payload = {
      platform, version, minimumVersion, forceUpdate,
    };
    createOrUpdateMobileConfig(platform, payload)
      .then((isSuccess) => {
        if (isSuccess) {
          history.push('/app/tapapplications/mobileconfigurations');
        }
      });
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleOnCheck = (flag) => {
    this.setState({ forceUpdate: flag });
  }

  onCancel = () => {
    const { history } = this.props;
    history.push('/app/tapapplications/mobileconfigurations');
  }

  renderHeader = () => {
    const { isEdit } = this.props;
    return (
      <PageHeader
        breadCrumbs={isEdit ? BREAD_CRUMBS.MOBILE_CONFIGURATIONS_EDIT : BREAD_CRUMBS.MOBILE_CONFIGURATIONS_CREATE}
      />
    );
  }

  _renderCreateForm = () => {
    const {
      platform, version, minimumVersion, forceUpdate,
    } = this.state;
    const { loading, isEdit } = this.props;

    return (
      <React.Fragment>
        {loading.fetchActiveMobileConfig ? <Loader />
          : (
            <div className={styles.container}>
              {this.renderHeader()}
              <div className={styles.createModule}>
                <Row>
                  <Col xs={24} md={24} sm={24} lg={11}>
                    <Row className={styles.rowCol} align="middle" gutter={16}>
                      <Col xs={24} md={10} sm={24} lg={7}>
                        {__('Platform')}
                      </Col>
                      <Col span={12}>
                        <Input value={platform} disabled={isEdit} name="platform" onChange={this.onChange} />
                      </Col>
                    </Row>
                    <Row className={styles.rowCol} align="middle" gutter={16}>
                      <Col xs={24} md={10} sm={24} lg={7}>
                        {__('Version')}
                      </Col>
                      <Col span={12}>
                        <Input value={version} name="version" onChange={this.onChange} />
                      </Col>
                    </Row>
                    <Row className={styles.rowCol} align="middle" gutter={16}>
                      <Col xs={24} md={10} sm={24} lg={7}>
                        {__('Minimum Version')}
                      </Col>
                      <Col span={12}>
                        <Input value={minimumVersion} name="minimumVersion" onChange={this.onChange} />
                      </Col>
                    </Row>
                    <Row className={styles.rowCol} align="middle" gutter={16}>
                      <Col xs={24} md={10} sm={24} lg={7}>
                        {__('Force Update')}
                      </Col>
                      <Col span={12}>
                        <Switch value={forceUpdate} name="forceUpdate" onChange={this.handleOnCheck} checked={forceUpdate} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className={styles.moduleFooter}>
                <Button style={{ marginRight: '25px' }} onClick={() => this.onCancel()}>
                  {__('Cancel')}
                </Button>
                <Button view="primary" onClick={() => this.handleSave()} disabled={!platform || !version}>
                  {__('Save')}
                </Button>
              </div>
            </div>
          )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this._renderCreateForm()}
      </React.Fragment>
    );
  }
}

MobileConfigurationsCreate.propTypes = {
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(MobileConfigurationsCreate);
