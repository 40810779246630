/* eslint-disable no-restricted-globals */
import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import {
  createUserApi, deleteUserApi, deleteUserFromV1Api, fetchAllUsersApi, fetchUsersWithImageV1Api, migrateImageApi, searchUsersApi,
} from '../api/UserManagement';
import { CONSTANTS } from '../constants/Constants';

const { ACTIONS } = CONSTANTS;

export const fetchAllUsers = baseUrl => (
  (dispatch) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: true });
    return fetchAllUsersApi(baseUrl)
      .then(({ data }) => {
        dispatch({ type: ACTIONS.SET_ALL_USER, payload: data });
        dispatch({ type: ACTIONS.SET_LOADER, payload: false });
        dispatch({ type: ACTIONS.SET_FETCHING, payload: false });
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.SET_ALL_USER_ERROR, payload: error });
        dispatch({ type: ACTIONS.SET_LOADER, payload: false });
      });
  }
);

export const searchUsers = (baseUrl, text) => (
  (dispatch) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: true });
    return searchUsersApi(baseUrl, text)
      .then(({ data }) => {
        dispatch({ type: ACTIONS.SEARCH_USER, payload: data });
        dispatch({ type: ACTIONS.SET_LOADER, payload: false });
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.SEARCH_USER_ERROR, payload: error });
        dispatch({ type: ACTIONS.SET_LOADER, payload: false });
      });
  }
);

export const createUser = (baseUrl, userdetails) => (
  (dispatch) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: true });
    return createUserApi(baseUrl, userdetails)
      .then(() => {
        toaster('success', __('User Added Successfully.'));
        dispatch({ type: ACTIONS.SET_FETCHING, payload: true });
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.SET_ALL_USER_ERROR, payload: error });
        if(error.status == 401) {
          toaster('error', __('You are not authorized to perform this operation.'));
        }
        dispatch({ type: ACTIONS.SET_LOADER, payload: false });
      });
  }
)

export const deleteUser = (baseUrl, email) => (
  (dispatch) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: true });
    return deleteUserApi(baseUrl, email)
      .then(() => {
        toaster('success', __('User Deleted Successfully.'));
        dispatch({ type: ACTIONS.SET_FETCHING, payload: true });
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.SET_ALL_USER_ERROR, payload: error });
        if(error.status == 401) {
          toaster('error', __('You are not authorized to perform this operation.'));
        }
        dispatch({ type: ACTIONS.SET_LOADER, payload: false });
      });
  }
);

/** User Image Migration Actions */
export const fetchUsersWithImageV1 = (baseUrl, version, filterWithImage) => (
  (dispatch) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: true });
    return fetchUsersWithImageV1Api(baseUrl, version, filterWithImage)
      .then(({ data }) => {
        dispatch({ type: ACTIONS.SET_ALL_USER, payload: data });
        dispatch({ type: ACTIONS.SET_LOADER, payload: false });
        dispatch({ type: ACTIONS.SET_FETCHING, payload: false });
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.SET_ALL_USER_ERROR, payload: error });
        dispatch({ type: ACTIONS.SET_LOADER, payload: false });
      });
  }
);

export const migrateImage = (baseUrl, email) => (
  (dispatch) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: true });
    return migrateImageApi(baseUrl, email)
      .then(() => {
        toaster('success', __('User Image Migrated Successfully.'));
        dispatch({ type: ACTIONS.SET_FETCHING, payload: true });
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.SET_ALL_USER_ERROR, payload: error });
        if (error.status === 401) {
          toaster('error', __('You are not authorized to perform this operation.'));
        }
        dispatch({ type: ACTIONS.SET_LOADER, payload: false });
      });
  }
);

export const deleteUserFromV1 = (baseUrl, email) => (
  (dispatch) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: true });
    return deleteUserFromV1Api(baseUrl, email)
      .then(() => {
        toaster('success', __('User removed from v1 Successfully.'));
        dispatch({ type: ACTIONS.SET_FETCHING, payload: true });
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.SET_ALL_USER_ERROR, payload: error });
        if (error.status === 401) {
          toaster('error', __('You are not authorized to perform this operation.'));
        }
        dispatch({ type: ACTIONS.SET_LOADER, payload: false });
      });
  }
);
