import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { CONSTANTS } from '../constants/Constants';

const { ACTIONS } = CONSTANTS;
const initialState = {
  tapConfig: EMPTY_ARRAY,
  searchUsers: EMPTY_ARRAY,
  loading: {
    getTapConfig: false,
    updateTapConfig: false,
    autoComplete: false,
  },
};

export default (prevState, action) => {
  const state = prevState || initialState;
  switch (action.type) {
    case ACTIONS.FETCH_TAP_CONFIG:
      return {
        ...state,
        tapConfig: action.payload,
      };
    case ACTIONS.FETCH_TAP_USERS:
      return {
        ...state,
        searchUsers: action.payload.data,
      };
    case ACTIONS.SET_LOADER:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      };
    default: return state;
  }
};
