import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import { fetchNotificationSetting, updateNotificationSetting } from '../api/SupportPortal';
import { CONSTANTS } from '../constants/Constants';
import { structureNotifiySetting, populateHash, recomposeGroupsHash } from '../pages/Settings/SupportPortal/SupportPortal.helper';
const { ACTIONS } = CONSTANTS;

export const getNotificationSetting = (baseUrl, data) => (dispatch) => {
  dispatch({ type: ACTIONS.SET_NOTIFY_SETTING_LOADING, payload: true });
  fetchNotificationSetting(baseUrl, data).then(({ data: responseData }) => {
    const groupsHash = populateHash(responseData);
    const { notifySettingHeaders, notifySettingData } = structureNotifiySetting(groupsHash);
    const hydratedData = {
      notifySettingHeaders,
      notifySettingData,
      groupsHash,
    };
    dispatch({ type: ACTIONS.HYDRATE_SP_NOTIFY_SETTING, payload: hydratedData });
    dispatch({ type: ACTIONS.SET_NOTIFY_SETTING_LOADING, payload: false });
  })
    .catch(() => {
      toaster('error', 'Failed to fetch User Notification Setting');
      dispatch({ type: ACTIONS.SET_NOTIFY_SETTING_LOADING, payload: false });
    });
};

export const saveNotificationSetting = (baseUrl, payloads) => (dispatch, getState) => {
  const promises = [];
  dispatch({ type: ACTIONS.SET_NOTIFY_SETTING_SAVING, payload: true });
  payloads.forEach(({ groupId, payload }) => {
    promises.push(updateNotificationSetting(baseUrl, groupId, payload));
  });
  Promise.all(promises).then(() => {
    const existingHash = getState().supportPortal.groupsHash;
    const groupsHash = recomposeGroupsHash(existingHash, payloads);
    const { notifySettingHeaders, notifySettingData } = structureNotifiySetting(groupsHash);
    const hydratedData = {
      notifySettingHeaders,
      notifySettingData,
      groupsHash,
    };
    dispatch({ type: ACTIONS.HYDRATE_SP_NOTIFY_SETTING, payload: hydratedData });
    dispatch({ type: ACTIONS.SET_NOTIFY_SETTING_SAVING, payload: false });
    toaster('success', 'Update User Notification Setting');
  })
    .catch(() => {
      toaster('error', 'Failed to update User Notification Setting');
      dispatch({ type: ACTIONS.SET_NOTIFY_SETTING_SAVING, payload: false });
    });
};
