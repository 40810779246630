import { CONSTANTS } from '../constants/Constants';

const { ACTIONS } = CONSTANTS;
const initialState = {
  fetchListing: {},
  saveModule: {},
  fetchModule: {},
  updateModule: {},
};

export default (prevState, action) => {
  const state = prevState || initialState;
  switch (action.type) {
    case ACTIONS.FETCH_LISTING_SUCESS:
      return {
        ...state,
        fetchListing: action.payload,
      };
    case ACTIONS.FETCH_MODULE_SUCESS:
      return {
        ...state,
        fetchModule: action.payload,
      };
    case ACTIONS.LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.RESET_MODULE:
      return {
        ...state,
        fetchModule: {},
      };
    default: return state;
  }
};
