/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import Tabs from 'tcomponents/molecules/Tabs/Tabs';

const TabGroup = ({
  onChange, tabList, initialTab, tabBarStyle,
}) => (
  <Tabs defaultActiveKey={initialTab} activeKey={initialTab} size="small" tabBarGutter={0} onChange={onChange} tabBarStyle={tabBarStyle}>
    {tabList.map(e => (
      <Tabs.TabPane key={e.key} tab={__(e.label)} />
    ))}
  </Tabs>
);


TabGroup.propTypes = {
  initialTab: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tabList: PropTypes.array.isRequired,
  tabBarStyle: PropTypes.object,
};

TabGroup.defaultProps = {
  tabBarStyle: {},
};
export default TabGroup;
