import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import Input from 'tcomponents/atoms/Input';
import Button from 'tcomponents/atoms/Button';
import Loader from 'tcomponents/molecules/loader';
import { deepClone } from '@tekion/tap-components/utils/helper';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { NO_OP } from '@tekion/tap-components/constants/Constants';
import { GroupData } from './constant';
import styles from './GroupManagement.module.scss';
import { activeGroup, createGroup, updateGroup } from '../../../action/GroupManagement.action';
import { loading, addNewGroup, getGroup } from './GroupManagement.selector';
import PageHeader from '../PageHeader/PageHeader';
import { BREAD_CRUMBS } from '../TapConfigurations/Constants';

class GroupCreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: '',
      editModule: false,
      loaded: false,
      disableSave: true,
    };
  }

  componentDidMount() {
    const { getIndividualGroup, apiBaseUrl, isEdit } = this.props;
    const { formData } = GroupData;
    this.setState({ formData });
    if (isEdit) {
      const { history: { location: { state: { groupData: { groupKey } } } } } = this.props;
      getIndividualGroup(apiBaseUrl, groupKey);
      this.setState({ editModule: true });
    } else {
      this.setState({ loaded: true });
    }
  }

  componentDidUpdate() {
    const { history, loading } = this.props;
    if (loading.addGroup || loading.updateGroup) {
      history.push('/app/tapapplications/groups');
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { getGroupResponse, location } = props;
    const { formData, loaded } = state;
    if (getGroupResponse?.data) {
      const { groupKey } = getGroupResponse?.data;
      if (location?.state?.groupData?.groupKey === groupKey && groupKey !== formData[0]?.id && !loaded && formData) {
        const newData = formData?.map(data => (
          {
            ...data,
            value: getGroupResponse.data[data.id],
          }
        ));
        return {
          formData: newData,
          editModule: true,
          loaded: true,
        };
      }
    }
    return null;
  }

  handleSave = () => {
    const { formData, editModule } = this.state;
    const payload = formData.reduce((obj, item) => ({
      ...obj,
      [item.id]: item.value,
    }), {});
    const {
      apiBaseUrl, updateGroupValue, addNewGroup, history,
    } = this.props;
    if (editModule) {
      updateGroupValue(apiBaseUrl, payload);
      history.push('/app/tapapplications/groups');
      this.setState({ loaded: false });
    } else {
      addNewGroup(apiBaseUrl, payload);
    }
  }

  formValidate = (formData) => {
    const invalid = formData.some(data => data.required ? !data.value : false);
    return invalid;
  }


  onChange = (target, index) => {
    const { formData } = this.state;
    const newData = deepClone(formData);
    newData[index].value = target.value;
    this.setState({ formData: newData, disableSave: this.formValidate(newData) });
  }

  onCancel = () => {
    const {
      history,
    } = this.props;
    history.push('/app/tapapplications/groups');
  }

  renderForm = () => {
    const {
      formData, editModule, disableSave,
    } = this.state;
    const { loading } = this.props;
    return (
      <div>
        <PageHeader
          breadCrumbs={editModule ? BREAD_CRUMBS.GROUP_MANAGEMENT_UPDATE : BREAD_CRUMBS.GROUP_MANAGEMENT_CREATE}
        />
        <div className={styles.createForm}>
          {formData && formData.map((data, index) => (
            <Row className={styles.formRow} align="middle" gutter={16} key={data.id}>
              <Col xs={24} md={10} sm={24} lg={3}>
                {data.label}{data.required ? ' *' : ''}
              </Col>
              <Col span={12}>
                <Input
                  id={data.id}
                  value={data.value}
                  name={data.id}
                  disabled={editModule && !data.isEditable}
                  placeholder={__('Please enter {{value}}.', { value: data.label })}
                  onChange={e => this.onChange(e.target, index)}
                />
              </Col>
            </Row>
          ))}
        </div>
        <div className={styles.formFooter}>
          <Button
            className={styles.cancel}
            onClick={this.onCancel}
          >
            {__('Cancel')}
          </Button>
          <Button
            view="primary"
            onClick={this.handleSave}
            disabled={disableSave}
            loading={loading.addGroup}
          >
            {__('Save')}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { loaded } = this.state;
    return (
      <React.Fragment>
        {!loaded ? <Loader />
          : (
            <div className={styles.container}>
              {this.renderForm()}
            </div>
          )
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  getGroupResponse: getGroup(),
  loading: loading(),
  addGroup: addNewGroup(),
});

const mapDispatchToProps = (dispatch) => {
  const getIndividualGroup = bindActionCreators(activeGroup, dispatch);
  const addNewGroup = bindActionCreators(createGroup, dispatch);
  const updateGroupValue = bindActionCreators(updateGroup, dispatch);
  return {
    getIndividualGroup,
    addNewGroup,
    updateGroupValue,
  };
};

GroupCreateForm.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.object,
  isEdit: PropTypes.bool,
  getIndividualGroup: PropTypes.func,
};

GroupCreateForm.defaultProps = {
  loading: EMPTY_OBJECT,
  isEdit: false,
  getIndividualGroup: NO_OP,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GroupCreateForm)
);
