/* eslint-disable no-param-reassign */
import { handleActions } from 'redux-actions';
import produce from 'immer';
import { CONSTANTS } from '../constants/Constants';

const { ACTIONS } = CONSTANTS;
const initialState = {
  preference: {},
  isFetchApiCalled: false,
};

export default handleActions({
  [ACTIONS.GET_USER_PREFERENCE_SUCCESS]:
   (state, { payload }) => produce(state, (updatedState) => {
     updatedState.preference = payload;
     updatedState.isFetchApiCalled = true;
   }),

  [ACTIONS.GET_USER_PREFERECE_ERROR]:
  (state, { payload }) => produce(state, (updatedState) => {
    updatedState.error = payload.error;
    updatedState.isFetchApiCalled = false;
    updatedState.preference = { ...payload.updatedPreference };
  }),
}, initialState);
