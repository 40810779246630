import { createSelector } from 'reselect';

const skeleton = state => state.skeleton;
const listingReducer = state => state.tapConfigurations;
const authenticationReducer = state => state.Authentication;

const getTapConfigs = () => createSelector(
  listingReducer,
  substate => substate?.tapConfig
);

const updateTapConfigs = () => createSelector(
  listingReducer,
  substate => substate?.updateTapConfig
);

const getSearchUsers = () => createSelector(
  listingReducer,
  substate => substate?.searchUsers
);
const getAllCards = () => createSelector(
  listingReducer,
  substate => substate?.searchCards
);

const fetchAuthenticationSelector = () => createSelector(
  authenticationReducer,
  substate => substate.notAuthenticated
);

const loading = () => createSelector(
  listingReducer,
  substate => substate?.loading
);

const getWorkSpaceSize = () => createSelector(
  skeleton,
  substate => substate?.workSpaceSize
);

export {
  getTapConfigs, loading, fetchAuthenticationSelector, getWorkSpaceSize, getSearchUsers, getAllCards, updateTapConfigs,
};
