import { deepClone } from '@tekion/tap-components/utils/helper';
import { omitFieldsFromObject } from '@tekion/tap-components/utils/helper.object';
import { EMPTY_OBJECT } from '@tekion/tap-components/constants/Constants';
import { EVENT_MAPPING, getEventData, getSourceData, NOTIFICATION_OPTION_LABELS, SOURCE_TYPE } from './SupportPortal.constants';

export const populateHash = (list = [], hashKeyProp = 'id') => {
  const listHash = {};
  list.forEach((item) => {
    const { [hashKeyProp]: id } = item;
    listHash[id] = deepClone(item);
  });
  return listHash;
};

export const getEventValue = (sourceWiseEvent, event, sources = ['chat','call','offline'], ) => {
  return sources.reduce((accumulator, source) => {
    return accumulator  && sourceWiseEvent[source][event]
  },true);
}

export const structureNotifiySetting = (groups) => {
  const notifySettingData = {};
  const notifySettingHeaders = [];
  Object.keys(groups).forEach((key) => {
    const { notify = [], id, name } = groups[key];
    notifySettingHeaders.push({
      key: id,
      label: name,
      accessor: id,
    });
    const userNotifySetting = omitFieldsFromObject(['email','name'], notify[0]) || {};
    const {email, name: userName } = notify[0] || EMPTY_OBJECT;

    const sourceWiseEvent = getEventData();
    Object.keys(userNotifySetting).forEach((event) => {
      const settingData = userNotifySetting[event];
      const eventName = EVENT_MAPPING[event];
      settingData?.forEach((source) => {
        sourceWiseEvent[source][eventName] = true;
      });
    })
    notifySettingData[id] =  [{
      key: 'ticket_create',
      label: NOTIFICATION_OPTION_LABELS.ticket_create,
      rawData: {email, name: userName},
      expanded: false,
      actions: [
        {
          type: 'email',
          value: getEventValue(sourceWiseEvent, 'ticketCreate'),
          disabled: false,
        },
        {
          type: 'webapp',
          value: getEventValue(sourceWiseEvent, 'webpappTicketCreate'),
          disabled: false,
        },
      ],
      subActions: getSourceData(sourceWiseEvent, 'ticketCreate', 'webpappTicketCreate')
    },
    {
      key: 'ticket_comment',
      label: NOTIFICATION_OPTION_LABELS.ticket_comment,
      rawData: {email, name: userName},
      expanded: false,
      actions: [
        {
          type: 'email',
          value: getEventValue(sourceWiseEvent, 'ticketComment'),
          disabled: false,
        },
        {
          type: 'webapp',
          value: getEventValue(sourceWiseEvent, 'webpappTicketComment'),
          disabled: false,
        },
      ],
      subActions: getSourceData(sourceWiseEvent, 'ticketComment', 'webpappTicketComment')
    },
    {
      key: 'ticket_update',
      label: NOTIFICATION_OPTION_LABELS.ticket_update,
      rawData: {email, name:userName},
      expanded: false,
      actions: [
        {
          type: 'email',
          value: getEventValue(sourceWiseEvent, 'ticketUpdate'),
          disabled: false,
        },
        {
          type: 'webapp',
          value: getEventValue(sourceWiseEvent, 'webpappTicketUpdate'),
          disabled: false,
        },
      ],
      subActions: getSourceData(sourceWiseEvent, 'ticketUpdate', 'webpappTicketUpdate')
    }];
  });
  return {
    notifySettingData, notifySettingHeaders,
  };
};

let differedData = {};
export const compareNotificationSettingDiff = (prevData, nextData) => {
  differedData = {};
  Object.keys(nextData).forEach((key) => {
    const nextContents = nextData[key];
    const prevContents = prevData[key];
    checkContentDiff(prevContents, nextContents, key);
  });
  return differedData;
};

const checkContentDiff = (prevContents, nextContents, key) => {
  nextContents.forEach((nextContent, index) => {
    const prevContent = prevContents[index];
    const { actions: prevActions, subActions: prevSubActions } = prevContent;
    const { actions: nextActions, subActions: nextSubActions } = nextContent;
    if (checkActionsDiff(prevActions, nextActions, differedData) || checkSubActionsDiff(prevSubActions, nextSubActions)) {
      differedData[key] = nextContents;
    }
  });
};

const checkActionsDiff = (prevActions, nextActions) => nextActions.some((action, index) => {
  const { value: nextValue } = action;
  const { value: prevValue } = prevActions[index];
  if (nextValue !== prevValue) {
    return true;
  }
  return false;
});

const checkSubActionsDiff = (prevSubActions, nextSubActions) => {
  let isChanged = false;
  nextSubActions.forEach((nextSubAction, index) => {
    const preSubAction = prevSubActions[index];
    const { actions: prevActions } = preSubAction;
    const { actions: nextActions } = nextSubAction;
    isChanged = isChanged || checkActionsDiff(prevActions, nextActions);
    
  });
  return isChanged;
}


export const getSourceList = (subActions, type) => {
 return subActions.filter((subAction) => subAction.actions[type].value).map(({key}) => key) || []; 
}

export const constructPayloads = (differedData) => {
  const payloads = [];
  Object.keys(differedData).forEach((key) => {

    let allSubActions = differedData[key].map(({ subActions }) => subActions);
    
    const { rawData } = differedData[key][0];
    payloads.push({
      groupId: key,
      payload: {
        notify: [
          {
            email: rawData.email,
            name: rawData.name,
            ticket_create: getSourceList(allSubActions[0], 0),
            app_ticket_create: getSourceList(allSubActions[0], 1),
            ticket_comment: getSourceList(allSubActions[1], 0),
            app_ticket_comment: getSourceList(allSubActions[1], 1),
            ticket_update: getSourceList(allSubActions[2], 0),
            app_ticket_update: getSourceList(allSubActions[2], 1),
          },
        ],
      },
    });
  });
  return payloads;
};


export const recomposeGroupsHash = (existingHash, newPayloads) => {
  const updatedHash = { ...existingHash };
  newPayloads.forEach(({ groupId, payload }) => {
    updatedHash[groupId] = {
      ...updatedHash[groupId],
      notify: [...deepClone(payload.notify)],
    };
  });
  return updatedHash;
};
