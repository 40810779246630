import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';

import canUseDOM from 'tbase/can-use-dom';

import arTranslations from './translations/ar_AR.json';
import enTranslations from './translations/en_US.json';


export const DEFAULT_NAMESPACE = 'iot_monitoring';

const i18nConfig = {
  debug: false,
  fallbackLng: 'en',
  resources: {
    en: {
      [DEFAULT_NAMESPACE]: enTranslations,
    },
    ar: {
      [DEFAULT_NAMESPACE]: arTranslations,
    },
  },
  ns: [DEFAULT_NAMESPACE],
  nsSeparator: '~~',
  defaultNS: DEFAULT_NAMESPACE,
  react: {
    wait: true,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init(i18nConfig);

if (canUseDOM) window.__ = i18n.t.bind(i18n);


export default i18n;
