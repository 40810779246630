import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import { getValueFromEnv } from '@tekion/tap-components/utils/commonHelper';
import { fetchUserPreferences, updateUserPreferences } from '../api/NotificationSettings';
import { updateSummaryApi } from '../api/TaskManagement';
import { CONSTANTS, STAGE_BASE_URL, STAGE_BASE_URL_2 } from '../constants/Constants';

const { ACTIONS } = CONSTANTS;
const BASE_URL_2 = getValueFromEnv('BASE_URL_2');

export const getUserPreferences = baseUrl => (
  (dispatch) => {
    const url = BASE_URL_2;
    dispatch({ type: ACTIONS.GET_USER_PREFERENCE_SUCCESS, payload: null });
    return fetchUserPreferences(url).then((response) => {
      dispatch({ type: ACTIONS.GET_USER_PREFERENCE_SUCCESS, payload: response });
    }).catch((error) => {
      dispatch({ type: ACTIONS.GET_USER_PREFERECE_ERROR, payload: error });
    });
  }
);

export const updatePreferences = (baseUrl, data, isApiFailed) => (dispatch, getState) => {
  const { notificationSettings = {} } = getState();
  const { preference = {} } = notificationSettings;
  const { userPreference } = preference;
  const url = BASE_URL_2;
  return updateUserPreferences(url, data).then((response = {}) => {
    const updatedUserPreference = {
      ...userPreference, ...data,
    };
    const updatedPreference = { ...preference, userPreference: { ...updatedUserPreference } };
    dispatch({ type: ACTIONS.GET_USER_PREFERENCE_SUCCESS, payload: updatedPreference });
    const { msg = __('Changes are saved') } = response;
    toaster('success', msg);
  }).catch((error) => {
    const { preference = {} } = notificationSettings;
    isApiFailed();
    const updatedPreference = { ...preference, userPreference: { ...preference.userPreference } };
    dispatch({ type: ACTIONS.GET_USER_PREFERECE_ERROR, payload: { error, updatedPreference } });
    toaster('error', __('Failed to update'));
  });
};

export const updateAppBasedSummary = (baseUrl, data, isApiFailed) => (dispatch, getState) => {
  const { notificationSettings = {} } = getState();
  const { preference = {} } = notificationSettings;
  const { userPreference } = preference;
  const url = BASE_URL_2;
  return updateSummaryApi(url, data).then(() => {
    const updatedUserPreference = {
      ...userPreference, ...data,
    };
    const updatedPreference = { ...preference, userPreference: { ...updatedUserPreference } };
    dispatch({ type: ACTIONS.GET_USER_PREFERENCE_SUCCESS, payload: updatedPreference });
    toaster('success', __('Changes are saved'));
  }).catch((error) => {
    isApiFailed();
    const updatedPreference = { ...preference, userPreference: { ...preference.userPreference } };
    dispatch({ type: ACTIONS.GET_USER_PREFERECE_ERROR, payload: { error, updatedPreference } });
    toaster('error', __('Failed to update'));
  });
};
