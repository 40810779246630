// Third-party library imports.
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _debounce from 'lodash/debounce';

// Tekion library imports.
import { EMPTY_OBJECT } from 'tbase/app.constants';

// Local repo imports.
import App from './App';
import {
  bodyResize,
} from './App.actionHandlers';
import {
  HEADER_HEIGHT, SIDEBAR_WIDTH, FOOTER_HEIGHT,
} from '../../constants/Constants';

class Container extends React.Component {
  componentDidMount() {
    this.onBodyResize();
    document.addEventListener('keyup', this.keyPressHandler);
    window.addEventListener('resize', _debounce(this.onBodyResize, 500));
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.keyPressHandler);
    window.removeEventListener('resize', this.onBodyResize);
  }

  onBodyResize = () => {
    const { height, width } = document.body.getBoundingClientRect();
    const { actions } = this.props;
    const scaledHeaderAndFooter = (HEADER_HEIGHT + FOOTER_HEIGHT);
    const scaledNav = (SIDEBAR_WIDTH);
    const contentHeight = (height - scaledHeaderAndFooter) || height;
    const contentWidth = (width - scaledNav) || width;
    actions.bodyResize({
      height,
      width,
      contentHeight,
      contentWidth,
    });
  };


  render() {
    const { props } = this;
    const { workspaceSize = EMPTY_OBJECT } = props;
    const { height, width } = workspaceSize;
    return (
      <div
        id="tekion-workspace"
        style={{ height, width, overflowY: 'hidden' }}
      >
        <App workspaceSize={workspaceSize} {...props} />
      </div>
    );
  }
}

Container.propTypes = {
  actions: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      bodyResize,
    }, dispatch),
  };
}

const mapStateToProps = state => ({
  workspaceSize: state.skeleton.workSpaceSize,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
