import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { CONSTANTS } from '../constants/Constants';
const { ACTIONS } = CONSTANTS;

const initialState = {
  groupsHash: EMPTY_OBJECT,
  notifySettingHeaders: EMPTY_ARRAY,
  notifySettingData: EMPTY_OBJECT,
  isNotifySettingFetched: false,
  isNotifySettingLoading: false,
  isNotifySettingSaving: false,
};

/* - Reducer for Support Portal.
 * @params
 *  - prevState - object
 *  - action - object
 * @return
 *  - state - object
 */
export default (prevState, action) => {
  const state = prevState || initialState;
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.HYDRATE_SP_NOTIFY_SETTING:
      return {
        ...state,
        groupsHash: payload.groupsHash || EMPTY_OBJECT,
        notifySettingHeaders: payload.notifySettingHeaders || EMPTY_ARRAY,
        notifySettingData: payload.notifySettingData || EMPTY_OBJECT,
        isNotifySettingFetched: true,
      };

    case ACTIONS.SET_NOTIFY_SETTING_LOADING:
      return {
        ...state,
        isNotifySettingLoading: payload || false,
      };
    case ACTIONS.SET_NOTIFY_SETTING_SAVING:
      return {
        ...state,
        isNotifySettingSaving: payload || false,
      };
    default: return state;
  }
};
