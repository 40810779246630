import { CONSTANTS } from '../constants/Constants';

const { ACTIONS } = CONSTANTS;
const initialState = {
  userDetails: {},
  isFetchApiCalled: false,
};

/* - Reducer for User Details.
 * @params
 *  - prevState - object
 *  - action - object
 * @return
 *  - state - object
 */
export default (prevState, action) => {
  const state = prevState || initialState;
  switch (action.type) {
    case ACTIONS.FETCH_GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload.data,
        isFetchApiCalled: true,
      };

    case ACTIONS.FETCH_GET_USER_DETAILS_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetchApiCalled: false,
      };

    case ACTIONS.FETCH_UPDATE_USER_DETAILS_SUCCESS: {
      const { userDetails } = state;
      return {
        ...state,
        userDetails: { ...userDetails, ...action.payload },
        isFetchApiCalled: false,
      };
    }

    case ACTIONS.FETCH_UPDATE_USER_DETAILS_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetchApiCalled: false,
      };

    case ACTIONS.RESET_USER_DETAIL_API_EVENT:
      return {
        ...state,
        isFetchApiCalled: false,
      };
    default:
      return state;
  }
};
