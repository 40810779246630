import { createSelector } from 'reselect';

const AvailabilityReducer = state => state.userAvailability;

const getUserData = () => createSelector(AvailabilityReducer, substate => substate.userAvailabilityData);

const getAvailabilitys = () => createSelector(AvailabilityReducer, (substate) => {
  const availabilityData = {
    wkday: [],
    overides: [],
  };
  substate.userAvailabilityData?.rule?.forEach((data) => {
    if (data.type === 'wkday') {
      availabilityData.wkday.push(data);
    } else {
      availabilityData.overides.push(data);
    }
  });
  return availabilityData;
});

const loader = () => createSelector(AvailabilityReducer,
  substate => substate.loading);

export {
  getUserData,
  loader,
  getAvailabilitys,
};
