import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import styles from './PageHeader.module.scss';

const PageHeader = ({ breadCrumbs, actionItems }) => {
  return (
  <div className={styles.versionHeader}>
    <div className={styles.breadcrumb}>
      <Breadcrumb>
        {breadCrumbs.map(data => data.url ?
            <Breadcrumb.Item><Link to={data.url}>{data.name}</Link></Breadcrumb.Item> :
            <Breadcrumb.Item>{data.name}</Breadcrumb.Item>
        )}
      </Breadcrumb>
    </div>
    <div className={styles.flexHeader}>
      {actionItems}
    </div>
  </div>
)};

export default PageHeader;
