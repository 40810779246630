import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import commonStyles from './styles.module.scss';

const ProjectInfo = ({ buildTime }) => (
  <div className={commonStyles.buildInfo}>
    <span className={commonStyles.buildInfoHeader}>
      <div className="icon-info" style={{ color: 'rgb(66, 133, 244)' }}></div>
      <div>{__('Build Info')}</div>
    </span>
    <span>
      <span className={commonStyles.buildInfoLabel}>{__('Published on:')}</span>{' '}
      <span className={commonStyles.buildInfoValue}>{buildTime}</span>
    </span>
  </div>
);

ProjectInfo.propTypes = {
  buildTime: PropTypes.string.isRequired,
};

const BuildInfoWrapper = ({ buildTime }) => {
  window.showVersion = () => {
    toast(<ProjectInfo buildTime={buildTime} />, {
      pauseOnHover: false,
      icon: false,
      autoClose: true,
    }, null);
  };
  return null;
};

export default BuildInfoWrapper;
