import { CONSTANTS } from '../constants/Constants';

const { ACTIONS } = CONSTANTS;

const initialState = {
  notAuthenticated: true,
};

export default (prevState, action) => {
  const state = prevState || initialState;
  switch (action.type) {
    case ACTIONS.FETCH_AUTHENTICATION:
      return {
        ...state,
        notAuthenticated: action.payload,
      };
    default: return state;
  }
};
