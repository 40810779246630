import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import { getValueFromEnv } from '@tekion/tap-components/utils/commonHelper';
import { fetchPreferencesApi, fetchTmTranslateUsersApi, fetchTmUsersApi, updateNotificationApi, updateSummaryApi, updateSummaryTMApi, updateUserPermissionApi } from '../api/TaskManagement';
import { CONSTANTS } from '../constants/Constants';
import { EMPTY_ARRAY } from '@tekion/tap-components/constants/Constants';
import { INITIAL_TRANSLATION_PAYLOAD, PERMISSION_MODIFIER_ROLE } from '../pages/Settings/TaskManagement/constants';
const { ACTIONS } = CONSTANTS;
const BASE_URL_2 = getValueFromEnv('BASE_URL_2');
const BASE_URL = getValueFromEnv('BASE_URL');

export const fetchTmUsers = baseUrl => (
  (dispatch) => {
    dispatch({ type: ACTIONS.SET_IS_FETCH_OPTIONS, payload: true });
    const url = baseUrl === 'https://us-west1-tapapi.tekioncloud.com' ? 'https://us-west1-tapapi-01.tekioncloud.com' : baseUrl;
    return fetchTmUsersApi(url).then((response) => {
      dispatch({ type: ACTIONS.SET_IS_FETCH_OPTIONS, payload: false });
      dispatch({ type: ACTIONS.SET_USERS, payload: response });
    }).catch((error) => {
        toaster('error', __('Failed to get TM users'));
        dispatch({ type: ACTIONS.SET_IS_FETCH_OPTIONS, payload: false });
    });
  }
);

export const fetchTmTranslateUsers = baseUrl => (
  (dispatch) => {
    const url = baseUrl === 'https://us-west1-tapapi.tekioncloud.com' ? 'https://us-west1-tapapi-01.tekioncloud.com' : baseUrl;
    return fetchTmTranslateUsersApi(url).then(({ permissionData }) => {
      const translateUsers = permissionData?.find(item => item.permission === INITIAL_TRANSLATION_PAYLOAD.permission) || EMPTY_ARRAY;
      dispatch({ type: ACTIONS.SET_TRANSLATE_USERS, payload: translateUsers?.userIDs });
      // dispatch({ type: ACTIONS.SET_TRANSLATE_USERS, payload: [523] });
    }).catch((error) => {
      toaster('error', __('Failed to get translate users'));
    });
  }
);

export const updateUserPermission = (baseUrl, payload) => (
  (dispatch, getState) => {
    const url = baseUrl === 'https://us-west1-tapapi.tekioncloud.com' ? 'https://us-west1-tapapi-01.tekioncloud.com' : baseUrl;
    return updateUserPermissionApi(url, payload).then(() => {
      const { task = {} } = getState();
      const { tmTranslateUsers } = task;
      const { permissionData } = payload;
      const translatePermission = permissionData?.find(item => item.permission === INITIAL_TRANSLATION_PAYLOAD.permission) || EMPTY_ARRAY;
      let newMembers = [...translatePermission?.addMember?.filter(item => !tmTranslateUsers?.includes(item))];
      newMembers = [...newMembers, ...(tmTranslateUsers?.filter(item => !translatePermission?.removeMember.includes(item)) || [])];
      dispatch({ type: ACTIONS.SET_TRANSLATE_USERS, payload: newMembers });
    });
  }
)

export const fetchGetPreferences = baseUrl => (
  (dispatch) => {
    const url = baseUrl === 'https://us-west1-tapapi.tekioncloud.com' ? 'https://us-west1-tapapi-01.tekioncloud.com' : baseUrl;
    dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_SUCCESS, payload: null });
    return fetchPreferencesApi(url).then((response) => {
      dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_SUCCESS, payload: response });
      if (response?.role === PERMISSION_MODIFIER_ROLE) {
        dispatch(fetchTmUsers(baseUrl));
        dispatch(fetchTmTranslateUsers(baseUrl));
      }
    }).catch((error) => {
      dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_ERROR, payload: error });
    });
  }
);

export const updateNotification = (baseUrl, data) => (
  (dispatch) => {
    return new Promise((resolve, reject) => {
      updateNotificationApi(baseUrl, data).then(() => {
        dispatch(fetchGetPreferences(baseUrl));
        resolve();
        toaster('success', __('Preference details updated successfully'));
      }).catch((error) => {
        dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_ERROR, payload: error });
        toaster('error', __('Failed to update Preference details'));
      });
    });
  }
);

export const updateSummary = (baseUrl, data) => (dispatch, getState) => {
  const { task = {} } = getState();
  const { perference = {} } = task;
  const { userPreference } = perference;
  const url = BASE_URL_2;
  return updateSummaryApi(url, data).then((response = {}) => {
    const updatedUserPreference = {
      ...userPreference, ...data,
    };
    const updatedPreference = { ...perference, userPreference: { ...updatedUserPreference } };
    dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_SUCCESS, payload: updatedPreference });
    const { msg = __('Changes are saved') } = response;
    toaster('success', msg);
  }).catch((error) => {
    dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_ERROR, payload: error });
    toaster('error', __('Failed to update'));
  });
};

export const updateTMSummary = (baseUrl, data) => (dispatch, getState) => {
  const { task = {} } = getState();
  const { perference = {} } = task;
  const { userPreference } = perference;
  const url = baseUrl === 'https://us-west1-tapapi.tekioncloud.com' ? 'https://us-west1-tapapi-01.tekioncloud.com' : baseUrl;
  return updateSummaryTMApi(url, data).then((response = {}) => {
    const updatedUserPreference = {
      ...userPreference, ...data,
    };
    const updatedPreference = { ...perference, userPreference: { ...updatedUserPreference } };
    dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_SUCCESS, payload: updatedPreference });
    const { msg = __('Changes are saved') } = response;
  }).catch((error) => {
    dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_ERROR, payload: error });
  });
};
