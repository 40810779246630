import React from 'react';
import PropTypes from 'prop-types';
import {
  Menu, Icon,
} from 'antd';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import _difference from 'lodash/difference';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import Button from 'tcomponents/atoms/Button';
import moment from 'moment';
import cls from 'classnames';
import Dropdown from 'tcomponents/molecules/DropDown';
import Checkbox from 'tcomponents/atoms/checkbox';
import TimePicker from 'tcomponents/atoms/TimePicker';
import CheckboxGroup from 'tcomponents/atoms/checkboxGroup';

import FontIcon from 'tcomponents/atoms/FontIcon';
import Radio from 'tcomponents/atoms/Radio';
import TokenManager from '@tekion/tap-components/utils/TokenManager';
import { getValueFromEnv } from '@tekion/tap-components/utils/commonHelper';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tap-components/constants/Constants';
import { isEmpty } from '@tekion/tap-components/utils/helper';
import { omitFieldsFromObject } from '@tekion/tap-components/utils/helper.object';
import { toaster } from 'tcomponents/organisms/NotificationWrapper';

import { fetchGetPreferences, updateNotification, updateSummary, updateTMSummary, fetchTmUsers, fetchTmTranslateUsers, updateTmUserRole, updateUserPermission } from '../../../action/TaskManagement.action';
import { makePreferences, makeFetchApiStatus, makeTmUsers, makeTmTranslateUsers, getIsFetchOptions } from './TaskManagement.selector';
import {
  INITIAL_TRANSLATION_PAYLOAD,
  INVALID_DATE, NOTIFICATION_DAYS, PERMISSION_MODIFIER_ROLE, TASK_STATUS_OPTIONS, TASK_STATUS_OPTIONS_ARRAY, TIME_FIELDS, TIME_FORMAT,
} from './constants';
import './TaskManagement.scss';
import style from './antdOverRide.module.scss';
import TMSelect from '../../../molecules/TMSelect';
import TMAvatar from '../../../atoms/TMAvatar';

const MQTT_BASE_URL = getValueFromEnv('MQTT_BASE_URL');
const PARTNERS_SITE = getValueFromEnv('PARTNERS_SITE');
const cookieEmail = TokenManager.getItem('email');

export const TASK_LIST_OPTIONS = [
  { label: (__('Expand All')), value: 'expandAll' },
  { label: (__('Collapse All')), value: 'collapseAll' }];

// const mqtt = require('mqtt');
// const client = mqtt.connect(MQTT_BASE_URL);

const isPartnerSite = PARTNERS_SITE === 'true';

export const UserAvatar = ({
  option, key, displayName = false, displayEmail = false,
}) => <TMAvatar key={key || option?.userId} user={option} displayName={displayName} enableTooltip={!displayName} displayEmail={displayEmail} />;

class TaskManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      days: [],
      scheduleFrom: '',
      scheduleTill: '',
      schedule: false,
      dailySummary: false,
      isPaused: false,
      showTime: '',
      weekly: false,
      onlyToday: false,
      // pauseTime: '',
      ptSummary: false,
      // fromTodayTime: '',
      defaultView: [],
      expandAll: '',
      savePayload: {},
      permissionData: EMPTY_ARRAY,
      selectedValue: EMPTY_ARRAY,
    };
  }

  componentDidMount = () => {
    const { getUserPreferences, apiBaseUrl } = this.props;
    getUserPreferences(apiBaseUrl);

    // client.on('connect', () => {
    //   client.subscribe(`/tm/notification/${email}/status`);
    // });

    // client.on('message', (topic, message) => {
    //   const dataStr = message.toString();
    //   const data = JSON.parse(dataStr);
    //   this.setState({ isPaused: data.isPaused });
    // });
  }

  static getDerivedStateFromProps(props, state) {
    const { perference } = props;
    const { userPreference } = perference || {};
    const {
      isDailySummary: dailySummary = false, isPtSummary: ptSummary = false, isPaused: paused, fromTime: from, toTime: till,
      toToday: toTodayTime, isDaily: daily, isWeekly: weekly, isToday: onlyToday, defaultView, weekDay: pauseWeekDays, isCollapsed,
    } = userPreference || {};
    if (!_isEmpty(userPreference) && !state.loaded) {
      return {
        loaded: true,
        days: Array.isArray(pauseWeekDays) ? [...pauseWeekDays] : EMPTY_ARRAY,
        scheduleFrom: moment(from, 'HH:mm:ss').format('HH:mm'),
        scheduleTill: moment(till, 'HH:mm:ss').format('HH:mm'),
        schedule: daily,
        dailySummary,
        ptSummary,
        isPaused: paused,
        weekly,
        onlyToday,
        showTime: moment.unix(toTodayTime).format('HH:mm'),
        expandAll: !isCollapsed ? 'expandAll' : 'collapseAll',
        defaultView: !isEmpty(defaultView) ? defaultView : TASK_STATUS_OPTIONS_ARRAY,
      };
    }
    if (_isEmpty(userPreference)) {
      return { loaded: false };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { tmUsers: prevTmUsers, tmTranslateUsers: prevTmTranslateUsers } = prevProps;
    const { tmUsers, tmTranslateUsers } = this.props;

    if ((tmTranslateUsers !== prevTmTranslateUsers || tmUsers !== prevTmUsers) && !isEmpty(tmUsers) && !isEmpty(tmTranslateUsers)) {
      const newSelectedUsers = tmUsers.filter(item => tmTranslateUsers.includes(item.userId));
      this.setState({ selectedValue: [...newSelectedUsers] });
    }
  }

  componentWillUnmount() {
    // client.unsubscribe(`/tm/notification/${email}/status`);
  }

  renderActions = () => (
    <Menu onClick={e => this.handleMenuClick(e)}>
      {TIME_FIELDS.map((dropOption) => {
        const {
          label, suffix = '', prefix = '', value,
        } = dropOption;
        const displayLabel = `${__(prefix)} ${__(label)} ${__(suffix)}`.trim();
        return (
          <Menu.Item key={value}>
            {displayLabel}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  setTime = () => {
    const date = moment().add('days', 1);
    date.set({ hour: '09', minute: '00', seconds: '00' });
    const duration = moment.duration(date.diff(moment()));
    const seconds = duration.asSeconds();
    return { seconds, date };
  }

  isPausedPayloadCheck = (payload) => {
    let resultPayload = { ...payload };
    const {
      onlyToday, weekly, schedule,
    } = this.state;
    const { perference: { userPreference: { isToday } } } = this.props;
    const { perference: { userPreference: { isWeekly } } } = this.props;
    const { perference: { userPreference: { isDaily } } } = this.props;

    if (isToday !== onlyToday || isWeekly !== weekly || isDaily !== schedule) {
      if (onlyToday || weekly || schedule) {
        resultPayload = { ...resultPayload, isPaused: true };
      } else {
        resultPayload = { ...resultPayload, isPaused: false };
      }
    }
    return resultPayload;
  }

  handleSavePayload = (payload, isChanged) => {
    const { savePayload } = this.state;
    const resultPayload = this.isPausedPayloadCheck(payload);
    if (isChanged) {
      this.setState({ savePayload: { ...savePayload, ...resultPayload } });
    } else {
      const keysToBeRemoved = Object.keys(resultPayload);
      const currentPayload = omitFieldsFromObject(keysToBeRemoved, savePayload);
      this.setState({ savePayload: currentPayload });
    }
  }

  handleMenuClick = (e) => {
    const { perference: { userPreference: { toToday: toTodayTime } } } = this.props;
    const currentTime = moment().unix();
    const tomorrow = this.setTime();
    const pauseTime = e.key === 'tomorrow' ? tomorrow.seconds : parseInt(e.key, 10);
    const toToday = e.key === 'tomorrow' ? tomorrow.date : moment().add(pauseTime, 'seconds');
    this.setState({
      onlyToday: true, showTime: toToday.format('HH:mm'),
    }, () => {
      const prevFromTime = moment.unix(toTodayTime).format('HH:mm');
      const payload = {
        isToday: true, fromToday: currentTime, toToday: currentTime + Math.round(pauseTime),
      };
      this.handleSavePayload(payload, prevFromTime !== toToday.format('HH:mm'));
    });
  };

  timeFromOnChange = (time, timeString) => {
    const { perference: { userPreference: { from } } } = this.props;
    const fromPrev = this.isDateValid(moment(from, 'HH:mm:ss').format('HH:mm'));
    const startTime = moment(timeString, 'HH:mm');
    this.setState({
      scheduleFrom: timeString,
    }, () => {
      const fromTime = this.isDateValid(startTime.format('HH:mm'));
      const payload = {
        fromTime,
      };
      this.handleSavePayload(payload, fromPrev !== fromTime);
    });
  };

  timeTillOnChange = (time, timeString) => {
    const { perference: { userPreference: { till } } } = this.props;
    const tillPrev = this.isDateValid(moment(till, 'HH:mm:ss').format('HH:mm'));
    const endTime = moment(timeString, 'HH:mm');
    this.setState({
      scheduleTill: timeString,
    }, () => {
      const toTime = this.isDateValid(endTime.format('HH:mm'));
      const payload = {
        toTime,
      };
      this.handleSavePayload(payload, tillPrev !== toTime);
    });
  };

  onChangeschedule = (e) => {
    const { perference: { userPreference: { isDaily } } } = this.props;
    const schedule = e.target.checked;
    this.setState({
      schedule,
    }, () => {
      const payload = { isDaily: schedule };
      this.handleSavePayload(payload, schedule !== isDaily);
    });
  };

  handlePTSummaryChange = (e) => {
    const { perference: { userPreference: { isPtSummary } } } = this.props;
    const ptSummary = e.target.checked;
    this.setState({
      ptSummary,
    }, () => {
      const payload = { isPtSummary: ptSummary };
      this.handleSavePayload(payload, isPtSummary !== ptSummary);
    });
  }

  onChangeDailySummary = (e) => {
    const { perference: { userPreference: { isDailySummary } } } = this.props;
    const dailySummary = e.target.checked;
    this.setState({
      dailySummary,
    }, () => {
      const payload = { isDailySummary: dailySummary };
      this.handleSavePayload(payload, isDailySummary !== dailySummary);
    });
  };

  handleDays = (e, val) => {
    const { perference: { userPreference: { weekDay } } } = this.props;
    const { days: daysInState } = this.state;
    const days = [...daysInState];
    const pauseWeekDays = Array.isArray(weekDay) ? [...weekDay] : EMPTY_ARRAY;

    if (days.includes(val)) {
      const daysVal = days.indexOf(val);
      days.splice(daysVal, 1);
      this.setState({ days }, () => {
        const payload = { weekDay: days, isWeekly: !isEmpty(days) };
        this.handleSavePayload(payload, !_isEqual(days.sort(), pauseWeekDays.sort()));
      });
    } else {
      const currentDays = [...days, val];
      this.setState({ days: currentDays }, () => {
        const payload = { weekDay: currentDays, isWeekly: !isEmpty(currentDays) };
        this.handleSavePayload(payload, !_isEqual(currentDays.sort(), pauseWeekDays.sort()));
      });
    }
  };

  resumeNotification = () => {
    const { perference: { userPreference: { isToday: paused } } } = this.props;
    this.setState({ onlyToday: false, showTime: EMPTY_STRING }, () => {
      const payload = { isToday: false };
      this.handleSavePayload(payload, paused !== false);
    });
  };

  isDateValid = date => date === INVALID_DATE ? EMPTY_STRING : date;


  postUpdatePreferences = (data) => {
    const { isPaused } = this.state;
    const { email } = this.props;
    const payload = { user: email || cookieEmail, isPaused, ...data };
    const { updatePreferences, apiBaseUrl } = this.props;
    return updatePreferences(apiBaseUrl, payload);
  };

  _renderPauseNotification = () => (
    <Dropdown disabled overlay={this.renderActions} trigger={['click']}>
      <Button><FontIcon className="bell-icon">icon-bell</FontIcon> {__('Pause notifications')}</Button>
    </Dropdown>
  );

  _renderResumeNotification = () => {
    const { loading } = this.state;
    return (
      <div>
        <Button disabled onClick={this.resumeNotification}>
          <FontIcon className="bell-icon">icon-bell</FontIcon> {__('Resume notifications')}
        </Button>
        <span>{loading && <Icon type="loading" />}</span>
      </div>
    );
  }

  handleSave = () => {
    const { savePayload, permissionData } = this.state;
    const { apiBaseUrl, editUserPermission } = this.props;

    const promises = [];

    const translationPayload = permissionData?.find(item => item.permission === INITIAL_TRANSLATION_PAYLOAD.permission);
    if (!isEmpty(translationPayload?.addMember) || !isEmpty(translationPayload?.removeMember)) {
      promises.push(editUserPermission(apiBaseUrl, { permissionData }));
    }
    if (!isEmpty(savePayload)) {
      const { updateSummaries } = this.props;
      promises.push(updateSummaries(apiBaseUrl, savePayload));    
    }
    if (!isEmpty(promises)) {
      Promise.all(promises).then((res) => {
        this.setState({ savePayload: EMPTY_OBJECT, permissionData: EMPTY_ARRAY });
        toaster('success', __('Updated successfully'));
      }).catch((error) => {
        toaster('error', __('Failed to update.'));
      });
    } else {
      toaster('error', __('No changes to be saved'));
    }
  };

  handlePayload = (payload, values) => ({ ...payload, ...values })

  onTaskStatus = (value) => {
    const { perference: { userPreference: { defaultView: taskStatus } } } = this.props;
    if (!isEmpty(value)) {
      this.setState({ defaultView: [...value] }, () => {
        const payload = { defaultView: [...value] };
        this.handleSavePayload(payload, !_isEqual(taskStatus, value));
      });
    }
  }

  onTaskListView = (e) => {
    const { target: { value } } = e;
    const { perference: { userPreference: { isCollapsed } } } = this.props;
    this.setState({ expandAll: value }, () => {
      const payload = { isCollapsed: value === 'collapseAll' };
      this.handleSavePayload(payload, isCollapsed !== (value === 'collapseAll'));
    });
  }

  getSelectedValueObjs = ({
    options, value, isSingleSelect = false, valueKey,
  }) => {
    if (isSingleSelect) {
      return options?.find(opt => (opt?.[valueKey] === value));
    }
    return options?.filter(opt => (value.includes(opt?.[valueKey])));
  };

  onChangeSelect = (value) => {
    this.setState({ selectedValue: value });
  };

  onOptionSelectHandler = (value) => {
    const { tmTranslateUsers } = this.props;
    const { userId } = value;
    const { permissionData } = this.state;

    const newPermission = permissionData.find(item => item.permission === INITIAL_TRANSLATION_PAYLOAD.permission) || INITIAL_TRANSLATION_PAYLOAD;

    if (!tmTranslateUsers?.includes(userId) && !newPermission?.addMember.includes(userId)) {
      newPermission.addMember.push(userId);
    }
    newPermission.removeMember = newPermission.removeMember.filter(item => item !== userId);
    this.setState({ permissionData: [newPermission] })
  }

  onOptionDeSelectHandler = (value) => {
    const { tmTranslateUsers } = this.props;
    const { userId } = value;
    const { permissionData } = this.state;

    const newPermission = permissionData.find(item => item.permission === INITIAL_TRANSLATION_PAYLOAD.permission) || INITIAL_TRANSLATION_PAYLOAD;

    if (tmTranslateUsers?.includes(userId) && !newPermission?.removeMember.includes(userId)) {
      newPermission.removeMember.push(userId);
    }
    newPermission.addMember = newPermission.addMember.filter(item => item !== userId);
    this.setState({ permissionData: [newPermission] });
  }

  render() {
    const {
      days, scheduleFrom, scheduleTill, schedule,
      dailySummary, showTime, defaultView, expandAll, ptSummary, savePayload, onlyToday, selectedValue, permissionData
    } = this.state;

    const { perference, tmUsers: options, isFetchOptions } = this.props;
    const { role } = perference || { };

    return (
      <React.Fragment>
        <div className="task-container">
          <div className="default-section">
            <h3>{__('Layout Settings')}</h3>
            <h4>{__('Default view')}</h4>
            <div className={style.statusSection}>
              <CheckboxGroup value={defaultView} options={TASK_STATUS_OPTIONS} onChange={this.onTaskStatus}></CheckboxGroup>
            </div>
            <div className="expandall-section">
              <Radio
                value={expandAll}
                radios={TASK_LIST_OPTIONS}
                onChange={this.onTaskListView}
              />
            </div>
            </div>
            {role === PERMISSION_MODIFIER_ROLE ? (
              <div className="default-section">
              <h3>{__('Template Settings')}</h3>
              <div className="translate-permission">
                <h4>{__('Template translation')}</h4>
                <TMSelect 
                  allowClear
                  isMultiSelect
                  allowSearch
                  canTrimOptions
                  options={options}
                  selectedValue={selectedValue}
                  onOptionSelect={this.onOptionSelectHandler}
                  onOptionDeSelect={this.onOptionDeSelectHandler}
                  onChange={this.onChangeSelect}
                  labelRender={option => <UserAvatar key={option.userId} option={option} displayName />}
                  optionSearchKey={'userName'}
                  optionValueKey={'userId'}
                  isDataLoading={isFetchOptions}
                />
              </div>
            </div>
            ) : ''}
          {/* <div className="notification-section">
            <h3>{__('Notification Settings')}</h3>
            <h4>{__('Do not disturb')}</h4>
            {onlyToday && <div>{__('Notifications paused until')} <span>{showTime}</span></div>}
            {!onlyToday ? this._renderPauseNotification() : this._renderResumeNotification()}
            <div className="schedule-section">
              <p>{__('Schedule')}</p>
              <div>
                <Checkbox disabled checked={schedule} onChange={this.onChangeschedule} />
                <span className="schedule-txt">{__('Do not notify me from :')} </span>
                <span className="time-select">
                  <TimePicker
                    onChange={this.timeFromOnChange}
                    value={moment(scheduleFrom, 'HH:mm').isValid() ? moment(scheduleFrom, TIME_FORMAT) : ''}
                    format={TIME_FORMAT}
                    disabled={!schedule}
                    id="from"
                    placeholder={__('From time')}
                  />
                </span>
                <span className="schedule-txt">{__('To:')}</span>
                <span className="time-select">
                  <TimePicker
                    onChange={this.timeTillOnChange}
                    value={moment(scheduleTill, 'HH:mm').isValid() ? moment(scheduleTill, TIME_FORMAT) : ''}
                    format={TIME_FORMAT}
                    disabled={!schedule}
                    id="to"
                    placeholder={__('To time')}
                  />
                </span>
              </div>
            </div>
            <div>{__('Do not disturb me on my days off')}</div>
            <div className="days-off">
              {NOTIFICATION_DAYS.map((daysOff, index) => {
                const daysStyle = cls('days', { active: days.indexOf(daysOff.value) > -1 });
                return (
                  <div
                    className={daysStyle}
                    key={daysOff.label}
                    // onClick={e => this.handleDays(e, daysOff.value)}
                    role="button"
                    tabIndex={index - 1}
                    disabled
                    style={{ cursor: 'not-allowed' }}
                  >
                    {__(daysOff.label)}
                  </div>
                );
              })}
            </div>
            <div className="summary-section">
              <Checkbox disabled checked={dailySummary} onChange={this.onChangeDailySummary} />
              <span>{__('Daily summaries')}</span>
              <p>{__('New tasks assigned to you and upcoming due dates')}</p>
            </div>
            {!isPartnerSite && (
              <div className="summary-section">
                <Checkbox disabled checked={ptSummary} onChange={this.handlePTSummaryChange} />
                <span>{__('Program alert')}</span>
                <p>{__('Notify me when each program changes')}</p>
              </div>
            )}
          </div> */}
        </div>
        <div className="task-settings-footer">
          <Button view="primary" onClick={this.handleSave}>{__('Save')}</Button>
        </div>
      </React.Fragment>

    );
  }
}

/**
 * @description Map the state objects (as props) that are required to render the details in the UI
 */
const mapStateToProps = createStructuredSelector({
  perference: makePreferences(),
  isFetchApiSuccess: makeFetchApiStatus(),
  tmUsers: makeTmUsers(),
  tmTranslateUsers: makeTmTranslateUsers(),
  isFetchOptions: getIsFetchOptions(),
});

/**
 * @description Map the actions (as props) that are required to dispatch actions from UI
 */
const mapDispatchToProps = (dispatch) => {
  const getUserPreferences = bindActionCreators(fetchGetPreferences, dispatch);
  const getTmUsers = bindActionCreators(fetchTmUsers, dispatch)
  const getTmTranslateUsers = bindActionCreators(fetchTmTranslateUsers, dispatch);
  const editUserPermission = bindActionCreators(updateUserPermission, dispatch);
  const updatePreferences = bindActionCreators(updateNotification, dispatch);
  const updateSummaries = bindActionCreators(updateTMSummary, dispatch);

  return {
    getUserPreferences,
    getTmUsers,
    getTmTranslateUsers,
    editUserPermission,
    updatePreferences,
    updateSummaries,
  };
};

TaskManagement.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  getUserPreferences: PropTypes.func.isRequired,
  updatePreferences: PropTypes.func.isRequired,
  updateSummaries: PropTypes.func.isRequired,
  perference: PropTypes.object,
};

TaskManagement.defaultProps = {
  perference: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskManagement);
