import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Row, Col, Select,
} from 'antd';
import Input from 'tcomponents/atoms/Input';
import Button from 'tcomponents/atoms/Button';
import TextArea from 'tcomponents/atoms/TextArea';
import Radio from 'tcomponents/atoms/Radio';
import Loader from 'tcomponents/molecules/loader';
import { deepClone, isEmpty, renderIfElse } from '@tekion/tap-components/utils/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { NO_OP } from '@tekion/tap-components/constants/Constants';
import _isEqual from 'lodash/isEqual';
import {
  searchUsers,
  updateTapConfig,
  getTapConfig,
} from '../../../action/TapConfiguration.action';

import { createFormData, BREAD_CRUMBS } from './Constants';
import styles from './TapConfigurations.module.scss';

import {
  loading, updateTapConfigs, getTapConfigs, getSearchUsers,
} from './TapConfiguration.selector';
import AutoCompleteHelper from './AutoCompleteHelper';
import PageHeader from '../PageHeader/PageHeader';

class TapConfigCreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: '',
      editModule: false,
      loaded: false,
      disableSave: true,
      isReload: true,
    };
  }

  componentDidMount() {
    const {
      history: { location: { currentModule } }, getTapConfigs, apiBaseUrl, isEdit, isCreate,
    } = this.props;
    const getFormDataFromParent = createFormData[currentModule?.type];
    if (!currentModule) getTapConfigs(apiBaseUrl);
    if (isEdit && currentModule) {
      const newData = !isEmpty(getFormDataFromParent) && getFormDataFromParent?.map(data => (
        {
          ...data,
          value: currentModule[data.id],
        }
      ));
      this.setState({ formData: newData, editModule: true, loaded: true });
    } else if (isCreate) {
      this.setState({ formData: createFormData[currentModule?.type], loaded: true });
    }
  }

  componentDidUpdate() {
    const { history, loading } = this.props;
    if (loading.updateTapConfig) {
      history.push('/app/tapapplications/tapconfig');
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { tapConfig, history: { location: { pathname } }, isEdit } = props;
    const { isReload } = state;
    if (tapConfig.length > 0 && isReload) {
      const currentPathArray = pathname.split('/');
      const currentModuleType = currentPathArray[currentPathArray.length - 2];
      const currentModuleIndex = tapConfig.findIndex(obj => obj.type === currentModuleType);
      const currentModule = tapConfig[currentModuleIndex];
      const getFormTemplateData = createFormData[currentModuleType];
      if (isEdit && getFormTemplateData) {
        const newData = getFormTemplateData.map(data => (
          {
            ...data,
            value: currentModule[data.id],
          }
        ));
        return {
          formData: newData,
          editModule: true,
          loaded: true,
          isReload: false,
        };
      }
    }
    return null;
  }

  handleSave = () => {
    const { formData } = this.state;
    const payload = formData.reduce((obj, item) => ({
      ...obj,
      [item.id]: item.value,
    }), {});
    const {
      apiBaseUrl, updateTapConfigs, history,
    } = this.props;
    updateTapConfigs(apiBaseUrl, payload);
    history.push('/app/tapapplications/tapconfig');
    this.setState({ loaded: false });
  }

  formValidate = (newData) => {
    const { location: { currentModule: originalData }, isEdit } = this.props;
    if (isEdit) {
      const modifiedData = {};
      newData.forEach((obj) => {
        modifiedData[obj.id] = obj.value;
      });
      return _isEqual(originalData, modifiedData);
    }
    const checkValues = newData.map((data) => {
      if (data.type === 'selectTag' || data.type === 'autoComplete') {
        return data.value.length > 0;
      }
      return data.value !== '';
    });
    return !checkValues.every(data => data);
  }

  onChange = (target, index) => {
    const { formData } = this.state;
    const newData = deepClone(formData);
    newData[index].value = target.value;
    this.setState({ formData: newData, disableSave: this.formValidate(newData) });
  }

  onChangeType = (value) => {
    this.setState({ formData: createFormData[value] });
  }

  onCancel = () => {
    const {
      history,
    } = this.props;
    history.push('/app/tapapplications/tapconfig');
  }

  callBackDataFromChild = (childData, id) => {
    const { formData } = this.state;
    const newData = deepClone(formData);
    if (newData && Array.isArray(childData)) {
      const index = newData.findIndex(object => object.id === id);
      newData[index].value = childData;
      this.setState({ formData: newData, disableSave: this.formValidate(newData) });
    }
  }

  handleAutoCompleteSearch = (searchKeyword) => {
    const { apiBaseUrl, getSearchUsers } = this.props;
    getSearchUsers(apiBaseUrl, searchKeyword);
  };

  renderForm = () => {
    const { formData, editModule, disableSave } = this.state;
    const { Option } = Select;
    const { loading } = this.props;
    return (
      <div>
        <PageHeader
          breadCrumbs={editModule ? BREAD_CRUMBS.TAP_CONFIGURATIONS_UPDATE : BREAD_CRUMBS.TAP_CONFIGURATIONS_CREATE}
        />
        <div className={styles.createForm}>
          {!editModule && (
            <Row className={styles.formRow} align="middle" gutter={16}>
              <Col xs={24} md={10} sm={24} lg={3}>
                {__('Select Template:')}
              </Col>
              <Select defaultValue="Select Type" style={{ width: 120 }} onChange={this.onChangeType}>
                {createFormData.header.map(data => (
                  <Option value={data.id}>{data.label}</Option>
                ))}
              </Select>
            </Row>
          )}
          {formData ? formData.map((data, index) => (
            <Row className={styles.formRow} align="middle" gutter={16} key={data.b}>
              <Col xs={24} md={10} sm={24} lg={3}>
                {data.label}{data.required ? ' *' : ''}
              </Col>
              {renderIfElse(data.type === 'autoComplete', () => (
                <Col span={12}>
                  <AutoCompleteHelper
                    searchKeyApi={this.handleAutoCompleteSearch}
                    getChildData={childData => this.callBackDataFromChild(childData, data.id)}
                    defaultValue={data.value}
                    placeholder={__('Start typing to search data.')}
                    id={data.id}
                    {...this.props}
                  />
                </Col>
              ),
                () => renderIfElse(data.type === 'selectTag', () => (
                  <Col span={12}>
                    <Select
                      style={{ width: '100%' }}
                      mode="tags"
                      defaultValue={data.value}
                      placeholder={__('Please enter {{value}} to add.', { value: data.id })}
                      onChange={childData => this.callBackDataFromChild(childData, data.id)}
                      allowClear
                    />
                  </Col>
                ),
                  () => renderIfElse(data.type === 'radio', () => (
                    <Col span={12}>
                      <Radio
                        type="RADIO"
                        value={data.value}
                        onChange={e => this.onChange(e.target, index)}
                        radios={data.options}
                      />
                    </Col>
                  ),
                    () => renderIfElse(data.type === 'textArea', () => (
                      <Col span={12}>
                        <TextArea
                          rows={4}
                          value={data.value}
                          placeholder={__('Please enter {{value}}.', { value: data.id })}
                          onChange={e => this.onChange(e.target, index)}
                        />
                      </Col>
                    ),
                      () => (
                        <Col span={12}>
                          <Input
                            id={data.id}
                            value={data.value}
                            name={data.id}
                            disabled={editModule && !data.isEditable}
                            placeholder={__('Please enter {{value}}.', { value: data.id })}
                            onChange={e => this.onChange(e.target, index)}
                          />
                        </Col>
                      )))))}
            </Row>
          ))
            : <div>{__('Choose a template from the list.')}</div>}
        </div>
        <div className={styles.formFooter}>
          <Button
            style={{ marginRight: '25px' }}
            onClick={this.onCancel}
          >
            {__('Cancel')}
          </Button>
          <Button
            view="primary"
            onClick={this.handleSave}
            disabled={disableSave}
            loading={loading.addCard}
          >
            {__('Save')}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { loaded } = this.state;
    return (
      <React.Fragment>
        <div className={styles.tapConfigContainer}>
          {!loaded ? <Loader />
            : this.renderForm()
          }
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  updateTapConfig: updateTapConfigs(),
  tapConfig: getTapConfigs(),
  searchUsers: getSearchUsers(),
  loading: loading(),
});

const mapDispatchToProps = (dispatch) => {
  const updateTapConfigs = bindActionCreators(updateTapConfig, dispatch);
  const getTapConfigs = bindActionCreators(getTapConfig, dispatch);
  const getSearchUsers = bindActionCreators(searchUsers, dispatch);
  return {
    updateTapConfigs,
    getSearchUsers,
    getTapConfigs,
  };
};

TapConfigCreateForm.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  updateTapConfigs: PropTypes.func,
  getSearchUsers: PropTypes.func,
  loading: PropTypes.object,
  getTapConfigs: PropTypes.func,
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
  searchUsers: PropTypes.array,
};

TapConfigCreateForm.defaultProps = {
  isEdit: false,
  isCreate: false,
  loading: EMPTY_OBJECT,
  updateTapConfigs: NO_OP,
  getSearchUsers: NO_OP,
  getTapConfigs: NO_OP,
  searchUsers: EMPTY_ARRAY,
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TapConfigCreateForm)
);
